
export const TotalBillDiscount = (pricingRule, setCart, cart, orderType, cartObj) => {
    const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const billCart = { ...cartObj };
      let maxAmount = pricingRule.maxBillAmount ? billCart.total <= pricingRule.maxBillAmount : true;
      const minQtyFlag = pricingRule.minimumQty ?  cartObj.totalQty >= pricingRule.minimumQty : true;
      const maxQtyFlag = pricingRule.maximumQty ?  cartObj.totalQty <= pricingRule.maximumQty : true;
      const minAmountFlag = pricingRule.billAmount ? cartObj.total >= pricingRule.billAmount : true;
      const maxAmountFlag = pricingRule.maxBillAmount ? cartObj.total <= pricingRule.maxBillAmount : true;

      let categoryItems = [];
      let brandItems = [];
      billCart.items.map((item) => {
          const stateValidation = pricingRule.mPricingXProducts.some((pc) => pc.mBrandId === item.productBrandId);
          const brandValidation = pricingRule.mPricingBrands.some((pc) => pc.mBrandId === item.productBrandId);
          const categoryValidation = pricingRule.mPricingPcategories.some((pc) => pc.mProductCategoryId === item.mProductCategoryId);
          if (stateValidation) {
              brandItems.push(item);
          }
          if(brandValidation){
              brandItems.push(item);
          }
          if(categoryValidation){
              categoryItems.push(item)
          }
          return null;
        });

        let allItems = [...brandItems,...categoryItems]
        let uniqueFinalArray = [...new Set(allItems)];
        let totalBillPrice = 0;
        uniqueFinalArray.map((product) => totalBillPrice = totalBillPrice + product.realPrice * product.weight);


      if (billCart.total + billCart.discount >= pricingRule.billAmount && maxAmount && minQtyFlag && maxQtyFlag && minAmountFlag && maxAmountFlag && totalBillPrice >= pricingRule.billAmount) {
        let discountValue = 0;
        discountValue = pricingRule.amountDiscount !== null ? pricingRule.amountDiscount : (pricingRule.percentageDiscount / 100) * billCart.total;
        let discountType = pricingRule.amountDiscount !== null ? "FD" : "PD";
        if (pricingRule.issueGiftVoucher === "N") {
          const cartItemsFiltered = billCart.items.filter((i) => i.nextRule === "Y" || i.nextRule === undefined);
          let salePricesTotal = 0;
          for (let i = 0; i < cartItemsFiltered.length; i += 1) {
            salePricesTotal += parseFloat(cartItemsFiltered[i].realPrice * cartItemsFiltered[i].weight);
          }

          billCart.items.map((addedToCart, index) => {

            let excludingFlag = true;

            if (pricingRule.mPricingB2CCustomerSegments.length > 0) {
              const matchingCustomerSegment = pricingRule.mPricingB2CCustomerSegments.find((pc) => pc.cwrB2CCustomerSegmentId === cartObj.customer?.b2cCustomerSegment?.cwrB2CCustomerSegmentId);
              
              if ((pricingRule.excludeB2CSegment === "Y" && matchingCustomerSegment) || (pricingRule.excludeB2CSegment !== "Y" && !matchingCustomerSegment)) {
                excludingFlag = false;
              }
            }
        
            if (pricingRule.mPricingB2CCustomers.length > 0) {
              const matchingCustomer = pricingRule.mPricingB2CCustomers.find((pc) => pc.b2cCustomerId === cartObj.customer.cwrCustomerId);
              
              if ((pricingRule.excludeB2CCustomers === "Y" && matchingCustomer) || (pricingRule.excludeB2CCustomers !== "Y" && !matchingCustomer)) {
                excludingFlag = false;
              }
            }
          
            if (pricingRule.mPricingPcategories.length > 0) {
              const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === addedToCart.mProductCategoryId);
                
              if ((pricingRule.excludeProductCategories === "Y" && matchingCategoy) || (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy)) {
                excludingFlag = false;
              }

              billCart.items.map((item) => {
                const stateValidation = pricingRule.mPricingPcategories.some((pc) => pc.mProductCategoryId === item.mProductCategoryId);
                if (stateValidation) {
                categoryItems.push(item)
                }
                return null;
              });
            }

            if (pricingRule.mPricingBrands.length > 0) {
              const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === addedToCart.productBrandId);
            
              if ((pricingRule.excludeBrands === "Y" && matchingBrand) || (pricingRule.excludeBrands !== "Y" && !matchingBrand)) {
                excludingFlag = false;
              }
              billCart.items.map((item) => {
                const stateValidation = pricingRule.mPricingBrands.some((pc) => pc.mBrandId === item.productBrandId);
                if (stateValidation) {
                    brandItems.push(item);
                }
                return null;
              });
            }
          
            if (pricingRule.mPricingBUnits.length > 0) {
              excludingFlag = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
            }
            
          
            if (pricingRule.mPricingXProducts.length > 0) {
              const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === addedToCart.productId);
            
              if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) || (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1)) {
                excludingFlag = false;
              }
            }
      
            let pricingRuleFlag = true;
            // if(pricingRule.mPricingXProducts.length>0){
            //   pricingRule.mPricingXProducts.some((op) => {
            //     cartObj.items.map((product)=> {
            //       if(op.mProductId === product.productId && product.nextRule === "Y"){
            //         pricingRuleFlag = false;
            //       }else if(op.mProductId === product.productId && !product.nextRule){
            //         pricingRuleFlag = true;
            //       }
            //     })
            //   })
            // }else{
            //   if(addedToCart.nextRule === "Y"){
            //     pricingRuleFlag = false;
            //   }
            // }


            if ((addedToCart.nextRule === "N" && pricingRuleFlag && excludingFlag && saleTypeValidation) || (addedToCart.nextRule === undefined && pricingRuleFlag && excludingFlag && saleTypeValidation)) {
              let discountAmt
              let sp;
              if (discountType === "FD") {
                discountAmt = (parseFloat(addedToCart.realPrice * addedToCart.weight) / totalBillPrice) * 100;
                addedToCart.discount = discountAmt;
                sp = parseFloat(addedToCart.realPrice) - (discountAmt / addedToCart.weight);
              } else {
                discountAmt = (pricingRule.percentageDiscount / 100) * parseFloat(addedToCart.realPrice);
                addedToCart.discount = discountAmt * addedToCart.weight;
                sp = parseFloat(addedToCart.realPrice) - (discountAmt);
              }
              
                const mrp = parseFloat(sp) * addedToCart.weight;
                const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
                addedToCart.salePrice = sp;
                addedToCart.taxAmount = tax;
                addedToCart.nettotal = mrp;
                addedToCart.discountName = pricingRule.printedName;
                addedToCart.mPricingruleId = pricingRule.mPricingrulesId;
                billCart.items[index] = addedToCart;
            }
          });

          billCart.mPricingruleId = pricingRule.mPricingrulesId;
          billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;

          const addedToCart = billCart.items;

          let totalTax = 0;
          let totalPrice = 0;
          let totalItemsQty = 0;
          let totalDiscounts = 0;
          for (let i = 0; i < addedToCart.length; i += 1) {
            totalPrice += addedToCart[i].nettotal;
            totalItemsQty += addedToCart[i].weight;
            totalTax += addedToCart[i].taxAmount;
            totalDiscounts += addedToCart[i].discount;
            addedToCart[i].key = i;
          }

          const roundOffValue = Math.round(totalPrice);
          const totalRoundOff = totalPrice - roundOffValue;

          setCart({
            ...cart,
            items: [...addedToCart],
            total: totalPrice,
            tax: totalTax,
            discount: totalDiscounts,
            totalQty: totalItemsQty,
            roundOff: totalRoundOff,
          });
        } else {
          billCart.giftVoucherType = pricingRule.giftVoucherType;
          billCart.giftVoucherAmount = discountValue;
          setCart({ ...billCart });
        }
      } else {
        const cartItemsFiltered = billCart.items.filter((i) => i.nextRule === "Y" || i.nextRule === undefined);
        let salePricesTotal = 0;
        for (let i = 0; i < cartItemsFiltered.length; i += 1) {
          salePricesTotal += parseFloat(cartItemsFiltered[i].realPrice);
        }

        billCart.items.map((addedToCart, index) => {
          if (addedToCart.discountName === pricingRule.printedName) {
            const sp = parseFloat(addedToCart.realPrice);
            const mrp = parseFloat(sp) * addedToCart.weight;
            const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
            addedToCart.salePrice = sp;
            addedToCart.taxAmount = tax;
            addedToCart.nettotal = mrp;
            addedToCart.discount = 0;
            addedToCart.discountName = "";
            billCart.items[index] = addedToCart;
          }
        });
        billCart.mPricingruleId = pricingRule.mPricingrulesId;
        billCart.billDiscount = 0;

        const addedToCart = billCart.items;

        let totalTax = 0;
        let totalPrice = 0;
        let totalItemsQty = 0;
        let totalDiscounts = 0;
        for (let i = 0; i < addedToCart.length; i += 1) {
          totalPrice += addedToCart[i].nettotal;
          totalItemsQty += addedToCart[i].weight;
          totalTax += addedToCart[i].taxAmount;
          totalDiscounts += addedToCart[i].discount;
          addedToCart[i].key = i;
        }

        const roundOffValue = Math.round(totalPrice);
        const totalRoundOff = totalPrice - roundOffValue;

        setCart({
          ...cart,
          items: [...addedToCart],
          total: totalPrice,
          tax: totalTax,
          discount: totalDiscounts,
          totalQty: totalItemsQty,
          roundOff: totalRoundOff,
        });
      }
}