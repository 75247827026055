export const flatDiscount = async (product, pricingRule,setCart,cart,orderType,cartObj,cartRef) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = (pricingRule?.cwrSaletypeId === null || orderType === undefined) ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
    let excludingFlag = true;

    if (pricingRule.mPricingPcategories.length > 0) {
      const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);
    
      if ((pricingRule.excludeProductCategories === "Y" && matchingCategoy) || (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy)) {
        excludingFlag = false;
      }
    }

    if (pricingRule.mPricingBrands.length > 0) {
      const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);
    
      if ((pricingRule.excludeBrands === "Y" && matchingBrand) || (pricingRule.excludeBrands !== "Y" && !matchingBrand)) {
        excludingFlag = false;
      }
    }

    if (pricingRule.mPricingBUnits.length > 0) {
      excludingFlag = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
    }
    
    if (pricingRule.mPricingXProducts.length > 0) {
      const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
    
      if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) || (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1)) {
        excludingFlag = false;
      }
    }

    let pricingRuleFlag = true;
    // if(pricingRule.mPricingXProducts.length>0){
    //   pricingRule.mPricingXProducts.some((op) => {
    //     cartObj.items.map((product)=> {
    //       if(op.mProductId === product.productId && product.nextRule === "Y"){
    //         pricingRuleFlag = false;
    //       }else if(op.mProductId === product.productId && !product.nextRule){
    //         pricingRuleFlag = true;
    //       }
    //     })
    //   })
    // }else{
    //   if(product.nextRule === "Y"){
    //     pricingRuleFlag = false;
    //   }
    // }

    if (saleTypeValidation && excludingFlag && pricingRuleFlag) {
      const addedToCart = cartObj.items;
      const index = addedToCart.findIndex((p) => p.productId === product.productId && p.upc === product.upc && p.mBatchId === product.mBatchId);
      pricingRule.minimumQty = pricingRule.minimumQty ? pricingRule.minimumQty : 0;
      if (index >= 0) {
        if (addedToCart[index].weight >= pricingRule.minimumQty) {
          const discountAmt = pricingRule.amountDiscount;
          const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
          const mrp = parseFloat(sp) * addedToCart[index].weight;
          const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
          addedToCart[index].salePrice = sp;
          addedToCart[index].taxAmount = tax;
          addedToCart[index].nettotal = mrp;
          addedToCart[index].discount = discountAmt * addedToCart[index].weight;
          addedToCart[index].discountName = pricingRule.printedName;
          addedToCart[index].type = pricingRule.type;
          addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
          addedToCart[index].nextRule = pricingRule.nextRule;
        } else {
          const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
          const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
          addedToCart[index].taxAmount = tax;
          addedToCart[index].nettotal = mrp;
          addedToCart[index].discount = 0;
          addedToCart[index].discountName = "";
          addedToCart[index].mPricingruleId = null;
          delete addedToCart[index].nextRule;
        }
        // totalPrice = roundOffValue;
        let obj = {
          ...cartObj,
          items: [...addedToCart],
        }
        // setCart(obj);
        return obj;
      }
    }
  }