import db from "../../../../database";

export const TotalBillFreeProductDiscount = (pricingRule, setCart, cart, orderType, cartObj, addProduct, setOfferProductList, setDisplayOfferProductSelection) => {
    const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
    const billCart = { ...cartObj };
    let categoryItems = [];
    let brandItems = [];

    if (pricingRule.mPricingPcategories.length > 0) {
      billCart.items.map((item) => {
        const stateValidation = pricingRule.mPricingPcategories.some((pc) => pc.mProductCategoryId === item.mProductCategoryId);
        if (stateValidation) {
        categoryItems.push(item)
        }
        return null;
      });
    }

    if (pricingRule.mPricingBrands.length > 0) {
      billCart.items.map((item) => {
        const stateValidation = pricingRule.mPricingBrands.some((pc) => pc.mBrandId === item.productBrandId);
        if (stateValidation) {
            brandItems.push(item);
        }
        return null;
      });
    }
    let allItems = [...brandItems,...categoryItems]
    let uniqueFinalArray = [...new Set(allItems)];
    let flag = brandItems.length>0 ? true : categoryItems.length>0 ? true : false;

    if (saleTypeValidation && flag) {
      let maxBillAmountFlag = pricingRule.maxBillAmount ? billCart.total <= pricingRule.maxBillAmount : true;
      const minQtyFlag = pricingRule.minimumQty ?  cartObj.totalQty >= pricingRule.minimumQty : true;
      const maxQtyFlag = pricingRule.maximumQty ?  cartObj.totalQty <= pricingRule.maximumQty : true;
      const minAmountFlag = pricingRule.billAmount ? cartObj.total >= pricingRule.billAmount : true;
      const maxAmountFlag = pricingRule.maxBillAmount ? cartObj.total <= pricingRule.maxBillAmount : true;
      let totalPrice = 0;
      uniqueFinalArray.map((product) => totalPrice = totalPrice + product.nettotal);
      if (totalPrice >= pricingRule.billAmount && maxBillAmountFlag && minQtyFlag && maxQtyFlag && minAmountFlag && maxAmountFlag) {
        if (pricingRule.mPricingXProducts.length > 0) {
          if (pricingRule.mPricingXProducts.length === 1) {
            db.products
              .where("mProductId")
              .equalsIgnoreCase(pricingRule.mPricingXProducts[0].mProductId)
              .toArray()
              .then((product) => {
                if (product.length > 0) {
                  const obj = { ...product[0] };
                  let batchno = null;
                  let mBatchId = null;
                  let upc = null;
                  if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                    const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[0].mBatchId);
                    batchno = obj.mBatch[bI].batchno;
                    mBatchId = obj.mBatch[bI].mBatchId;
                    upc = obj.mBatch[bI].upc;
                  }
                  if (obj.overRideTax === "Y" && 0 <= obj.overRideCondition) {
                    obj.cTaxId = obj.contraTaxId;
                    obj.taxRate = obj.contraRate;
                  }
                  const productDefined = {
                    batchno: batchno,
                    description: obj.description,
                    discount: 0,
                    discountName: "",
                    imageurl: obj.imageurl,
                    isDecimal: obj.isDecimal,
                    isManualQty: obj.isManualQty,
                    isPromoApplicable: false,
                    isReturn: false,
                    mBatchId: mBatchId,
                    mPricingruleId: null,
                    name: obj.name,
                    nettotal: 0,
                    primaryOrderLine: null,
                    productId: obj.mProductId,
                    realPrice: 0,
                    returnQty: null,
                    salePrice: 0,
                    stock: obj.onhandQty,
                    tax: obj.cTaxId,
                    taxAmount: 0,
                    taxRate: obj.taxRate,
                    uom: obj.csUomId,
                    uom_name: obj.uomName,
                    isDecimalQty: obj.uomData[0].length>0 ? obj.uomData[0].decimal === "Y" ? true : false : false,
                    isQtyDesimal: obj.uomData[0].length>0 ? obj.uomData[0].stdprecision : 2,
                    upc: upc,
                    value: obj.value,
                    weight: 1,
                    shortDescription: obj.shortDescription,
                    hsncode: obj.hsncode,
                    csBunitId: obj.csBunitId,
                    mProductCategoryId: obj.mProductCategoryId,
                    productManufacturerId: obj.productManufacturerId,
                    productBrandId: obj.productBrandId,
                    batchedProduct: obj.batchedProduct,
                    batchedForSale: obj.batchedForSale,
                    batchedForStock: obj.batchedForStock,
                    multiPrice: obj.multiPrice,
                    shelfLife: obj.shelfLife,
                  };
                  addProduct(productDefined, 1);
                }
              });
          } else {
            const offerProductsStack = [];
            for (let i = 0; i < pricingRule.mPricingXProducts.length; i++) {
              db.products
                .where("mProductId")
                .equalsIgnoreCase(pricingRule.mPricingXProducts[i].mProductId)
                .toArray()
                .then((product) => {
                  if (product.length > 0 && pricingRule.mPricingXProducts[i].isFree === "Y") {
                    const obj = { ...product[0] };
                    let batchno = null;
                    let mBatchId = null;
                    let upc = null;
                    if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                      const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[i].mBatchId);
                      if (bI >= 0) {
                        batchno = obj.mBatch[bI].batchno;
                        mBatchId = obj.mBatch[bI].mBatchId;
                        upc = obj.mBatch[bI].upc;
                      }
                    }
                    if (obj.overRideTax === "Y" && 0 <= obj.overRideCondition) {
                      obj.cTaxId = obj.contraTaxId;
                      obj.taxRate = obj.contraRate;
                    }
                    const productDefined = {
                      batchno: batchno,
                      description: obj.description,
                      discount: 0,
                      discountName: "",
                      imageurl: obj.imageurl,
                      isDecimal: obj.isDecimal,
                      isManualQty: obj.isManualQty,
                      isReturn: false,
                      isPromoApplicable: false,
                      mBatchId: mBatchId,
                      mPricingruleId: null,
                      name: obj.name,
                      nettotal: 0,
                      primaryOrderLine: null,
                      productId: obj.mProductId,
                      realPrice: 0,
                      returnQty: null,
                      salePrice: 0,
                      stock: obj.onhandQty,
                      tax: obj.cTaxId,
                      taxAmount: 0,
                      taxRate: obj.taxRate,
                      uom: obj.csUomId,
                      uom_name: obj.uomName,
                      isDecimalQty: obj.uomData[0]?.length>0 ? obj.uomData[0].decimal === "Y" ? true : false : false,
                      isQtyDesimal: obj.uomData[0]?.length>0 ? obj.uomData[0].stdprecision : 2,
                      upc: upc,
                      value: obj.value,
                      weight: 1,
                      shortDescription: obj.shortDescription,
                      hsncode: obj.hsncode,
                      csBunitId: obj.csBunitId,
                      mProductCategoryId: obj.mProductCategoryId,
                      productManufacturerId: obj.productManufacturerId,
                      productBrandId: obj.productBrandId,
                      batchedProduct: obj.batchedProduct,
                      batchedForSale: obj.batchedForSale,
                      batchedForStock: obj.batchedForStock,
                      multiPrice: obj.multiPrice,
                      shelfLife: obj.shelfLife,
                    };
                    offerProductsStack.push(productDefined);
                  }
                });
            }
            setTimeout(() => {
              setOfferProductList([...offerProductsStack]);
              setCart((currentBillCart) => ({
                ...currentBillCart,
                mPricingrulesId: pricingRule.mPricingrulesId,
              }));
              setDisplayOfferProductSelection(true);
            }, 500);
          }
        }
      }
    }
}