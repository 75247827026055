import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Table, Input } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "./style.css";
import "./mobile.css";
import "react-simple-keyboard/build/css/index.css";
import Scan from "../../../../assets/images/scan.gif";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";
import SMS from "../../../../assets/images/sms-solid.svg";
import Receipt from "../../../../assets/images/receipt.svg";
import CrediCard from "../../../../assets/images/credit-card.svg";
import AddBag from "../../../../assets/images/bag-outline.svg";
import QRCode from "../../../../assets/images/qr-code.svg";
import GiftCard from "../../../../assets/images/gift-card.svg";
import Loyality from "../../../../assets/images/loyalty.svg";
import Sucess from "../../../../assets/images/sucess.gif";
import Settings from "../../../../assets/images/settings.gif";
import Sync from "../../../../assets/images/dataSync.gif";
import Cashup from "../../../../assets/images/cashup.gif";
import _ from "lodash";

import { PlusOutlined, MinusOutlined, CloseSquareFilled } from "@ant-design/icons";

const PaymentSelection = (props) => {
  const {
    addProduct,
    cart,
    checkIsManualWeight,
    clearProductSearchResults,
    decreaseProductQty,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    openPaymentModal,
    parkBill,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    productsList,
    selectProductCategory,
    selectProductInCart,
    selectedProductInCart,
    selectedRowKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    tillData,
    clearSelectedProductInCart,
    productListCardRef,
    removeCutomer,
    selectedProductCategory,
    handleHorizantalScroll,
    handleScroll,
    handleKeyvalue,
    selectdata,
    removeProductModal,
    ref,
    productItems,
    setProductItems,
    productArray,
    setProductArray,
    setIsProductsVisible,
    requestPayment,
    selectedPaymentMethod,
    processOrder,
    amount,
    setKioskUI,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    openPaymentModalByCustomer
  } = props;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const currenciesList = tillData?.tillAccess?.csBunit?.currencies;
  const customerId = tillData?.tillAccess?.csBunit.b2cCustomer.cwrCustomerId;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userName = userData?.user;
  const [layoutType, setLayoutType] = useState(0);
  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <div className="customer-login">
        <img
          src={kisokLogo}
          style={{ height: "2.6vw", position: "relative", top: "2.3vh", left: "1.2vw" }}
          onClick={() => {
            localStorage.setItem("layoutType", 0);
            const customEvent1 = new CustomEvent("customStorageChange", {
              detail: { key: "layoutType", newValue: 0 },
            });
          }}
        />
        <p className="user-name">Hey, {userName}</p>
      </div>
      {layoutType === 0 ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column" }}>
          <p style={{ fontSize: "2.15vw", color: "#fff", textAlign: "center", fontWeight: "500" }}>Choose Your Preferred Payment Method</p>
          <div style={{ display: "flex", paddingTop: "3.4%", paddingBottom: "8vh", justifyContent: "start", width: "100%", paddingLeft: "8%" }}>
          {_.sortBy(tillDataPaymentMethods, "sequenceNo").map((pm, ind) => {
      return posConfig.loyaltyApplicable === "N" && pm.name === "Loyalty" ? null : (
<div
              style={{
                height: "32.5vh",
                width: "18.2vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginRight: "4.1%",
                borderRadius: "10px",
              }}
              onClick={() => {
                requestPayment(pm, cart.total);
                openPaymentModalByCustomer();
                setTimeout(() => {
                  setKioskUI(0);
                  localStorage.setItem("kioskUI", 0);
                  localStorage.setItem("layoutType", 0);
                  const customEvent1 = new CustomEvent("customStorageChange", {
                    detail: { key: "layoutType", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent1);
                  const customEvent = new CustomEvent("customStorageChange", {
                    detail: { key: "kioskUI", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent);
                }, 1000);
              }}
            >
              <div style={{ height: "18vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={CrediCard} style={{ height: "9vh" }} />
              </div>

              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "60%",
                  textAlign: "center",
                  lineHeight: "1.2",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                Credit / Debit Card
              </p>
            </div>
      );
          })}

            <div
              style={{
                height: "32.5vh",
                width: "18.2vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginRight: "4.1%",
                borderRadius: "10px",
              }}
              // onClick={() => setLayoutType(1)}
              onClick={() => {
                requestPayment(selectedPaymentMethod, cart.total);
                setTimeout(() => {
                  processOrder();
                  setKioskUI(0);
                  localStorage.setItem("kioskUI", 0);
                  localStorage.setItem("layoutType", 0);
                  const customEvent1 = new CustomEvent("customStorageChange", {
                    detail: { key: "layoutType", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent1);
                  const customEvent = new CustomEvent("customStorageChange", {
                    detail: { key: "kioskUI", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent);
                }, 500);
              }}
            >
              <div style={{ height: "18vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={GiftCard} style={{ height: "9vh" }} />
              </div>

              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "60%",
                  textAlign: "center",
                  lineHeight: "1.2",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                Gift card/ Voucher
              </p>
            </div>

            <div
              style={{
                height: "32.5vh",
                width: "18.2vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginRight: "4.1%",
                borderRadius: "10px",
              }}
              // onClick={() => setLayoutType(1)}
              onClick={() => {
                requestPayment(selectedPaymentMethod, cart.total);
                setTimeout(() => {
                  processOrder();
                  setKioskUI(0);
                  localStorage.setItem("kioskUI", 0);
                  localStorage.setItem("layoutType", 0);
                  const customEvent1 = new CustomEvent("customStorageChange", {
                    detail: { key: "layoutType", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent1);
                  const customEvent = new CustomEvent("customStorageChange", {
                    detail: { key: "kioskUI", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent);
                }, 500);
              }}
            >
              <div style={{ height: "18vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={Loyality} style={{ height: "9vh" }} />
              </div>

              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "60%",
                  textAlign: "center",
                  lineHeight: "1.2",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                Loyalty
              </p>
            </div>
          </div>
        </div>
      ) : layoutType === 1 ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column", position: "relative" }}>
          <img src={Sucess} style={{ height: "30vh", position: "absolute", left: 0, right: 0, top: "2%", margin: "0 auto" }} />
          <p style={{ fontSize: "2.6vw", color: "#fff", textAlign: "center", fontWeight: "500", paddingTop: "11vh", margin: "0" }}>Payment Successful</p>
          <p style={{ fontSize: "3.3vw", color: "#fff", textAlign: "center", fontWeight: "500", paddingTop: "2vh", margin: "0", paddingBottom: "3vh" }}>
            How would you like your receipt ?
          </p>
          <div style={{ display: "flex", paddingTop: "2.4%", paddingBottom: "8vh", justifyContent: "center", width: "100%" }}>
            <div
              style={{
                height: "25.5vh",
                width: "18.2vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginRight: "8%",
                borderRadius: "10px",
              }}
              onClick={() => setLayoutType(3)}
            >
              <img src={Receipt} style={{ height: "7vh" }} />
              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "1vh",
                  lineHeight: "1.2",
                  position: "relative",
                  bottom: "-4vh",
                  fontWeight: "500",
                }}
              >
                Print Receipt
              </p>
            </div>

            <div
              style={{
                height: "25.5vh",
                width: "18.2vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                borderRadius: "10px",
              }}
            >
              <img src={SMS} style={{ height: "7vh" }} />
              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "1vh",
                  lineHeight: "1.2",
                  position: "relative",
                  bottom: "-4vh",
                  fontWeight: "500",
                }}
              >
                Digital Receipt
              </p>
            </div>
          </div>
        </div>
      ) : layoutType === 3 ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column" }}>
          <div style={{ display: "flex", paddingTop: "3.4%", paddingBottom: "8vh", justifyContent: "center", width: "100%" }}>
            <div
              style={{
                height: "43vh",
                width: "22vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginRight: "5%",
                borderRadius: "10px",
              }}
              onClick={() => setLayoutType(1)}
            >
              <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={Cashup} style={{ height: "15vh" }} />
              </div>

              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                  lineHeight: "1.2",
                  position: "relative",
                  fontWeight: "500",
                  margin: "9%",
                }}
              >
                Cash Up Details
              </p>
              <p style={{ textAlign: "center", width: "94%", color: "#fff", lineHeight: "1.2" }}>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>

            <div
              style={{
                height: "43vh",
                width: "22vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginRight: "5%",
                borderRadius: "10px",
              }}
              onClick={() => setLayoutType(1)}
            >
              <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={Sync} style={{ height: "15vh" }} />
              </div>

              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                  lineHeight: "1.2",
                  position: "relative",
                  fontWeight: "500",
                  margin: "9%",
                }}
              >
                Data Sync
              </p>
              <p style={{ textAlign: "center", width: "94%", color: "#fff", lineHeight: "1.2" }}>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>

            <div
              style={{
                height: "43vh",
                width: "22vw",
                backgroundColor: "#626367",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                //   marginRight: "5%",
                borderRadius: "10px",
              }}
              onClick={() => setLayoutType(1)}
            >
              <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={Settings} style={{ height: "15vh" }} />
              </div>

              <p
                style={{
                  wordBreak: "break-word",
                  fontSize: "2vw",
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                  lineHeight: "1.2",
                  position: "relative",
                  fontWeight: "500",
                  margin: "9%",
                }}
              >
                Kiosk Settings
              </p>
              <p style={{ textAlign: "center", width: "94%", color: "#fff", lineHeight: "1.2" }}>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>
          </div>
        </div>
      ) : null}

      {layoutType === 0 ? (
        <div style={{ position: "absolute", right: "3%", bottom: "4vh" }}>
          <p style={{ fontSize: "2.15vw", color: "#fff", margin: "0", fontWeight: "500" }}>
            Total Amount To Pay {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
            &nbsp;
            {parseFloat(cart.total).toFixed(2)}
            &nbsp;
            {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
          </p>
        </div>
      ) : null}

      {layoutType === 3 ? (
        <div className="left-bottom-images">
          <div className="bottom-img">
            <img src={backImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Back</p>
          </div>
          <div className="bottom-img">
            <img src={helpImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Help</p>
          </div>
        </div>
      ) : (
        <div className="left-bottom-images">
          <div className="bottom-img">
            <img
              src={backImg}
              style={{ height: "2.7vw" }}
              onClick={() => {
                setKioskUI(3);
                localStorage.setItem("layoutType", 1);
                const customEvent1 = new CustomEvent("customStorageChange", {
                  detail: { key: "layoutType", newValue: 1 },
                });
                window.dispatchEvent(customEvent1);
                localStorage.setItem("kioskUI", 3);
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "kioskUI", newValue: 3 },
                });
                window.dispatchEvent(customEvent);
              }}
            />
            <p style={{ color: "#fff", margin: "0" }}>Back</p>
          </div>
          <div className="bottom-img">
            <img src={volumeImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Volume</p>
          </div>
          <div className="bottom-img">
            <img src={wheelchairImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
          </div>
          <div className="bottom-img">
            <img src={helpImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Help</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentSelection;
