import React, { Fragment, useContext, useState, useEffect, useRef, createContext, Component } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col, Card, Tooltip, Table, Divider, Button, Input, Menu, Spin, Badge, message, Select, DatePicker, Form, Tabs } from "antd";
import {
  LoadingOutlined,
  RightOutlined,
  DownOutlined,
  UserOutlined,
  PrinterOutlined,
  CloseCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import MenuIcon from "../../../assets/images/material-symbols_menu.svg";
import Success from "../../../assets/images/sucess.png";
import Retrieve from "../../../assets/images/retrieve.png";
import Discard from "../../../assets/images/returnIcon.svg";
import DownloadIcon from "../../../assets/images/downloadIcon.png";
import printerIcon from "../../../assets/images/printerIcon.svg";
import BackArrow from "../../../assets/images/back_arrow.svg";
import CancelArrow from "../../../assets/images/cancelArrow.svg";
import Cancel from "../../../assets/images/discard.png";
import Logout from "../../../assets/images/logout-new.svg";
import DeleteProduct from "../../../assets/images/delete1.png";
import Logo from "../../../assets/images/logo-without-bg.png";
import Offers from "../../../assets/images/offers.png";
import userIcon from "../../../assets/images/userIcon.svg";
import RetailModals from "./RetailModals";
import "react-aspect-ratio/aspect-ratio.css";
import "../../style.css";
import "./styles.css";
import "../../globalStyles.css";
import data from "../../../constants/retailPos.json";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { useWindowDimensions } from "../../../lib/useViewport";
import BarCodeComponentForRetail from "../../../lib/BarCodeComponentForRetail";
import db from "../../../database";
import POSIcon from "./SvgComponent/POS";
import ParkedBillIcon from "./SvgComponent/ParkedBill";
import CashManagementIcon from "./SvgComponent/CashManagementIcon";
import SalesHistoryIcon from "./SvgComponent/SalesHistoryIcon";
import SyncIcon from "./SvgComponent/SyncIcon";
import { v4 as uuidv4 } from "uuid";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../lib/printer/xmlPrinter";
import { processSync } from "../../Sync/syncData";
import Axios from "axios";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import CartPage from "./CartPage";
const EditableContext = createContext(null);
const serverUrl = process.env.REACT_APP_serverUrl;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};


const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, setModalEditState, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    setModalEditState(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const checkManualQty = (value) => {
    if (!Number.isInteger(value)) {
      if (record.product.isManualQty) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      values.qtyToReturn = parseFloat(values.qtyToReturn);
      let returnTotal = record.qty - record.returnQty;
      if (values.qtyToReturn > returnTotal) {
        message.warning("Return Qty can't be more than ordered quantity !");
        throw new Error("Qty is more than ordered, invalid value");
      } else if (checkManualQty(values.qtyToReturn)) {
        message.warning("Return Qty can't be a Decimal Value !");
        throw new Error("Qty is not a valid type, invalid value");
      } else {
        toggleEdit();
        handleSave({ ...record, ...values });
      }
    } catch (errInfo) {
      console.error("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};


// PointOfSaleRetail Component Start
const PointOfSaleRetail = (props) => {
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const {
    addProduct,
    cart,
    setCart,
    checkIsManualWeight,
    clearProductSearchResults,
    closeProductPanel,
    confirmDiscardCart,
    decreaseProductQty,
    deleteCart,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    onBarcodeInput,
    openDisplayParkedBillModal,
    openPaymentModal,
    orderType,
    parkBill,
    parkedList,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    productsList,
    setProductsList,
    selectProductCategory,
    selectProductInCart,
    selectProduct,
    selectedProductInCart,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setDisplayOrderType,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    showOrderHistory,
    tillData,
    openSideMenu,
    currencyType,
    setSalesRepModalOpen,
    salesRepresent,
    setSalesRepresent,
    setSalesRepresentSearchInput,
    setFilteredSalesRepresentList,
    salesReprestiveList,
    setManualDiscountInput,
    setManualDiscountModalVisible,
    clearSelectedProductInCart,
    productListCardRef,
    posLogActivity,
    removeCutomer,
    // parked Data
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    searchParkedBill,
    parkedBillSearchInput,
    filterdParkedList,
    discardParkedBill,
    selectParkedBill,
    // Cash Management
    setAddCashFlag,
    // Order History
    setOrderHistoryInput,
    searchOrderHistory,
    changeOrderHistorySearchType,
    orderHistoryInput,
    orderHistorySearchInputRef,
    orderHistoryDetails,
    setOrderHistoryDetails,
    paymentProcessFlag,
    setPaymentProcessFlag,
    tillDataPaymentMethods,
    requestPayment,
    setCouponModalVisible,
    paymentCardRef,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    amount,
    onChangeAmount,
    paymentModalInputRef,
    processOrder,
    paymentModalLoyalityMessages,
    handleAmount,
    selectedProductCategory,
    setCashAddInFlag,
    addCashFlag,
    isPrintModeXML,
    setSelectedKeys,
    setDisplayReturnOrderSearch,
    displayReturnOrderSearch,
    setIsProductsVisible,
    setAmount,
    pickProduct,
    selectProductToCart,
  } = props;
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);


  const { Option } = Select;
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const [keyValue, setKeyValue] = useState("0");
  const { width } = useWindowDimensions();
  const [cashManagementData, setCashManagementData] = useState(false);
  const [productArray, setProductArray] = useState([...productsList]);
  const [productItems, setProductItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showReturnPurchase, setShowReturnPurchase] = useState(false);
  const [paymentType, setPaymentType] = useState(tillDataPaymentMethods);

  // Return Bill start
  const [retrunSearchType, setReturnSearchType] = useState("orderNo");
  const [selectedReturnOrder, setSelectedReturnOrder] = useState({});
  const [modalEditState, setModalEditState] = useState(false);
  const [returnProcessLoading, setReturnProcessLoading] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [event, setEvent] = useState('')

  const searchReturnHistory = async (documentno) => {
    const searchQuery = `${retrunSearchType} : "${documentno}"`;
    const authHeaders = getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      try {
        // setLoader(true);
        const searchData = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query {
                      verifyReturn(${searchQuery}) {
                          sOrderID
                          created
                          createdby
                          updated
                          updatedby
                          documentno
                          dateordered
                          totalQty
                          orderTime
                          cwrProductQty
                          taxamt
                          grosstotal
                          discAmount
                          csBUnit {
                              csBunitId
                              name
                          }
                          cwrB2cCustomer {
                              cwrCustomerId
                              code
                              name
                              mobileNo
                              pincode
                              email
                              retlLoyaltyBalance
                              sCustomer {
                                  sCustomerID
                                  customerCategory {
                                      sCustomerCateforyId
                                      value
                                      name
                                      description
                                  }
                              }
                          }
                          saleType {
                              cwrSaletypeId
                              name
                              value
                          }
                          cwrTill {
                              cwrTillID
                              till
                          }
                          finReceiptPlan {
                              finReceiptPlanDetails {
                                  amount
                                  cwrPaymentmethod {
                                      cWRPaymentMethodID
                                      finFinancialAccountId
                                      finPaymentmethodId
                                      integratedPayment
                                      isloyalty
                                      paymentProvider
                                  }
                              }
                          }
                          line {
                              sOrderlineID
                              sOrderId
                              line
                              description
                              qty
                              netlist
                              netunit
                              linetax
                              unittax
                              linenet
                              linegross
                              grosslist
                              grossstd
                              returnline
                              returnQty
                              discount
                              product {
                                  mProductId
                                  name
                                  value
                                  upc
                                  hsncode
                                  imageurl
                                  isManualQty
                                  shortDescription
                                  returnable
                                  returnDays
                              }
                              uom {
                                  csUomId
                                  name
                              }
                              tax {
                                  csTaxID
                                  name
                                  rate
                              }
                              pricingRule {
                                  mPricingrulesId
                                  name
                              }
                          }
                      }
                  }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        });
        selectPurchaseBillForReturn(searchData.data.data.verifyReturn);
        setShowReturnPurchase(true);
      } catch (error) {
        console.error(error);
      } finally {
        // setLoader(false);
      }
    }
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
    handleSaveReturnCartRecord(newData);
    setTimeout(() => {
      returnProcessRef.current.focus();
    }, 750);
  };

  const columns1 = [
    {
      title: "Description",
      dataIndex: "product",
      width: "43%",
      render: (_, record) => {
        return record.product.name;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "grossstd",
      render: (_, record) => {
        return parseFloat(record.grossstd).toFixed(2);
      },
    },
    {
      title: "Qty Ordered",
      dataIndex: "qty",
    },
    {
      title: "Qty to Return",
      dataIndex: "qtyToReturn",
    },
  ];

  let index = 0;
  const selectPurchaseBillForReturn = (data) => {
    if (index === 0) {
      let returnBillItem = data[0];
      setDisplayReturnOrderSearch(false);
      setReturnSearchType("orderNo");

      const discountValue = returnBillItem.discAmount;
      let grossstdTotal = 0;
      for (let i = 0; i < returnBillItem.line.length; i += 1) {
        grossstdTotal += parseFloat(returnBillItem.line[i].grossstd);
      }
      const zeroDiscountCount = returnBillItem.line.filter((item) => item.discount === 0).length;

      if (zeroDiscountCount === returnBillItem.line.length && discountValue > 0) {
        returnBillItem.line.forEach((addedToCart, index) => {
          const discountAmt = parseFloat((addedToCart.grossstd / grossstdTotal) * discountValue) / addedToCart.qty;
          const sp = parseFloat(addedToCart.grossstd) - discountAmt;
          addedToCart.grossstd = sp;
          returnBillItem.line[index] = addedToCart;
        });
      }
      const updatedDataSource = [...returnBillItem.line];

      updatedDataSource.forEach((_, i) => {
        updatedDataSource[i].key = `${i}`;
        updatedDataSource[i].qtyToReturn = updatedDataSource[i].qtyToReturn ? updatedDataSource[i].qtyToReturn : 0;
      });

      updatedDataSource.itemsToReturn = 0;
      updatedDataSource.itemsQtyToReturn = 0;
      updatedDataSource.returnAmountTotal = 0;

      setDataSource([...updatedDataSource]);
      setSelectedReturnOrder({ ...returnBillItem });
      index++;
    }
  };

  const handleSaveReturnCartRecord = (data) => {
    let itemsToReturn = 0;
    let itemsQtyToReturn = 0;
    let returnAmountTotal = 0;

    data.forEach((item, i) => {
      if (item.qtyToReturn > 0) {
        itemsToReturn += 1;
        itemsQtyToReturn += item.qtyToReturn;
        returnAmountTotal += item.qtyToReturn * item.grossstd;
      }
    });

    selectedReturnOrder.line = data;
    selectedReturnOrder.itemsToReturn = itemsToReturn;
    selectedReturnOrder.itemsQtyToReturn = itemsQtyToReturn;
    selectedReturnOrder.returnAmountTotal = returnAmountTotal;
    setSelectedReturnOrder({ ...selectedReturnOrder });
  };

  const addSelectedReturnProductsToCart = async () => {
    const customerResult = await getCustomerData(selectedReturnOrder.cwrB2cCustomer.mobileNo);
    if (!customerResult) {
      setCart({
        ...cart,
        customer: customerResult === null ? cart.customer : customerResult,
      });
    } else {
      setCart({
        ...cart,
        customer: selectedReturnOrder.cwrB2cCustomer,
      });
      // selectedReturnOrder.customer = selectedReturnOrder.cwrB2cCustomer
    }

    for (let index = 0; index < selectedReturnOrder.line.length; index++) {
      const item = { ...selectedReturnOrder.line[index] };
      const productItem = await getProductData(item.product.mProductId);
      if (productItem) {
        productItem.realPrice = item.grossstd;
        productItem.salePrice = item.grossstd;
        productItem.mrpPrice = item.grossstd;
        productItem.sOrderReturnId = item.sOrderId;
        productItem.sOrderlineReturnId = item.sOrderlineID;
        addProduct(productItem, -item.qtyToReturn);
        if (
          db.logConfiguration.toArray().then((fetched) => {
            fetched.map((item) => {
              item.salesReturn.filter((item) => item.log === "Y").length > 0;
            });
          })
        ) {
          posLogActivity(productItem, "SLR");
        }
        await new Promise((r) => setTimeout(r, 900));
      }
    }

    setKeyValue("0");
    setReturnProcessLoading(false);
  };

  const getCustomerData = (mobileNo) => {
    const authHeaders = getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      return new Promise(function (resolve) {
        Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query{
              customerSearch(mobileNo:"${mobileNo}"){
                cwrCustomerId 
                code   
                name
                email        
                mobileNo
                name
                pincode
                retlLoyaltyBalance
                b2cRegisteredstoreId
                 iscredit
                balancepoints
                loyaltyLevel{
                    cwrLoyaltyLevelId
                    name
                    accumulationRate
                    redemptionRate
                }
                sCustomer{
                  sCustomerID
                  customerCategory{
                    sCustomerCateforyId
                    value
                    name
                    description
                  }
                }
                csBunit{
                  csBunitId
                  name
                }
                b2cCustomerSegment{
                  name
                  cwrB2CCustomerSegmentId
                }
              }
            }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        })
          .then((response) => {
            if (response.data.data.customerSearch !== null && response.data.data.customerSearch.length > 0) {
              const result = response.data.data.customerSearch;
              resolve(result[0]);
            } else {
              console.warn("No customer found, try searching with different customer");
              resolve(null);
            }
          })
          .catch(() => {
            resolve(null);
          });
      });
    }
  };

  const getProductData = (data) => {
    return new Promise(function (resolve) {
      db.products
        .where("mProductId")
        .equalsIgnoreCase(data)
        .toArray()
        .then((product) => {
          if (product.length > 0) {
            const obj = { ...product[0] };
            if (obj.overRideTax === "Y" && obj.sunitprice <= obj.overRideCondition) {
              // prettier-ignore
              const originalPrice = obj.sunitprice - (obj.sunitprice - (obj.sunitprice * (100 / (100 + obj.taxRate))));
              const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
              obj.sunitprice = taxedPrice;
              obj.cTaxId = obj.contraTaxId;
              obj.taxRate = obj.contraRate;
            }
            const productDefined = {
              batchno: null,
              description: obj.description,
              discount: 0,
              discountName: "",
              imageurl: obj.imageurl,
              isDecimal: obj.isDecimal,
              isManualQty: obj.isManualQty,
              isPromoApplicable: false,
              isReturn: true,
              mBatchId: null,
              mPricingruleId: null,
              name: obj.name,
              name2: obj.name2,
              nettotal: 0,
              primaryOrderLine: null,
              productId: obj.mProductId,
              realPrice: obj.sunitprice,
              returnQty: null,
              salePrice: obj.sunitprice,
              mrpPrice: obj.sunitprice,
              stock: obj.onhandQty,
              tax: obj.cTaxId,
              taxAmount: 0,
              taxRate: obj.taxRate,
              uom: obj.csUomId,
              uom_name: obj.uomName,
              upc: obj.upc,
              value: obj.value,
              weight: 0,
              shortDescription: obj.shortDescription,
              hsncode: obj.hsncode,
              csBunitId: obj.csBunitId,
              mProductCategoryId: obj.mProductCategoryId,
              productManufacturerId: obj.productManufacturerId,
              productBrandId: obj.productBrandId,
              batchedProduct: obj.batchedProduct,
              batchedForSale: obj.batchedForSale,
              batchedForStock: obj.batchedForStock,
              multiPrice: obj.multiPrice,
              shelfLife: obj.shelfLife,
            };
            resolve(productDefined);
          } else {
            message.warning("Product Not Found !");
            resolve(null);
          }
        });
    });
  };

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey && keyCode === 82) {
      if (posConfig.showSalesReturn === "Y") {
        setDisplayReturnOrderSearch(true);
      }
    }
  };

  useEffect(() => {
    let methods = [];
    tillDataPaymentMethods.map((item) => {
      if (item.name === "Cash" && posConfig.enableCash === "Y") {
        methods.push(item);
      } else if (item.name === "Card" && posConfig.enableCard === "Y") {
        methods.push(item);
      } else if (item.name !== "Cash" && item.name !== "Card") {
        methods.push(item);
      }
    })
    setPaymentType(methods)
    if (!localStorage.getItem("salesReturn")) {
      localStorage.setItem("salesReturn", "N");
    }

    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const returnBillSearchInputRef = useRef();
  useEffect(() => {
    if (displayReturnOrderSearch) {
      setTimeout(() => {
        returnBillSearchInputRef.current.focus();
      }, 100);
    }
  }, [retrunSearchType, displayReturnOrderSearch]);

  const returnProcessRef = useRef();

  // Return Bill End

  const history = useHistory();

  const ref = useRef(null);

  useEffect(async () => {
    filterdParkedList?.map((item, index) => {
      item.key = uuidv4().replace(/-/g, "").toUpperCase();
    });
    if (paymentProcessFlag === false) {
      productsList.map((item) => {
        item.selected = "N";
      });
      setProductsList(productsList);
      setTimeout(() => {
        setKeyValue("0");
      }, 1000);
    }
    if (posConfig.allowSplitTender === "N") {
      setAmount(cart.total);
    }
  }, [paymentProcessFlag]);

  useEffect(async () => {
    let data = await db.cashInCashOut.toArray();
    setCashManagementData(data);
  }, [addCashFlag])

  orderHistoryDetails.map((item) => {
    item.name = item.customer.name;
    item.total = Number(item.total).toFixed(2)
    item.status = item.isSynced === 1 ? "Success" : "Pending";
  });

  const removeProductModal = (record) => {
    // console.log(record, "record")
    deleteProduct(record);
  };

  const deleteProductModal = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "This action will remove all products from the cart, including the selected customer.Do you wish to proceed?",
        icon: null,
        cancelText: "No",
        okText: "Yes",
        onOk() {
          deleteCart(true);
          setEvent("")
        },
      });
    }
  };

  const selectdata = (e) => {
    if (e === "discount") {
      setManualDiscountModalVisible(true);
      setManualDiscountInput("");
    } else if (e === "parkBill") {
      openDisplayParkedBillModal();
      setEvent('')
    } else if (e === "clearCart") {
      productsList.map((ele) => {
        ele.selected = "N";
      });
      setProductsList(productsList);
      deleteProductModal();
    }
  };

  const columns = [
    {
      title: "Parked Sale",
      dataIndex: "parkedTime",
      key: "parkedTime",
      width: "30%",

    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: "28%",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: "38%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "19%",
    },
  ];

  const orderHistoryColumns = [
    {
      title: "Document Number",
      dataIndex: "documentno",
      key: "documentno",
      width: "30%",
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      width: "28%",
    },
    {
      title: "Total Amount",

      dataIndex: "total",
      key: "total",
      width: "38%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "19%",
    },
  ];

  const cashManagementColumn = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "30%",

    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "30%",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "33%",
    },
    {
      title: "Transactions(Rs)",
      dataIndex: "amount",
      key: "amount",
      width: "20%",
      render: (text, record) => {
        return record.type === "cashOut" || record.type === "pettyCashOut" ? <span style={{ color: "red" }}>-{record.amount}</span> : record.amount;
      },
    },
  ];

  const handleKeyvalue = async (key) => {
    if (cart.items.length <= 0) {
      productsList.map((ele) => {
        ele.selected = "N";
      });
      setProductsList(productsList);
    }
    if (key === "0") {
      setSelectedPaymentMethod({});
      setKeyValue("0");
      setSelectedKeys([]);
    }
    if (key === "1") {
      filterdParkedList?.map((item, index) => {
        item.status = "Parked";
        item.key = uuidv4().replace(/-/g, "").toUpperCase();
        item.customer = item.parkedCart.customer.name;
        item.qty = item.parkedCart.totalQty;
      });
      setKeyValue("1");
      setSelectedKeys([]);
    }
    if (key === "2") {
      setKeyValue("2");
      setSelectedKeys([]);
    }

    if (key === "3") {
      showOrderHistory();
      setKeyValue("3");
      setSelectedKeys([]);
    }
    if (key === "4") {
      setLoading(true);
        await db.productCategories.clear();
        await db.AllProductCategories.clear();
        //   await db.restaurantTables.clear();
        await db.products.clear();
        await db.pricingRules.clear();
        await db.posSaletypes.clear();
        await db.productUom.clear();
        await db.rfidData.clear();
        await processSync();
      setLoading(false);
    }
    if (key === "7") {
      setKeyValue("7");
      setPaymentProcessFlag(false);
      setSelectedKeys([]);
    }
    if (key === "parked-bill") {
      filterdParkedList?.map((item, index) => {
        item.status = "Parked";
        item.key = uuidv4().replace(/-/g, "").toUpperCase();
        item.customer = item.parkedCart.customer.name;
        item.qty = item.parkedCart.totalQty;
      })
    }
  };

  const retrieveParkedData = (record) => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            You can retrieve the bill later by selecting the 'Retrieve' option in Parked Bills.
            <br />
            Do you want to continue parking the bill?
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          parkBill();
          setTimeout(() => {
            selectParkedBill(record, "management");
          }, 2000);
        },
        onCancel() {
          selectParkedBill(record, "management");
        },
      });
    } else {
      selectParkedBill(record, "management");
    }
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      selectProduct(record);
      console.log("Record expanded:", record);
    } else {
      setSelectedKeys([]);
      console.log("Record unexpanded:", record);
    }
  };

  const componentProps = {
    handleExpand,
    retrieveParkedData,
    handleKeyvalue,
    selectdata,
    deleteProductModal,
    setManualDiscountModalVisible,
    setManualDiscountInput,
    removeProductModal,
    handleKey,
    ref,
    width,
    productItems,
    setProductItems,
    productArray,
    setProductArray,
    paymentType,
    tillDataPaymentMethods,
    paymentCardRef,
    cashManagementColumn,
    cashManagementData,
    columns,
    filterdParkedList,
    retrieveParkedData,
    orderHistoryColumns,
    orderHistoryDetails,
    setIsProductsVisible,
    setIsQtyUpdate,
    setEvent,
    pickProduct,
    selectProductToCart,
    event,
    ...props,
  }


  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#fff" }} spin />} spinning={loading}>
      <div style={data.posScreenStyles.main}>
        <BarCodeComponentForRetail onScan={onBarcodeInput} />
        <div style={{ display: "flex" }}>
          {keyValue === "7" ? "" :
            <div style={{ backgroundColor: "#fff", height: "100vh", width: "6.7%", borderRight: "1px solid #d9d9d9" }}>
              <Row gutter={[0, 16]}>
                <Col span={24} style={{ marginTop: "1.4vh" }}>
                  <Tooltip>
                    <button
                      style={{
                        width: "100%",
                        background: "#fff",
                        color: "#ffffff",
                        border: "1px solid #fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={Logo} alt="remove" style={{ height: "4.5vw" }} />
                    </button>
                  </Tooltip>
                </Col>
                <Col
                  span={1}
                  className="antd-col-1"
                  style={keyValue === "0" ? { backgroundColor: "#A4CD38", marginTop: "0.7vh" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                />
                <Col id="step1" span={23} style={{ marginTop: "0.7vh" }}>
                  <button
                    className="cartMenuItems"
                    style={keyValue === "0" ? { backgroundColor: "#2F3856", border: "1px solid #2F3856" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                    onClick={() => {
                      handleKeyvalue("0");
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <POSIcon color={keyValue === "0" ? "#FFF" : "#555359"} />
                      <span className="posIcon" style={{ color: keyValue === "0" ? "#fff" : "#555359" }}>
                        Point of Sale
                      </span>
                    </div>
                  </button>
                </Col>
                <Col
                  span={1}
                  className="antd-col-1"
                  style={keyValue === "1" ? { backgroundColor: "#A4CD38", marginTop: "1.4vh" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                />
                <Col id="step2" span={23} style={{ marginTop: "1.4vh" }}>
                  <button
                    className="cartMenuItems"
                    style={keyValue === "1" ? { backgroundColor: "#2F3856", border: "1px solid #2F3856" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                    onClick={() => {
                      handleKeyvalue("1");
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <ParkedBillIcon color={keyValue === "1" ? "#FFF" : "#555359"} />
                      <span className="posMenuIcons" style={{ color: keyValue === "1" ? "#fff" : "#555359", paddingTop: "0px" }}>
                        Parked bills
                      </span>
                    </div>
                  </button>
                </Col>
                <Col
                  span={1}
                  className="antd-col-1"
                  style={keyValue === "2" ? { backgroundColor: "#A4CD38", marginTop: "2vh" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                />
                <Col id="step3" span={23} style={{ marginTop: "2vh" }}>
                  <button
                    className="cartMenuItems"
                    style={keyValue === "2" ? { backgroundColor: "#2F3856", border: "1px solid #2F3856" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                    onClick={() => {
                      handleKeyvalue("2");
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <CashManagementIcon color={keyValue === "2" ? "#FFF" : "#555359"} />
                      <span className="posMenuIcons" style={{ color: keyValue === "2" ? "#fff" : "#555359", paddingTop: "0px" }}>
                        Cash Management
                      </span>
                    </div>
                  </button>
                </Col>

                <Col
                  span={1}
                  className="antd-col-1"
                  style={keyValue === "3" ? { backgroundColor: "#A4CD38", marginTop: "1.8vh" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                />
                <Col id="step4" span={23} style={{ marginTop: "1.8vh" }}>
                  <button
                    className="cartMenuItems"
                    style={keyValue === "3" ? { backgroundColor: "#2F3856", border: "1px solid #2F3856" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                    onClick={() => handleKeyvalue("3")}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <SalesHistoryIcon color={keyValue === "3" ? "#FFF" : "#555359"} />
                      <span className="posIcon" style={{ color: keyValue === "3" ? "#fff" : "#555359" }}>
                        Sales History
                      </span>
                    </div>
                  </button>
                </Col>

                <Col
                  span={1}
                  className="antd-col-1"
                  style={keyValue === "4" ? { backgroundColor: "#A4CD38", marginTop: "1.2vh" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                />
                <Col id="step5" span={23} style={{ marginTop: "1.2vh" }}>
                  <button
                    className="cartMenuItems"
                    style={
                      keyValue === "4"
                        ? { backgroundColor: "#2F3856", border: "1px solid #2F3856", padding: "0" }
                        : { backgroundColor: "#fff", border: "1px solid #fff", padding: "0" }
                    }
                    onClick={async () => {
                      handleKeyvalue("4");
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      {/* <img src={SyncIcon} style={{ height: "2.5vh" }} color={keyValue === "4" ? "#FFF" : "#555359"} /> */}
                      <SyncIcon color={keyValue === "4" ? "#FFF" : "#555359"} />
                      <span className="posMenuIcons" style={{ color: keyValue === "4" ? "#fff" : "#555359" }}>
                        Product Sync
                      </span>
                    </div>
                  </button>
                </Col>
                <Col span={24} style={{ position: "absolute", bottom: "0", left: "0.8%" }}>
                  <div style={{ marginTop: "1.2vh", width: "5vw" }}>
                    <button
                      className="cartMenuItems"
                      style={
                        keyValue === "5"
                          ? { backgroundColor: "#2F3856", border: "1px solid #2F3856" }
                          : { backgroundColor: "#fff", border: "1px solid #fff", display: "flex", flexDirection: "column" }
                      }
                      onClick={() => {
                        handleKeyvalue("5");
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: "0.5vw",
                          border: "1px solid #555359",
                          borderRadius: "50%",
                        }}
                      >
                        <img src={userIcon} style={{ height: "2vh" }} />
                      </div>
                      <p className="posMenuTill" style={{ color: keyValue === "9" ? "#fff" : "#555359" }}>
                        Till Id : {JSON.parse(localStorage.getItem("tillValue")).name}
                      </p>
                    </button>
                  </div>
                  <div id="step6" style={{ marginTop: "1.2vh" }}>
                    <button
                      className="cartMenuItems"
                      style={keyValue === "10" ? { backgroundColor: "#2F3856", border: "1px solid #2F3856" } : { backgroundColor: "#fff", border: "1px solid #fff" }}
                      onClick={() => {
                        let posConfig = JSON.parse(localStorage.getItem("posConfig"));
                        if (posConfig.shiftClose === "Y") {
                          if (posConfig.showDenominations === "Y") {
                            history.push("/close-till");
                          } else if (posConfig.showDenominations === "N") {
                            history.push("/close-till-all");
                          }
                        } else {
                          history.push("/");
                        }
                        handleKeyvalue("10");
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <img src={Logout} style={{ height: "3vh" }} />
                      </div>
                    </button>
                  </div>
                </Col>
              </Row>
            </div>}

          <div style={{ width: keyValue === "7" ? "100%" : "93.3%" }}>
            {keyValue === "0" && <CartPage {...componentProps} />}

            {keyValue === "1" && (
              <div style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                  <Col span={24} style={{ padding: "2.3% 3.5% 0 3.5%" }}>
                    <Row style={{ paddingBottom: "5vh" }}>
                      <Col span={11}>
                        <label style={{ color: "#000", fontSize: "1.4vw" }}>Customer</label>
                        <Input
                          placeholder={`Search for customers`}
                          prefix={<UserOutlined style={{ color: "#929098", height: "2vh" }} />}
                          size="large"
                          allowClear={true}
                          style={data.posScreenStyles.orderHistoryModal.orderHistorySearchInput1}
                          onPressEnter={searchParkedBill}
                          onChange={handleParkedBillSearchInput}
                          value={parkedBillSearchInput}
                          className="parkedCustomer"
                        />
                      </Col>
                      <Col span={13} style={{ display: "flex", justifyContent: "end" }}>
                        <span style={{ position: "absolute", bottom: 0, paddingRight: "1vw", color: "#A4CD38", fontSize: "1.1vw" }}>
                          <img src={DownloadIcon} style={{ height: "1.3vw", paddingRight: "4px" }} />
                          Export list
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ padding: "0% 3.5% 0 3.5%" }}>
                  <Table
                    style={{ height: "75vh", background: "#F3F4F9", fontSize: "0.9vw", padding: "0% 1.5% 0 1.5%", borderRadius: "5px" }}
                    scroll={{ y: "68vh" }}
                    pagination={false}
                    columns={columns}
                    dataSource={filterdParkedList}
                    rowClassName={(record) => (selectedKeys[0] === record.key ? "tableSelectedBlue" : "tableNonSelectedWhite")}
                    className="parkedTable"
                    expandable={{
                      expandedRowRender: (record) => (
                        <div
                          style={{
                            padding: "1% 4% 1% 4%",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Row>
                            <Col span={21}>
                              <Row>
                                <Col span={12} className="td-nested-title">
                                  Description
                                </Col>
                                <Col span={3} className="td-nested-title" style={{ textAlign: "center" }}>
                                  Qty
                                </Col>
                                <Col span={3} offset={1} className="td-nested-title" style={{ textAlign: "center" }}>

                                  Unit Price
                                </Col>
                                <Col span={3} offset={1} className="td-nested-title" style={{ textAlign: "center" }}>

                                  Net Total
                                </Col>
                              </Row>
                              <Row style={{ paddingBottom: "0.5vw" }}>
                                {record.parkedCart.items.map((e) => {

                                  return (

                                    <>
                                      <Col span={12} className="td-nested-description">
                                        {e.name}
                                      </Col>
                                      <Col span={3} style={{ textAlign: "center", fontWeight: "500" }}>
                                        {e.weight}
                                      </Col>
                                      <Col span={1} style={{ textAlign: "center", fontWeight: "500" }} />
                                      <Col span={3} style={{ textAlign: "center", fontWeight: "500" }}>

                                        {e.realPrice.toFixed(2)}
                                      </Col>
                                      <Col span={1} style={{ textAlign: "center" }} />
                                      <Col span={3} style={{ textAlign: "right", paddingRight: "1.5vw", fontWeight: "500" }}>

                                        {(e.realPrice - record.parkedCart.tax).toFixed(2)}
                                      </Col>
                                    </>
                                  );
                                })}
                              </Row>
                              <p style={{ width: "96%", marginLeft: "0%", borderTop: "1.3px solid rgba(52, 40, 44, 0.55)", marginBottom: "0px" }} />
                              <Row style={{ paddingTop: "5px", }}>
                                <Col span={3} offset={17} className="td-subtotal">
                                  <span className="td-subTotalText">Subtotal</span>
                                  <span className="td-subTotalText" >Total tax</span>
                                </Col>

                                <Col span={3} className="td-subtotal" style={{ textAlign: "right", paddingRight: "1.5vw" }}>
                                  <span className="td-subTotalText">{record.parkedCart.total.toFixed(2) - record.parkedCart.tax.toFixed(2)}</span>
                                  <span className="td-subTotalText">{record.parkedCart.tax.toFixed(2)}</span>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: "1%" }}>
                                <Col span={3} offset={17} className="td-subtotal" style={{ padding: "4px 0" }}>
                                  <span className="td-total">SALE TOTAL</span>
                                </Col>
                                <Col
                                  span={3}
                                  style={{ fontSize: "1.1vw", borderBottom: "1px solid rgba(52, 40, 44, 0.55)", textAlign: "right", paddingRight: "1.5vw", paddingTop: "4px" }}
                                >
                                  <span className="td-total">{(record.parkedCart.total.toFixed(2))}</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={3} className="td-bottons">
                              <p
                                style={{ cursor: "pointer", padding: "5.5% 12%", borderRadius: "3px", border: "1px solid #929098" }}
                                onClick={() => {
                                  setKeyValue("0");
                                  retrieveParkedData(record);
                                }}
                              >
                                <img src={Retrieve} style={{ height: "1.1vw", paddingRight: "0.2vw", cursor: "pointer" }} />
                                Retrieve sale
                              </p>
                              <p
                                style={{ cursor: "pointer", padding: "5.5% 13%", borderRadius: "3px", border: "1px solid #929098" }}
                                onClick={() => {
                                  discardParkedBill(record);
                                }}
                              >
                                <img src={Cancel} style={{ height: "1.1vw", paddingRight: "0.4vw" }} />
                                Discard sale
                              </p>
                            </Col>
                          </Row>
                        </div>
                      ),
                      expandIcon: ({ expanded, onExpand, record }) => (
                        <span onClick={(e) => onExpand(record, e)}>
                          {expanded ? <DownOutlined style={{ color: "#929098" }} /> : <RightOutlined style={{ color: "#929098" }} />}
                        </span>
                      ),
                    }}
                    onExpand={handleExpand}
                  />
                </Row>
              </div>
            )}

            {keyValue === "2" && (
              <div style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                  <Col span={24} style={{ padding: "2.65% 3.3% 0 3.3%" }}>
                    <Row style={{ paddingBottom: "24px" }} gutter={[16, 16]}>
                      <Col span={7} offset={17} style={{ display: "flex", alignItems: "center", justifyContent: "end", }}>
                        {posConfig.pettyCash === "N" && posConfig.cashOut === "N" ? null : <Button
                          style={{
                            width: "10.3vw",
                            height: "5.7vh",
                            border: "1px solid #0F0718",
                            borderRadius: "4px",
                            fontWeight: "600",
                            marginRight: "1.4vw",
                            fontSize: "1vw",
                            color: "#0F0718",
                          }}
                          onClick={() => {
                            setAddCashFlag(true);
                            setCashAddInFlag(true);
                          }}
                        >
                          Remove Cash
                        </Button>}
                        {
                          posConfig.cashIn === "Y" && <Button
                            style={{ width: "10.3vw", height: "5.7vh", border: "1px solid #0F0718", borderRadius: "4px", fontWeight: "600", fontSize: "1vw", color: "#0F0718" }}
                            onClick={() => {
                              setAddCashFlag(true);
                              setCashAddInFlag(false);
                            }}
                          >
                            Add Cash
                          </Button>
                        }

                      </Col>
                    </Row>
                    <Row>
                      <Table
                        style={{ height: "82vh", background: "#F3F4F9", padding: "2% 3.5%", width: "100%", borderRadius: "5px" }}
                        pagination={false}
                        columns={cashManagementColumn}
                        dataSource={cashManagementData}
                        className="cash-management"
                      />
                    </Row>
                  </Col>
                </Row>
              </div>
            )}

            {keyValue === "3" && (
              <div>
                {!showReturnPurchase ? (
                  <>
                    <div style={{ display: "flex", padding: "2.6% 3.1% 0 3.1%" }}>
                      <div style={{ width: "26%", paddingRight: "2%" }}>
                        <label style={{ color: "#000", fontSize: "1.2vw" }}>Customers</label>
                        <Input
                          ref={orderHistorySearchInputRef}
                          placeholder="Search for customers"
                          prefix={<UserOutlined style={{ color: "#929098" }} />}
                          size="large"
                          allowClear={false}
                          onPressEnter={searchOrderHistory}
                          className="salesHistory-input"
                          //value={orderHistoryInput}
                          onChange={(e) => {
                            e.target.value === "" ? showOrderHistory() : setOrderHistoryInput(e.target.value);
                            changeOrderHistorySearchType("orderCustomerSearchKey");
                          }}
                        />
                      </div>
                      <div style={{ width: "26%", paddingRight: "2%", paddingLeft: "0.3%" }}>
                        <label style={{ color: "#000", fontSize: "1.2vw" }}>Date</label>
                        <DatePicker
                          placeholder={`Choose date range`}
                          onPressEnter={searchOrderHistory}
                          size="large"
                          allowClear={false}
                          onChange={(e) => {
                            e === "" ? showOrderHistory() : setOrderHistoryInput(e);
                            searchOrderHistory("orderDateSearchKey", e);
                          }}
                          className="salesHistory-datePicker"
                        />
                      </div>

                      <div style={{ width: "26%", paddingRight: "2%", paddingLeft: "0.3%" }}>
                        <label style={{ color: "#000", fontSize: "1.2vw" }}>Document Number</label>
                        <Input
                          placeholder="Search document number"
                          size="large"
                          allowClear={false}
                          className="salesHistory-doc-input"
                          onPressEnter={searchOrderHistory}
                          //value={orderHistoryInput}
                          onChange={(e) => {
                            e.target.value === "" ? showOrderHistory() : setOrderHistoryInput(e.target.value);
                            changeOrderHistorySearchType("orderDocumentNo");
                          }}
                        />
                      </div>
                      <div style={{ width: "22%", display: "flex", justifyContent: "flex-end", alignItems: "flex-end", paddingRight: "2%" }}>
                      </div>
                    </div>
                    <Row style={{ padding: "5vh 3.1% 0 3.1%" }}>
                      <Table
                        style={{ height: "75vh", background: "#F3F4F9", fontSize: "0.9vw", padding: "0 1.5%", borderRadius: "5px" }}
                        scroll={{ y: "64vh" }}
                        pagination={false}
                        columns={orderHistoryColumns}
                        dataSource={orderHistoryDetails}
                        className="salesHistory-table"
                        rowClassName={(record) => (selectedKeys[0] === record.key ? "tableSelectedBlue" : "tableNonSelectedWhite")}
                        onExpand={handleExpand}
                        expandable={{
                          expandedRowRender: (record) => (
                            <div
                              style={{
                                padding: "1% 4% 1% 4%",
                                backgroundColor: "#fff",
                              }}
                            >
                              <Row>
                                <Col span={21}>
                                  <Row>
                                    <Col span={12} className="td-nested-title">
                                      Description
                                    </Col>
                                    <Col span={3} className="td-nested-title" style={{ textAlign: "center" }}>
                                      Qty
                                    </Col>
                                    <Col span={3} offset={1} className="td-nested-title" style={{ textAlign: "center" }}>
                                      Unit Price
                                    </Col>
                                    <Col span={3} offset={1} className="td-nested-title" style={{ textAlign: "center" }}>
                                      Net Total
                                    </Col>
                                  </Row>
                                  <Row style={{ paddingBottom: "0.5vw" }}>
                                    {record.items.map((e) => {
                                      return (
                                        <>
                                          <Col span={12} className="td-nested-description">
                                            {e.name}
                                          </Col>
                                          <Col span={3} style={{ textAlign: "center", fontWeight: "500" }}>
                                            {e.weight}
                                          </Col>
                                          <Col span={1} style={{ textAlign: "center", fontWeight: "500" }} />
                                          <Col span={3} style={{ textAlign: "center", fontWeight: "500" }}>
                                            {e.realPrice.toFixed(2)}
                                          </Col>
                                          <Col span={1} style={{ textAlign: "center" }} />
                                          <Col span={3} style={{ textAlign: "right", paddingRight: "1.5vw", fontWeight: "500" }}>

                                            {(e.realPrice - record.tax).toFixed(2)}
                                          </Col>
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <p style={{ width: "96%", marginLeft: "0%", borderTop: "1.3px solid rgba(52, 40, 44, 0.55)", marginBottom: "0px" }} />
                                  <Row style={{ paddingTop: "5px" }}>
                                    <Col span={3} offset={17} className="td-subtotal">
                                      <span className="td-subTotalText">Subtotal</span>
                                      <span className="td-subTotalText">Total tax</span>
                                    </Col>
                                    <Col span={3} className="td-subtotal" style={{ textAlign: "right", paddingRight: "1.5vw" }}>
                                      <span className="td-subTotalText">{record.total - record.tax.toFixed(2)}</span>
                                      <span className="td-subTotalText">{record.tax.toFixed(2)}</span>
                                    </Col>
                                  </Row>
                                  <Row style={{ marginBottom: "1%" }}>
                                    <Col span={3} offset={17} className="td-subtotal" style={{ padding: "4px 0" }}>
                                      <span className="td-total" >SALE TOTAL</span>
                                    </Col>
                                    <Col
                                      span={3}
                                      style={{ fontSize: "1.1vw", borderBottom: "1px solid rgba(52, 40, 44, 0.55)", textAlign: "right", paddingRight: "1.5vw", paddingTop: "4px" }}
                                    >
                                      <span className="td-total">{record.total}</span>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={3} className="td-bottons">
                                  <p
                                    style={{ cursor: "pointer", padding: "5.5% 12%", borderRadius: "3px", border: "1px solid #929098" }}
                                    onClick={() => (isPrintModeXML ? xmlPrint(record, "Duplicate Copy") : htmlPrint(record, "Duplicate Copy"))}
                                  >
                                    <img src={printerIcon} style={{ height: "1.4vw", paddingRight: "0.2vw", cursor: "pointer" }} />
                                    Print receipt
                                  </p>
                                  {parseInt(record.total) > 0 &&
                                    posConfig.showSalesReturn === "Y" ? (
                                    <p
                                      style={{ cursor: "pointer", padding: "5.5% 13%", borderRadius: "3px", border: "1px solid #929098" }}
                                      onClick={() => {
                                        searchReturnHistory(record.documentno);
                                        localStorage.setItem("salesReturn", "Y");
                                      }}
                                    >
                                      <img src={Discard} style={{ height: "1.1vw", paddingRight: "0.4vw" }} />
                                      Sales Return
                                    </p>
                                  ) : null
                                  }
                                </Col>
                              </Row>
                            </div>
                          ),
                          expandIcon: ({ expanded, onExpand, record }) => (
                            <span onClick={(e) => onExpand(record, e)}>
                              {expanded ? <DownOutlined style={{ color: "#929098" }} /> : <RightOutlined style={{ color: "#929098" }} />}
                            </span>
                          ),
                        }}
                      />
                    </Row>
                  </>
                ) : (
                  <div>
                    <Row style={{ padding: "1.3% 3.5% 0 1.5%" }}>
                      <Col span={1}>
                        <img
                          src={BackArrow}
                          style={data.posScreenStyles.orderHistoryModal.backArrowIcon}

                          onClick={() => {
                            // handleKeyvalue("0");
                            if (cart.items.length <= 0) {
                              productsList.map((ele) => {
                                ele.selected = "N";
                              });
                              setProductsList(productsList);
                            }
                            setShowReturnPurchase(false)
                          }}
                        />
                      </Col>
                      <Col span={7} style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ fontSize: "1.6vw", paddingLeft: "1vw", fontWeight: "500" }}>Return Purchase</span>
                      </Col>
                    </Row>
                    <div style={{ padding: "1.5% 3.5% 0 3%" }}>
                      <Row>
                        <Table
                          className="salesReturn-table"
                          dataSource={dataSource}
                          pagination={false}
                          components={{
                            body: {
                              row: EditableRow,
                              cell: EditableCell,
                            },
                          }}
                          scroll={{ y: "48vh" }}
                          style={{ width: "100%", background: "#F3F4F9", fontSize: "0.9vw", padding: "0 2%", borderRadius: "5px" }}
                          setModalEditState={setModalEditState}
                          columns={columns1.map((col) => ({
                            ...col,
                            onCell: (record) => ({
                              record,
                              editable: col.dataIndex === "qtyToReturn" && record.product.returnable,
                              dataIndex: col.dataIndex,
                              title: col.title,
                              handleSave: handleSave,
                              setModalEditState: setModalEditState,
                            }),
                          }))}
                        />
                      </Row>
                      <Row style={{ paddingTop: "4vh" }}>
                        <Col span={11} offset={1}>
                          <p style={{ fontSize: "1.6vw" }}>Return Items / Qty: </p>
                          <p style={{ fontSize: "1.6vw" }}>Return Amount Total:</p>
                        </Col>
                        <Col span={11} style={{ textAlign: "right" }}>
                          <p style={{ fontSize: "1.6vw" }}>
                            {" "}
                            {selectedReturnOrder.itemsToReturn ? selectedReturnOrder.itemsToReturn : "0"} /{" "}
                            {selectedReturnOrder.itemsQtyToReturn ? parseFloat(selectedReturnOrder.itemsQtyToReturn).toFixed(2) : "0.00"}
                          </p>
                          <p style={{ fontSize: "1.6vw" }}> {selectedReturnOrder.returnAmountTotal ? parseFloat(selectedReturnOrder.returnAmountTotal).toFixed(2) : "0.00"}</p>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "8.3vh" }} gutter={[16]}>
                        <Col span={8} />
                        <Col span={8} style={{ textAlign: "right" }}>
                          <Button
                            style={{ width: "100%", height: "7.5vh", fontSize: "1.6vw", fontWeight: "600", borderColor: "#A4CD38", color: "#A4CD38" }}
                            onClick={() => setShowReturnPurchase(false)}
                            disabled={loader}
                          >
                            Remove
                          </Button>
                        </Col>
                        <Col span={8} style={{ textAlign: "right" }}>
                          <Button
                            style={{ width: "100%", height: "7.5vh", fontSize: "1.6vw", fontWeight: "600", backgroundColor: "#A4CD38", color: "#fff" }}
                            ref={returnProcessRef}
                            onClick={(e) => {
                              if (!returnProcessLoading && selectedReturnOrder.line.some((each) => each.qtyToReturn > 0)) {
                                setReturnProcessLoading(true); // Set the flag to indicate the return process is in progress
                                addSelectedReturnProductsToCart(e);
                              }
                            }}
                            disabled={
                              modalEditState ||
                              loader
                            }
                            //loading={returnProcessLoading}
                            className="focusDashboardCard"
                          >
                            Return
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    {returnProcessLoading && <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}><LoadingOutlined style={{ fontSize: 60 }} /></Col>}
                  </div>
                )}
              </div>
            )}

            {keyValue === "7" && (
              <>
                {paymentProcessFlag ? <div style={{ width: "100%", display: "flex" }}>
                  {/* LEFT SIDE */}
                  <div style={{ paddingTop: "1.8vh", width: "67.8%", backgroundColor: "#F3F4F9" }}>
                    <Row style={{ paddingBottom: "2.4vh", paddingLeft: "2.3vw" }}>
                      <Col span={8}>
                        <img
                          src={BackArrow}
                          style={data.posScreenStyles.orderHistoryModal.backArrowIcon}
                          onClick={() => {
                            handleKeyvalue("0");
                            if (cart.items.length <= 0) {
                              productsList.map((ele) => {
                                ele.selected = "N";
                              });
                              setProductsList(productsList);
                            }
                          }}
                        />
                      </Col>
                    </Row>

                    <div style={{ width: "100%", display: "flex" }}>
                      <div style={{ width: "18.5%" }}></div>
                      <div style={{ width: "63%" }}>
                        <div style={{ width: "100%", textAlign: "center", paddingBottom: "4.6vh" }}>
                          <span style={data.posScreenStyles.paymentModal.cartTotalValue}>
                            {parseFloat(cart.total) < 0 ? "Change" : "Amount To Pay"}

                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                            &nbsp;
                            {parseFloat(cart.total) >= parseFloat(cart.paid) ? parseFloat(cart.total - cart.paid).toFixed(2) : parseFloat(cart.paid - cart.total).toFixed(2)}{" "}
                            {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                          </span>
                        </div>
                        <Row gutter={[16, 16]} justify="center" align="center" style={{ height: "21vh" }}>
                          {_.sortBy(paymentType, "sequenceNo").map((pm, ind) => {
                            return posConfig.loyaltyApplicable === "N" && pm.name === "Loyalty" ? null : (
                              <Col span={8} style={{ width: "100%" }}>
                                <button
                                  key={pm.finPaymentmethodId}
                                  onClick={() => {
                                    if (posConfig.allowSplitTender === "Y") {
                                      setSelectedPaymentMethod(pm);

                                    } else if (cart.payments.length < 1) {
                                      setSelectedPaymentMethod(pm);
                                    }
                                  }}
                                  ref={ind === 0 ? paymentCardRef : null}
                                  style={
                                    pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId
                                      ? data.posScreenStyles.paymentModal.selectedPaymentType
                                      : data.posScreenStyles.paymentModal.paymtCard
                                  }
                                  //style={pm.name === 'Cash' ? data.posScreenStyles.paymentModal.selectedPaymentType : data.posScreenStyles.paymentModal.CardStyle}
                                  tabIndex={0}
                                  onKeyPress={(e) => (e.charCode === 13 ? setSelectedPaymentMethod(pm) : null)}
                                >
                                  {pm.name}
                                </button>
                              </Col>
                            );
                          })}
                        </Row>
                        <Row style={{ paddingBottom: "6vh" }}>
                          <Col span={24}>
                            <Input
                              size="large"
                              allowClear={false}
                              readOnly={posConfig.allowSplitTender === "N" ? true : false}
                              style={data.posScreenStyles.orderHistoryModal.paymentSearchInput}
                              value={amount}
                              onChange={onChangeAmount}
                              ref={paymentModalInputRef}
                              placeholder="Enter Amount"
                              className="totalInput"
                              disabled={tillData.tillAccess.cwrTill.layAway === "Y" && tillData.tillAccess.cwrTill.payNow === "N" ? true : false}
                              onPressEnter={parseFloat(cart.total) > parseFloat(cart.paid) && cart.payments.length>0 ? () => requestPayment(selectedPaymentMethod, amount) : () => processOrder()}
                            />
                          </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                          <Col span={1}></Col>
                          <Col span={22}>
                            <Row gutter={[10, 8]}>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("1")}>
                                  1
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("2")}>
                                  2
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("3")}>
                                  3
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("x")}>
                                  <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 30} height={width <= 1024 ? 15 : 20} />
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("4")}>
                                  4
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("5")}>
                                  5
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("6")}>
                                  6
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount(".")}>
                                  .
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("7")}>
                                  7
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("8")}>
                                  8
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("9")}>
                                  9
                                </button>
                              </Col>
                              <Col span={6}>
                                <button className="amt-dial-btn" onClick={() => handleAmount("0")}>
                                  0
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                      </div>
                      <div style={{ width: "18.5%" }}></div>
                    </div>
                  </div>
                  {/* RIGHT SIDE */}
                  <div style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.14)", width: "32.2%", backgroundColor: "#fff" }}>
                    <Row style={{ padding: "6px 10px 10px 10px", height: "100vh" }}>
                      <Col span={24} style={{ paddingTop: "1.7vh" }}>
                        <Row>
                          <Col span={9} className="cartStyle">
                            <span>CART</span>
                            <span style={{ fontSize: "1vw", paddingLeft: "0.5vw", paddingBottom: "0.3vw", color: "rgba(0, 0, 0, 0.35)", fontWeight: "600" }}>
                              {cart.totalQty} Item(s)
                            </span>
                          </Col>
                          <Col span={10} />
                        </Row>
                        <Row style={{ paddingTop: "8px", height: "91.6vh", }}>
                          <Col span={24}>
                            <div style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 0, backgroundColor: "red" }}>
                              <Table
                                className="cart-table"
                                style={data.posScreenStyles.cartTable.tableStl}
                                pagination={false}
                                dataSource={[...cart.items]}
                                sticky
                                // scroll={{ y: "60vh" }}
                                scroll={{ y: cart.payments.length > 0 ? "50vh" : "60vh" }}

                                //cart.payments.length > 0 ? "1px solid rgba(0, 0, 0, 0.27)" : ""




                                rowClassName={"tableRowNonSelectedWhite"}
                                columns={[
                                  {
                                    title: "Item name",
                                    dataIndex: "name",
                                    key: "name",
                                    width: "55%",
                                    render: (text, record, index) => {
                                      return (
                                        <>
                                          <div
                                            onClick={() => {
                                              selectProductInCart(record, "1");
                                              setIsSearchProducts(false);
                                              if (selectedRowKeys.length > 0 && selectedProductInCart.productId === record.productId) {
                                                setIsQtyUpdate(true)
                                              }
                                            }}
                                            className="productName1"
                                          >
                                            {record.name}
                                          </div>
                                          <div className="offerName">
                                            {cart.discount > 0 ? record.discountName || "" : ""}{" "}
                                            {cart.discount > 0 ? record.discountName ? <img src={Offers} alt="" width={15} height={15} style={{ marginTop: "-2px" }} /> : "" : ""}
                                          </div>
                                        </>
                                      );
                                    },
                                  },
                                  {
                                    title: "Qty",
                                    dataIndex: "weight",
                                    key: "weight",
                                    width: "15%",
                                    render: (text, record, index) => {
                                      const wt = parseFloat(text);
                                      if (Number.isInteger(wt)) {
                                        return (
                                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                            <div style={{ margin: "0 3px", fontWeight: "500", fontSize: "0.9vw" }}>{wt}</div>
                                            {/* <div className="offerName">
                                          {record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}
                                        </div> */}
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <div>{parseFloat(text).toFixed(2)}</div>
                                            <div className="offerName">
                                              {record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}
                                            </div>
                                          </>
                                        );
                                      }
                                    },
                                  },
                                  {
                                    title: "Amount",
                                    dataIndex: "nettotal",
                                    key: "nettotal",
                                    render: (text, record) => {
                                      return (
                                        <div>
                                          {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                          &nbsp;
                                          <span style={{ fontWeight: "500", fontSize: "0.9vw" }}>{parseFloat(text).toFixed(2)}</span>
                                          &nbsp;
                                          {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                        </div>
                                      );
                                    },
                                    align: "right",
                                  },
                                ]}
                                onRow={(record) => ({
                                  onDoubleClick: () => {
                                    selectProductInCart(record);
                                  },
                                })}
                              />
                              <div style={data.posScreenStyles.cartTable.prodTotal}>
                                <div
                                  style={{
                                    padding: "1% 1.5%",
                                    borderTop: cart.payments.length > 0 ? "1px solid rgba(0, 0, 0, 0.27)" : "",
                                  }}
                                >
                                  {cart.payments.map((item) => {
                                    return (
                                      <Row style={{ paddingTop: "0.4vw" }}>
                                        <Col span={12}>
                                          <p style={{ textAlign: "left", margin: "0", fontWeight: "600", fontSize: "1.3vw" }}>{item.name}</p>
                                          <p style={{ margin: "0", fontSize: "1vw" }}>{new Date().toLocaleDateString("zh-Hans-CN")}</p>
                                        </Col>
                                        <Col span={12}>
                                          <p style={{ textAlign: "right", fontWeight: "600" }}>
                                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                            &nbsp;{item.amount} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                          </p>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>
                                <div style={{ backgroundColor: "#fff", borderTop: "1px dashed #929098" }}>
                                  <Row style={{ padding: "0px 5px 0 5px", paddingTop: "3vh" }}>
                                    <Col span={6} offset={1}>
                                      <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                                        Tax
                                      </label>
                                    </Col>
                                    <Col span={16} style={data.posScreenStyles.cartTable.amtTextAlign}>
                                      <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                        &nbsp;
                                        {parseFloat(cart.tax).toFixed(2)}
                                        <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                                      </label>
                                    </Col>
                                    <Col span={1} />
                                  </Row>
                                  <Row style={{ padding: "0px 5px 0 5px", backgroundColor: "" }}>
                                    <Col span={6} offset={1}>
                                      <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                                        Discounts
                                      </label>
                                    </Col>
                                    <Col span={16} style={data.posScreenStyles.cartTable.amtTextAlign}>
                                      <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                        &nbsp;
                                        {parseFloat(cart.discount).toFixed(2)}
                                        <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                                      </label>
                                    </Col>
                                    <Col span={1} />
                                  </Row>
                                  <Row style={{ padding: "0px 5px 0 5px", backgroundColor: "" }}>
                                    <Col span={6} offset={1}>
                                      <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                                        Items / Qty
                                      </label>
                                    </Col>
                                    <Col span={16} style={data.posScreenStyles.cartTable.amtTextAlign}>
                                      <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                                        &nbsp;
                                        {cart.items.length} / {parseFloat(cart.totalQty).toFixed(2)}
                                      </label>
                                    </Col>
                                    <Col span={1} />
                                  </Row>
                                  <Row style={{ padding: "0px 5px 0 5px", backgroundColor: "" }}>
                                    <Col span={12} offset={1}>
                                      <label style={data.posScreenStyles.cartTable.taxTotal} className="taxDisLbl">
                                        Total Amount
                                      </label>
                                    </Col>
                                    <Col span={10} style={data.posScreenStyles.cartTable.amtTextAlign}>
                                      <label style={data.posScreenStyles.cartTable.taxTotal} className="taxDisLbl">
                                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                        &nbsp;
                                        {parseFloat(cart.total).toFixed(2)}
                                        <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                                      </label>
                                    </Col>
                                    <Col span={1} />
                                  </Row>
                                  <Row style={{ padding: "1.4vh 0.14vh 0.7vh 1.4vh", display: "flex", justifyContent: "center", paddingTop: "1.5vh" }}>
                                    {tillData.tillAccess.cwrTill.payNow === "Y" ? (
                                      <Button
                                        size="large"
                                        onClick={parseFloat(cart.total) > parseFloat(cart.paid) ? () => posConfig.enableCash === "N" && posConfig.enableCard === "N" ? "" : requestPayment(selectedPaymentMethod, amount) : () => processOrder()}
                                        className="totalBtn"
                                        style={data.posScreenStyles.cartTable.totalBtn}
                                      >
                                        {cart.items.length !== 0
                                          ? parseFloat(cart.total) > parseFloat(cart.paid)
                                            ? paymentModalLoyalityMessages.buttonText
                                            : "Complete Order"
                                          : "Payment Not Allowed !"}
                                      </Button>
                                    ) : null}
                                    {tillData.tillAccess.cwrTill.layAway === "Y" ? (
                                      <Button
                                        size="large"
                                        onClick={() => processOrder("layaway")}
                                        className="totalBtn"
                                        style={data.posScreenStyles.cartTable.totalBtn}
                                      >
                                        {cart.items.length !== 0 ? "Layaway" : "Operation Not Allowed !"}
                                      </Button>
                                    ) : null}
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div> :
                  <div style={{ padding: "3%", width: "100%", backgroundColor: "#F3F4F9", height: "100vh" }}>
                    <Row style={{ position: "absolute", top: "3%", left: "3%" }}>
                      <Col span={8}>
                        <img
                          src={Logo}
                          style={{ height: "4.5vw" }}
                          onClick={() => {
                            handleKeyvalue("0");
                            if (cart.items.length <= 0) {
                              productsList.map((ele) => {
                                ele.selected = "N";
                              });
                              setProductsList(productsList);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row id="step1" justify="center" align="center" style={{ paddingBottom: "24px", width: "100%" }} gutter={[16, 16]}>
                      <Col span={12} style={{ paddingRight: "0.3%", height: "90vh" }}>
                        <div style={{ height: "100%", padding: "3% 4.5% 3% 6%", borderRadius: "8px", display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                          <p style={{ color: "#000", fontSize: "2vw", fontWeight: "600", textAlign: "center", margin: 0, paddingBottom: "3vh" }}>
                            Payment Successful
                          </p>
                          <Scrollbars autoHide className="itemScroll">
                            {cart.items.map((item, index) => {
                              return (
                                <Row style={{ paddingTop: "1.8%", paddingBottom: "1.57%", borderBottom: "1px solid rgba(0, 0, 0, 0.11)" }}>
                                  <Col span={16} style={{ paddingLeft: "1vw" }}>
                                    <p style={{ fontWeight: "600", fontSize: "1.1vw" }}>{item.name}</p>
                                    <div className="offerName1">
                                      {cart.discount > 0 ? item.discountName || "" : ""}{" "}
                                      {cart.discount > 0 ? item.discountName ? <img src={Offers} alt="" width={15} height={15} style={{ marginTop: "-2px" }} /> : "" : ""}
                                    </div>
                                  </Col>
                                  <Col span={4}>
                                    <p style={{ textAlign: "center", fontWeight: "600", fontSize: "1.1vw" }}>{item.weight}</p>
                                  </Col>
                                  <Col span={4}>
                                    <p style={{ textAlign: "right", fontWeight: "600", fontSize: "1.1vw" }}>
                                      {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                      &nbsp;{item.salePrice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                    </p>
                                  </Col>
                                </Row>
                              );
                            })}
                          </Scrollbars>

                          <Row style={{ paddingTop: "2%", borderTop: "1px solid rgba(0, 0, 0, 0.27)", paddingLeft: "1.5%" }}>
                            <Col span={12}>
                              <h4 className="payment-details">Tax</h4>
                            </Col>
                            <Col span={12}>
                              <h4 style={{ textAlign: "right" }} className="payment-details">
                                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                &nbsp;{parseFloat(cart.tax).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                              </h4>
                            </Col>
                            <Col span={12}>
                              <h4 className="payment-details">Discounts</h4>
                            </Col>
                            <Col span={12}>
                              <h4 style={{ textAlign: "right" }} className="payment-details">
                                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                &nbsp;{cart.discount} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                              </h4>
                            </Col>
                            <Col span={12}>
                              <h4 style={{ textAlign: "left" }} className="payment-details">
                                Items / Qty
                              </h4>
                            </Col>
                            <Col span={12}>
                              <h4 style={{ textAlign: "right" }} className="payment-details">
                                {cart.items.length} / {parseFloat(cart.totalQty).toFixed(2)}
                              </h4>
                            </Col>
                          </Row>
                          <Row style={{ borderTop: "1px solid rgba(0, 0, 0, 0.27)", padding: "2% 0 2% 1.5%", borderBottom: "1px solid rgba(0, 0, 0, 0.27)" }}>
                            <Col span={12}>
                              <label style={{ fontSize: "2.1vw", fontWeight: "600" }}>Total</label>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                              <label style={{ fontSize: "2.1vw", fontWeight: "600", textAlign: "right" }}>
                                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                &nbsp;{cart.total} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                              </label>
                            </Col>
                          </Row>
                          <div
                            style={{
                              paddingTop: "1%",
                              borderTop: "1px solid rgba(0, 0, 0, 0.27)",
                              paddingLeft: "1.5%",
                              borderBottom: cart.payments.length > 30 ? "1px solid rgba(0, 0, 0, 0.27)" : "",
                            }}
                          >
                            {cart.payments.map((item) => {
                              return (
                                <Row style={{ paddingTop: "0.4vw" }}>
                                  <Col span={12}>
                                    <p style={{ textAlign: "left", margin: "0", fontWeight: "600", fontSize: "1.3vw" }}>{item.name}</p>
                                    <p style={{ margin: "0", fontSize: "1vw" }}>{new Date().toLocaleDateString("zh-Hans-CN")}</p>
                                  </Col>
                                  <Col span={12}>
                                    <p style={{ textAlign: "right", fontWeight: "600" }}>
                                      {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                      &nbsp;{item.amount} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                    </p>
                                  </Col>
                                </Row>
                              );
                            })}
                          </div>
                        </div>
                      </Col>
                      <Col span={11} offset={1} style={{ paddingRight: "2%", height: "90vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff", borderRadius: "10px" }}>
                        <Row >
                          <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                            {/* <img src={Success} style={{ height: "5vw", }} /> */}
                            <p style={{ fontWeight: "600", fontSize: "1.8vw", paddingBottom: "4vh" }}>How would you like your receipt ?</p>
                            {/* <div style={{paddingLeft: "2vw" }}> */}
                            <Button id="step2" style={{ width: "95%", height: "6vh", fontSize: "1.2vw", borderColor: "#2F3856", borderRadius: "8px", fontWeight: "500", backgroundColor: "#2F3856", color: "#fff", marginBottom: "4vh" }}>
                              Print Receipt
                            </Button>
                            <Button id="step2" style={{ width: "95%", height: "6vh", fontSize: "1.2vw", borderColor: "#2F3856", borderRadius: "8px", fontWeight: "500", backgroundColor: "#2F3856", color: "#fff", marginBottom: "4vh" }}>
                              Digital Receipt
                            </Button>
                            <Button id="step2" style={{ width: "95%", height: "6vh", fontSize: "1.3vw", borderColor: "#f3f4f9", borderRadius: "8px", fontWeight: "500", backgroundColor: "#f3f4f9", color: "#000" }}>
                              New Order
                            </Button>
                            {/* </div> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                }
              </>

            )}
          </div>
        </div>
        <RetailModals {...props} />
      </div>
    </Spin>
  );
};

export default PointOfSaleRetail;
