import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Table, Input } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "./style.css";
import "./mobile.css";
import "react-simple-keyboard/build/css/index.css";
import Scan from "../../../../assets/images/scan.gif";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";
import SearchIcon from "../../../../assets/images/searchImg.svg";
import SearchImg from "../../../../assets/images/searchIcon.svg";
import ScanItem from "../../../../assets/images/scanItem.svg";
import AddBag from "../../../../assets/images/bag-outline.svg";
import WeightImg from "../../../../assets/images/outline-weight.svg";
import MoreOptionImg from "../../../../assets/images/more-option.svg";
import DeleteProduct from "../../../../assets/images/delete1.svg";
import Offers from "../../../../assets/images/offers.png";
import { PlusOutlined, MinusOutlined, CloseSquareFilled } from "@ant-design/icons";
import ProductPanel from "../ProductPanel";
import QuantityUpdate from "../QuantityUpdate";
import BarCodeComponentForRetail from "../../../../lib/BarCodeComponentForRetail";

const HomeComponent = (props) => {
  const {
    addProduct,
    cart,
    checkIsManualWeight,
    clearProductSearchResults,
    decreaseProductQty,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    openPaymentModal,
    parkBill,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    productsList,
    selectProductCategory,
    selectProductInCart,
    selectedProductInCart,
    selectedRowKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    tillData,
    clearSelectedProductInCart,
    productListCardRef,
    removeProductModal,
    ref,
    productItems,
    setProductItems,
    productArray,
    setProductArray,
    setIsProductsVisible,
    onBarcodeInput,
    layoutType,
    setLayoutType,
    setSelectedProductInCart,
    setKioskUI,
    kioskFilteredProducts,
    deleteCart,
    setProductsList,
    pickProduct,
    setIsInputFocused,
    isInputFocused,
  } = props;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const currenciesList = tillData?.tillAccess?.csBunit?.currencies;
  const customerId = tillData?.tillAccess?.csBunit.b2cCustomer.cwrCustomerId;
  const productsDisplay = "kiosk";
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userName = userData?.user;


  return (
    <div style={{ height: "100vh", display: "flex" }}>
      {!isInputFocused && <BarCodeComponentForRetail onScan={onBarcodeInput} isInputFocused={isInputFocused} />}
      <div className="customer-login">
        <img src={kisokLogo} style={{ height: "2.6vw", position: "relative", top: "2.3vh", left: "1.2vw" }} />
        <p className="user-name">Hey, {userName}</p>
      </div>
      {layoutType === 0 ? (
        <div style={{ width: "77%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <div style={{ paddingBottom: "5vh" }}>
            <img src={Scan} className="scan-cl-img" />
          </div>
          <div style={{ paddingBottom: "3vh" }}>
            <p style={{ fontSize: "2vw", fontWeight: "600", color: "#fff", textAlign: "center", margin: "0", letterSpacing: "0.05vw" }}>Please start scanning your items</p>
            <p style={{ fontSize: "1.4vw", fontWeight: "500", color: "#929098" }}>If you miss any item or not able to scan, click search for item option</p>
          </div>
        </div>
      ) : layoutType === 1 ? (
        <div style={{ width: "77%", height: "100vh", display: "flex", flexDirection: "column", padding: " 0 1.5%", paddingTop: "9.3vh", position: "relative" }}>
          <Button
            style={{
              position: "absolute",
              width: "8vw",
              top: "2.8%",
              right: "2%",
              border: "1px solid #FFF",
              borderColor: "#fff",
              backgroundColor: "transparent",
              color: "#FFF",
              height: "5vh",
              borderRadius: "5px",
            }}
          >
            Edit Cart
          </Button>
          <Table
            className="kiosk-cart-table-pos"
            style={{
              width: "100%",
              overflowY: "auto",
              border: "none",
              fontSize: "1.5vw",
              borderRadius: "7px",
              border: "1px solid rgba(146, 144, 152, 0.4)",
              height: "76vh",
              background: "#fff",
            }}
            pagination={false}
            dataSource={[...cart.items]}
            sticky
            rowClassName={(record) => (selectedRowKeys[0] === record.key ? "tableRowSelectedBlue" : "tableRowNonSelectedWhite")}
            columns={[
              {
                title: "Item Name",
                dataIndex: "name",
                key: "name",
                width: "47%",
                render: (text, record, index) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          selectProductInCart(record, "1");
                          setIsSearchProducts(false);
                          if (selectedRowKeys.length > 0 && selectedProductInCart.productId === record.productId) {
                            setIsQtyUpdate(true);
                          }
                        }}
                        className="tableRow"
                      >
                        {record.name}
                      </div>
                      <div className="offerName">
                        {cart.discount > 0 ? record.discountName || "" : ""}{" "}
                        {cart.discount > 0 ? record.discountName ? <img src={Offers} alt="" width={15} height={15} style={{ marginTop: "-2px" }} /> : "" : ""}
                      </div>
                    </>
                  );
                },
              },
              {
                title: "Qty",
                dataIndex: "weight",
                key: "weight",
                width: "11%",
                render: (text, record, index) => {
                  const wt = parseFloat(text);
                  if (Number.isInteger(wt)) {
                    return (
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {selectedProductInCart.productId === record.productId ? (
                          <MinusOutlined
                            title="Decrease Qty"
                            onClick={() => (selectedRowKeys.length > 0 && parseFloat(selectedProductInCart.weight) > 1 ? decreaseProductQty(selectedProductInCart) : "")}
                            style={{
                              marginRight: "5px",
                              padding: "0.5vh 0.5vh",
                              border: "1px solid rgba(0,0,0)",
                              fontSize: "1vh",
                              borderRadius: "2px",
                              cursor: "pointer",
                              fontWeight: "500",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <div style={{ margin: "0 3px", fontWeight: "500", fontSize: "1.1vw" }}>{wt}</div>
                        <div className="offerName">
                          {record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}
                        </div>
                        {selectedProductInCart.productId === record.productId ? (
                          <PlusOutlined
                            title="Increase Qty"
                            onClick={() => (selectedRowKeys.length > 0 ? increaseProductQty(selectedProductInCart) : "")}
                            style={{
                              marginLeft: "5px",
                              padding: "0.5vh 0.5vh",
                              border: "1px solid rgba(0,0,0)",
                              fontSize: "1vh",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <div>{parseFloat(text).toFixed(2)}</div>
                        <div className="offerName">
                          {record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}
                        </div>
                      </>
                    );
                  }
                },
                align: "center",
              },
              {
                title: "Discount",
                dataIndex: "discount",
                key: "discount",
                width: "11%",
                render: (text, record) => {
                  return (
                    <div>
                      <span style={{ fontWeight: "500", fontSize: "1.1vw" }}>{parseFloat(text).toFixed(0)}</span>
                    </div>
                  );
                },
                align: "center",
              },
              {
                title: "Tax",
                dataIndex: "taxAmount",
                key: "taxAmount",
                width: "11%",
                render: (text, record) => {
                  return (
                    <div>
                      <span style={{ fontWeight: "500", fontSize: "1.1vw" }}>{parseFloat(text).toFixed(2)}</span>
                    </div>
                  );
                },
                align: "center",
              },
              {
                title: "Price",
                dataIndex: "realPrice",
                key: "realPrice",
                width: "11%",
                render: (text, record) => {
                  return (
                    <div>
                      <span style={{ fontWeight: "500", fontSize: "1.1vw" }}>{parseFloat(text).toFixed(0)}</span>
                    </div>
                  );
                },
                align: "center",
              },
              {
                title: "Net Value",
                dataIndex: "nettotal",
                key: "nettotal",
                width: "13.5%",
                render: (text, record) => {
                  return (
                    <div style={{position:"relative"}}>
                      {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                      &nbsp;
                      <span style={{ fontWeight: "500", fontSize: "1.1vw", width: "11.5%" }}>{parseFloat(text).toFixed(0)}</span>
                      &nbsp;
                      {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                      <img
                        src={DeleteProduct}
                        onClick={() => {
                          if (selectedProductInCart.productId === record.productId) {
                            clearSelectedProductInCart();
                            removeProductModal();
                          }
                        }}
                        style={{ height: "18px", width: "18px", cursor: "pointer",position:"absolute",right:0,top:"15%"}}
                      />
                    </div>
                  );
                },
                align: "center",
              },
            ]}
            onRow={(record) => ({
              onDoubleClick: () => {
                selectProductInCart(record);
              },
            })}
          />
          <Row>
            <Col span={16}></Col>
            <Col span={8} style={{ height: "15vh" }}>
              <Row style={{ paddingTop: "3vh" }}>
                <Col span={13} offset={1}>
                  <p style={{ margin: "0", color: "#fff", fontSize: "1.2vw" }}>Tax:</p>
                </Col>
                <Col span={10}>
                  <p style={{ textAlign: "right", margin: "0", fontWeight: "600", color: "#fff", fontSize: "1.2vw" }}>{cart.tax.toFixed(2)}</p>
                </Col>
              </Row>
              <Row>
                <Col span={13} offset={1}>
                  <p style={{ margin: "0", color: "#fff", fontSize: "1.2vw" }}>Discount:</p>
                </Col>
                <Col span={10}>
                  <p style={{ textAlign: "right", margin: "0", fontWeight: "600", color: "#fff", fontSize: "1.2vw" }}>{cart.discount.toFixed(2)}</p>
                </Col>
              </Row>
              <Row>
                <Col span={13} offset={1}>
                  <p style={{ margin: "0", color: "#fff", fontSize: "1.5vw", fontWeight: "600", lineHeight: "1.2" }}>Total Amount:</p>
                </Col>
                <Col span={10}>
                  <p style={{ textAlign: "right", margin: "0", fontWeight: "600", color: "#fff", fontSize: "1.5vw", lineHeight: "1.2" }}>{cart.total.toFixed(2)}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <QuantityUpdate
            selectedProductInCart={selectedProductInCart}
            setSelectedProductInCart={setSelectedProductInCart}
            addProduct={addProduct}
            setIsQtyUpdate={setIsQtyUpdate}
            isQtyUpdate={isQtyUpdate}
            increaseProductQty={increaseProductQty}
            decreaseProductQty={decreaseProductQty}
            removeProductModal={removeProductModal}
            setIsSearchProducts={setIsSearchProducts}
          />
        </div>
      ) : layoutType === 2 ? (
        <div style={{ width: "85.3%", height: "100vh", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <div style={{ display: "flex", width: "100%", padding: "9.4vh 0 0 1.5%" }}>
            <div style={{ width: "85.4%" }}>
              <Input
                placeholder="Search for products by code"
                ref={productSearchInputRef}
                onChange={(e) => {
                  e.target.value === "" ? clearProductSearchResults() : setProductSearchInput(e.target.value);
                }}
                className="productSearch-sm"
                style={{ borderRadius: "5px", borderColor: "rgba(0, 0, 0, 0.25)", height: "5.5vh" }}
                value={productSearchInput}
                onFocus={() => {
                  setIsInputFocused(true);
                }}
                onBlur={() => {
                  setIsInputFocused(false);
                }}
                onPressEnter={getSearchedProducts}
                suffix={<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}></div>}
                prefix={<img src={SearchImg} alt="" onClick={getSearchedProducts} />}
              />
            </div>
            <div style={{ width: "15.6%", padding: "0 2% 0 1.2%" }}>
              <Input
                placeholder="Scan Item"
                // ref={productSearchInputRef}
                // onChange={(e) => {
                //   e.target.value === "" ? clearProductSearchResults() : setProductSearchInput(e.target.value);
                // }}
                className="productSearch-sm"
                style={{ borderRadius: "5px", borderColor: "rgba(0, 0, 0, 0.25)", height: "5.5vh" }}
                value={productSearchInput}
                onPressEnter={getSearchedProducts}
                suffix={
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {/* {productSearchInput.length > 0 ? <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => clearProductSearchResults("")} /> : null} */}
                  </div>
                }
                prefix={<img src={ScanItem} alt="" onClick={getSearchedProducts} style={{ height: "3.3vh" }} />}
              />
            </div>
          </div>
          <ProductPanel
            productsDisplay={productsDisplay}
            checkIsManualWeight={checkIsManualWeight}
            setIsSearchProducts={setIsSearchProducts}
            getMoreProducts={getMoreProducts}
            productsList={productsList}
            productItems={productItems}
            setProductItems={setProductItems}
            selectProductInCart={selectProductInCart}
            productArray={productArray}
            setProductArray={setProductArray}
            isProductsFilter={isProductsFilter}
            getSearchedProducts={getSearchedProducts}
            productListCardRef={productListCardRef}
            setIsProductsVisible={setIsProductsVisible}
            pickProduct={pickProduct}
          />
        </div>
      ) : null}
      {layoutType === 0 || layoutType === 1 ? (
        <div style={{ width: "23%", height: "100vh", display: "flex", flexDirection: "column" }}>
          <div
            onClick={() => {
              localStorage.setItem("layoutType", 2);
              const customEvent = new CustomEvent("customStorageChange", {
                detail: { key: "layoutType", newValue: 2 },
              });
              window.dispatchEvent(customEvent);
            }}
            style={{
              width: "93%",
              height: "16.8vh",
              backgroundColor: "#626367",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "9.3vh",
              borderRadius: "10px",
              cursor:"pointer",
            }}
          >
            <img src={SearchIcon} style={{ height: "2.4vw" }} />
            <p style={{ margin: "0", fontSize: "1.4vw", color: "#fff", paddingTop: "1.6vh" }}>Search For Item</p>
          </div>

          <div
            onClick={() => {
              // setLayoutType(2);
              localStorage.setItem("layoutType", 2);
              const customEvent = new CustomEvent("customStorageChange", {
                detail: { key: "layoutType", newValue: 2 },
              });
              window.dispatchEvent(customEvent);
            }}
            style={{
              width: "92%",
              height: "16.8vh",
              backgroundColor: "#626367",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "3vh",
              borderRadius: "10px",
              cursor:"pointer",
            }}
          >
            <img src={AddBag} style={{ height: "2.4vw" }} />
            <p style={{ margin: "0", fontSize: "1.4vw", color: "#fff", paddingTop: "1.6vh" }}>Add Bag</p>
          </div>

          <div
            style={{
              width: "92%",
              height: "16.8vh",
              backgroundColor: "#626367",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "3vh",
              borderRadius: "10px",
              cursor:"pointer",
            }}
          >
            <img src={WeightImg} style={{ height: "2.4vw" }} />
            <p style={{ margin: "0", fontSize: "1.4vw", color: "#fff", paddingTop: "1.6vh" }}>Weigh the product</p>
          </div>

          <div
            style={{
              width: "92%",
              height: "16.8vh",
              backgroundColor: "#626367",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "3vh",
              borderRadius: "10px",
              cursor:"pointer",
            }}
          >
            <img src={MoreOptionImg} style={{ height: "2.4vw" }} />
            <p style={{ margin: "0", fontSize: "1.4vw", color: "#fff", paddingTop: "1.6vh" }}>More Options</p>
          </div>

          {layoutType === 1 ? (
            <div
              className="custom-box"
              onClick={() => {
                openPaymentModal();
                setKioskUI(5);
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "kioskUI", newValue: 5 },
                });
                window.dispatchEvent(customEvent);
                localStorage.setItem("kioskUI", 5);
              }}
            >
              <p style={{ margin: "0", fontSize: "1.86vw", color: "#fff", textAlign: "center" }}>Finish & Pay</p>
            </div>
          ) : null}
        </div>
      ) : layoutType === 2 ? (
        <div style={{ width: "14.7%", height: "100vh", display: "flex", flexDirection: "column" }}>
          <div style={{ marginTop: "9.3vh", width: "89%", height: "76vh", backgroundColor: "#FFF", borderRadius: "5px" }}>
            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
            >
              Popular
            </div>

            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
              onClick={()=>kioskFilteredProducts("All")}
            >
              Organic
            </div>

            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
              onClick={()=>kioskFilteredProducts("A")}
            >
              A
            </div>

            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
              onClick={()=>kioskFilteredProducts("B-C")}
            >
              B - C
            </div>

            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
              onClick={()=>kioskFilteredProducts("D-E-F-G-H-I-J")}
            >
              D - J
            </div>

            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
              onClick={()=>kioskFilteredProducts("K-L-M")}
            >
              K - M
            </div>

            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
              onClick={()=>kioskFilteredProducts("N-O")}
            >
              N - O
            </div>

            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
              onClick={()=>kioskFilteredProducts("P")}
            >
              P
            </div>

            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
              onClick={()=>kioskFilteredProducts("Q-R-S")}
            >
              Q - S
            </div>

            <div
              style={{
                height: "7.6vh",
                width: "100%",
                borderBottom: "1px solid rgba(146, 144, 152, 0.5)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.3vw",
                fontWeight: "500",
              }}
              onClick={()=>kioskFilteredProducts("T-U-V-W-X-Y-Z")}
            >
              T - Z
            </div>
          </div>
          <div style={{ position: "absolute", bottom: "1.8%", right: "1.7%" }}>
            <Button
              onClick={() => {
                // setLayoutType(1);
                localStorage.setItem("layoutType", 1);
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "layoutType", newValue: 1 },
                });
                window.dispatchEvent(customEvent);
              }}
              className="proceed-to-pay-btn"
            >
              Proceed To Cart
            </Button>
          </div>
        </div>
      ) : null}
      <div className="left-bottom-images">
      <div className="bottom-img">
            <img
              src={backImg}
              style={{ height: "2.7vw" }}
              onClick={() => {
                
                if(layoutType === 1){
                  localStorage.setItem("layoutType", 2);
                  const customEvent = new CustomEvent("customStorageChange", {
                    detail: { key: "layoutType", newValue: 2 },
                  });
                  window.dispatchEvent(customEvent);
                }else if(layoutType === 0){
                  productsList.map((ele) => {
                    ele.selected = "N";
                  });
                  setProductsList(productsList);
                  localStorage.setItem("kioskUI", 0);
                  setKioskUI(0);
                  const customEvent = new CustomEvent("customStorageChange", {
                    detail: { key: "kioskUI", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent);
                }else {
                  localStorage.setItem("layoutType", 0);
                  const customEvent = new CustomEvent("customStorageChange", {
                    detail: { key: "layoutType", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent);
                }
              }}
            />
            <p style={{ color: "#fff", margin: "0" }}>Back</p>
          </div>
        <div className="bottom-img">
          <img src={volumeImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Volume</p>
        </div>
        <div className="bottom-img">
          <img src={wheelchairImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
        </div>
        <div className="bottom-img">
          <img src={helpImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Help</p>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
