import React, { useState, useEffect, useRef } from "react";
import { message, Modal, Form } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { innovitiPaymentCodes } from "../../../constants/config";
import { clock, timeStamp, timeValidator, currentDay, dateValidator } from "../../../utility/clock";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../lib/printer/xmlPrinter";
import { kotPrinter } from "../../../lib/printer/kotPrinter";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../lib/auth";
import { initializeCart, defaultCustomer } from './cartUtils';
import { v4 as uuidv4 } from "uuid";
import useDebounce from "../../../lib/hooks/useDebounce";
import DefaultProductImage from "../../../assets/images/default-product.webp";
import db from "../../../database";
import Axios from "axios";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import moment from "moment";
import { sendOrder } from "../../../socket";
import { SyncData } from "../Restaurant/Tables-Management/SyncData";

// OMS Orders Modal Imports //
import NewWhite from "../../../assets/images/pending.svg";
import PreparingWhite from "../../../assets/images/parkedOrder.svg";
import ReadyWhite from "../../../assets/images/completed.svg";
import CompletedWhite from "../../../assets/images/prepared.svg";
import New from "../../../assets/images/new.svg";
import Preparing from "../../../assets/images/preparing.svg";
import Ready from "../../../assets/images/ready.svg";
import Completed from "../../../assets/images/todayOrders.svg";
import _ from "lodash";
import CoreModals from "./coreModals";
import ReturnBill from "./returnBill";
import { CheckoutTotalManualDiscount } from "./PricingRules/CheckoutTotalManualDiscount";
import { CheckoutFlatDiscount } from "./PricingRules/CheckoutFlatDiscount";
import { CheckoutPercentageDiscount } from "./PricingRules/CheckoutPercentageDiscount";
import { createCustomer, getCustomer, updateCustomer } from "./customer";
import { pricingRuleController } from "./pricingRuleController";
import { barcodeScaner } from "./scaner";
import { TotalBillDiscount } from "./PricingRules/totalBillDiscount";
import { TotalBillFreeProductDiscount } from "./PricingRules/totalBillFreeProductDiscount";
import HCPrint from "../../../lib/printer/hardWareControllerPrinter";
import { paymentProcess } from "./paymentProcess";
import PrintController from "../../../lib/printer/printController";

// import BillManagement from "../Restaurant/billManagement";

// PointOfsaleCore Component Start
const PointOfsaleCore = (props) => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const edcUrl = process.env.REACT_APP_edcUrl;
  const RenderComponent = props.component;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  const ObFlag = tillData.tillAccess.cwrTill.printTemplate.obController === "Y" ? true : false;
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  // const defaultCustomer = JSON.parse(localStorage.getItem("defaultCustomer"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
  let tillDataPaymentMethods = tillData.tillAccess.csBunit.paymentMethodList;
  const tillDocumentSequence = parseFloat(localStorage.getItem("documentSequence"));
  const isPrintModeXML = tillData.tillAccess.cwrTill.hardwareController.printReceipt === "Y" ? true : false;
  const history = useHistory();
  const qs = require("querystring");
  const tillValue = JSON.parse(localStorage.getItem("tillValue"));
  // let setAuthTokens;
  const paymentModalInputRef = useRef();
  const quantityInputRef = useRef();
  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(async () => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  // KeyBord changes start
  const [orderHistoryInput, setOrderHistoryInput] = useState("");
  const [keyboardType, setKeyboardType] = useState({ product: false, parkedBill: false, salesHistoryDoc: false, salesHistoryCus: false });
  const [layout, setLayout] = useState("default");
  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState("");
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [keyboardVisible, setKeyboardVisible] = useState(true);
  const keyboardProduct = useRef(null);
  const keyboardParkbill = useRef(null);
  const orderHistorySearchInputRef = useRef(null);

  const handleKeyPress = (button) => {
    if (button === "{shift}" || button === "{caps}") setLayout("shift");
    if (button === "{default}" || button === "{small}") setLayout("default");
    if (button === "{numbers}") setLayout("numbers");
    if (button === "{number}") setLayout("number");
    if (button === "{done}") {
      switch (true) {
        case keyboardType.parkedBill:
          searchParkedBill();
          break;
        case keyboardType.salesHistoryDoc:
          searchOrderHistory();
        case keyboardType.salesHistoryCus:
          searchOrderHistory();
          break;
        case keyboardType.product:
          // getSearchedProducts();
          break;
        // Add more cases as needed
        default:
          // Handle the default case or do nothing
          break;
      }
    }
  };

  const handleKeyboardInput = (inputs) => {
    if (keyboardType.parkedBill === true) {
      setProductSearchInput("");
      setParkedBillSearchInput(inputs.default);
    } else if (keyboardType.product === true) {
      if (inputs.default === "") {
        clearProductSearchResults();
      } else {
        setProductSearchInput(inputs.default);
      }
    } else if (keyboardType.salesHistoryDoc === true) {
      setOrderHistoryInput(inputs.default);
    } else if (keyboardType.salesHistoryCus === true) {
      setOrderHistoryInput(inputs.default);
    }
  };

  // KeyBord changes End

  // ORDER TYPE BLOCK START
  const [orderType, setOrderType] = useState();
  const [posSaleTypes, setPosSaleTypes] = useState([]);
  const [displaySetOrderType, setDisplayOrderType] = useState(false);
  const changeOrderType = (type) => {
    setDisplayOrderType(false);
    setOrderType(type);
  };
  useEffect(() => {
    db.posSaletypes.toArray().then((saleType) => {
      setPosSaleTypes([...saleType]);
      const saleIndex = saleType.findIndex((st) => st.cwrSaletype.isdefault === "Y");
      setOrderType(saleType[saleIndex]);
    });
  }, []);
  // ORDER TYPE BLOCK END

  // Cash Management Start
  const [cashAddInFlag, setCashAddInFlag] = useState(false);
  const [cashManagementForm] = Form.useForm();
  const [cashIn, setCashOut] = useState(true);
  const [pettCashIn, setPettCashIn] = useState(false);
  const [addCashFlag, setAddCashFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [giftCardFlag, setGiftCardFlag] = useState(false);

  const handleCahInOut = (data) => {
    let formData = cashManagementForm.getFieldsValue(true);
    formData.key = uuidv4().replace(/-/g, "").toUpperCase();
    formData.id = uuidv4().replace(/-/g, "").toUpperCase();
    formData.date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    if (cashAddInFlag === true) {
      if (formData.type === "cashOut" || !formData.type) {
        formData.type = "cashOut";
      } else {
        formData.type = "pettyCashOut";
      }
    } else {
      if (formData.type === "cashIn" || !formData.type) {
        formData.type = "cashIn";
      } else {
        formData.type = "pettyCashIn";
      }
    }
    setPettCashIn(false);
    setCashOut(true);
    db.cashInCashOut.add(formData);
    cashManagementForm.resetFields();
    setAddCashFlag(false);
  };

  const onChangeCheckbox = (e) => {
    const eventId = e.target.id;
    const checkedValue = e.target.checked;
    let formData = cashManagementForm.getFieldsValue(true);
    if (cashAddInFlag) {
      if (eventId === "cashOut") {
        if (checkedValue === true) {
          setCashOut(true);
          setPettCashIn(false);
          formData.type = "cashOut";
        }
      }
      if (eventId === "pettyCashOut") {
        if (checkedValue === true) {
          formData.type = "pettyCashOut";
          setCashOut(false);
          setPettCashIn(true);
        }
      }
    } else {
      if (eventId === "cashIn") {
        if (checkedValue === true) {
          formData.type = "cashIn";
          setCashOut(true);
          setPettCashIn(false);
        }
      }
      if (eventId === "pettyCashIn") {
        if (checkedValue === true) {
          formData.type = "pettyCashIn";
          setCashOut(false);
          setPettCashIn(true);
        }
      }
    }
    cashManagementForm.setFieldsValue(formData);
  };

  // Cash Management End

  // CUSTOMER  SEARCH AND SELECTION BLOCK START
  const [displayCustomerSearch, setDisplayCustomerSearch] = useState(false);
  const [customerSearchType, setCustomerSearchType] = useState(() => (posConfig.defaultCustomerSearch === "Search Key" ? "searchKey" : "mobile"));
  const [customerSearchInput, setCustomerSearchInput] = useState("");
  const [customerSearchResults, setCustomerSearchResults] = useState();
  const [closeCustomerFlag, setCloseCustomerFlag] = useState(false);
  const [properties, setProperties] = useState("");
  const [kioskUI, setKioskUI] = useState(parseFloat(localStorage.getItem("kioskUI")) ? parseFloat(localStorage.getItem("kioskUI")) : 0);
  const [kioskLogin] = Form.useForm();
  const [layoutType, setLayoutType] = useState(parseFloat(localStorage.getItem("layoutType")) ? parseFloat(localStorage.getItem("layoutType")) : 0);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      const dynamoDBValue = event.detail.newValue;
      if (event.detail.key === "kioskUI") {
        setKioskUI(dynamoDBValue ? dynamoDBValue : parseFloat(localStorage.getItem("kioskUI")) || 0);
      } else if (event.detail.key === "layoutType") {
        setLayoutType(dynamoDBValue ? dynamoDBValue : parseFloat(localStorage.getItem("layoutType")) || 0);
      }
    };

    window.addEventListener("customStorageChange", handleCustomEvent);

    return () => {
      window.removeEventListener("customStorageChange", handleCustomEvent);
    };
  }, []);

  const debouncedCustomerSearch = useDebounce(customerSearchInput, 350);
  useEffect(() => {
    if (debouncedCustomerSearch !== "") {
      handleCustomerSearch(debouncedCustomerSearch);
    } else {
      setCustomerSearchResults([]);
    }
  }, [debouncedCustomerSearch]);

  const closeCustomerSearch = (obj) => {
    setDisplayCustomerSearch(false);
    setCustomerSearchInput("");
    setCustomerSearchResults();
    if (paymentModalByCustomerState) {
      setTimeout(() => {
        openPaymentModalByCustomer();
      }, 500);
    }
  };

  const handleCustomerSearch = async () => {
    getCustomer(form, tillLayout, customerSearchInput, setKioskUI, setCustomerSearchResults, kioskLogin);
  };

  const selectCustomer = async (index) => {
    const cartObj = {
      ...cart,
      customer: customerSearchResults[index],
    };
    if (cartObj.items.length > 0) {
      let addToCart = cart.items[cart.items.length - 1];
      pricingRuleController(addToCart, cartObj, cart, setCart, cartRef, orderType);
    }
    localStorage.setItem("cartObj",JSON.stringify(cartObj));
    setCart({ ...cartObj });
    closeCustomerSearch(cartObj, true);
  };
  // CUSTOMER  SEARCH AND SELECTION BLOCK END

  // ADD NEW CUSTOMER BLOCK START
  const [form] = Form.useForm();
  const [displayAddNewCustomer, setDisplayAddNewCustomer] = useState(false);

  const showAddNewCustomerFields = () => {
    setDisplayCustomerSearch(false);
    let customerSearchType = "name";

    if (/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(customerSearchInput)) {
      customerSearchType = "email";
    } else if (/^\d+$/.test(customerSearchInput)) {
      customerSearchType = "mobile";
    } else {
      customerSearchType = "name";
    }
    if (customerSearchType === "mobile") {
      form.setFieldsValue({
        mobile: customerSearchInput,
        name: "",
        email: "",
        country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
        state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
      });
    } else if (customerSearchType === "name") {
      form.setFieldsValue({
        name: customerSearchInput,
        mobile: "",
        email: "",
        country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
        state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
      });
    } else if (customerSearchType === "email") {
      form.setFieldsValue({
        name: "",
        mobile: "",
        email: customerSearchInput,
        country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
        state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
      });
    }
    setDisplayAddNewCustomer(true);
  };

  const addNewCustomer = async (data) => {
    createCustomer(data, cart, setCart, form, tillLayout, closeCustomerSearch, tillData, posLogActivity, setKioskUI, setDisplayAddNewCustomer);
  };
  // ADD NEW CUSTOMER BLOCK END

  // EDIT CUSTOMER BLOCK END
  const [displayEditOldCustomer, setDisplayEditOldCustomer] = useState(false);
  const [selectedEditOldCustomer, setSelectedEditOldCustomer] = useState();

  const showEditOldCustomerFields = (customer) => {
    setDisplayCustomerSearch(false);
    setSelectedEditOldCustomer(customer);
    form.setFieldsValue({
      editName: customer.name,
      editMobile: customer.mobileNo,
      editEmail: customer.email,
      editPincode: customer.pincode,
      name: customer.name,
      mobile: customer.mobileNo,
      birthday: customer.birthday !== null ? moment(customer.birthday) : null,
      anniversaryDate: customer.anniversaryDate !== null ? moment(customer.anniversaryDate) : null,
      city: customer.customerAddress.line2,
      street: customer.customerAddress.line1,
      gender: customer.gender !== null ? customer.gender : "",
      lastName: customer.lastName,
      taxID: customer.taxId,
      pincode: customer.pincode,
      country: customer.customerAddress.country !== null ? customer.customerAddress.country : tillData.tillAccess.csBunit.customerAddress.csCountry.name,
      state: customer.customerAddress.region !== null ? customer.customerAddress.region : tillData.tillAccess.csBunit.customerAddress.csRegion.name,
    });
    setDisplayEditOldCustomer(true);
  };

  const editOldCustomer = async (data) => {
    updateCustomer(data, cart, setCart, form, closeCustomerSearch, selectedEditOldCustomer, setSelectedEditOldCustomer, setDisplayEditOldCustomer, tillData);
  };
  // EDIT CUSTOMER BLOCK END

  //// CENTER BUTTON BLOCK START /////

  const [isQtyUpdate, setIsQtyUpdate] = useState(false);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [selectedProductInCart, setSelectedProductInCart] = useState({});
  const [selectedProductQty, setSelectProductQty] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(true);

  const selectProductInCart = (record, e) => {
    if (record.productId === selectedProductInCart.productId && e !== "1") {
      // setSelectedRowKeys([]);
      // setSelectedProductInCart({});
    } else {
      record.weight = parseFloat(record.weight.toFixed(record?.isQtyDesimal));
      setSelectedRowKeys([record.key]);
      setSelectedProductInCart(record);
    }
  };

  const enterTotalQty = async () => {
    if (cart.items.length > 0 && selectedProductInCart.weight > 0 && !selectedProductInCart.isReturn) {
      let totalQtyFlag = true;
      setSelectedProductInCart({});
      let product = {};
      const addedToCart = cart.items;
  
      // Use Promise.all to wait for all async operations to complete
      await Promise.all(addedToCart.map(async (item) => {
        if (item.value === selectedProductInCart.value && !item.bundleId) {
          product = item;
        }
        if (item.value === selectedProductInCart.value && item.bundleId) {
          const productItem = await getProductData(item.productId);
          product = productItem;
          product.weight = 1;
          product.isReturn = false;
          product.bundleId = null;
        }
      }));
      addProduct(product, selectedProductInCart.weight, totalQtyFlag);
    }
  };
  

  const onChangeTotalQuantity = async (e) => {
    if (!selectedProductInCart.isReturn && Object.keys(selectedProductInCart).length > 0) {
      setSelectedProductInCart({ ...selectedProductInCart, weight: selectedProductInCart.isDecimalQty === true ? e : e.replaceAll(".", "") });
    }
  };

  const handleTotalQty = async (value) => {
    if (Object.keys(selectedProductInCart).length > 0 && !selectedProductInCart.isReturn) {
      if (selectedProductQty === "" && value === "x") {
        setSelectedProductInCart({ ...selectedProductInCart, weight: 0 });
      } else if (value === "x") {
        setSelectedProductInCart({
          ...selectedProductInCart,
          weight: `${selectedProductInCart.weight.toString().substring(0, selectedProductInCart.weight.toString().length - 1)}`,
        });
      } else {
        if (qtyNumberFlag === 0) {
          setQtyNumberFlag(1);
          setSelectedProductInCart({ ...selectedProductInCart, weight: value });
        } else {
          setSelectedProductInCart({
            ...selectedProductInCart,
            weight: selectedProductInCart.isDecimalQty === true ? `${selectedProductInCart.weight}${value}` : `${selectedProductInCart.weight}${value}`.replaceAll(".", ""),
          });
        }
      }
    }
  };

  const selectProduct = (record) => {
    setSelectedKeys([record.key]);
    // setSelectedProduct(record);
  };

  const selectSalseProduct = (record) => {
    setSelectedKeys([record.sOrderID]);
    // setSelectedProduct(record);
  };

  const deleteProduct = (addToCart) => {
    clearSelectedProductInCart();
    if (!addToCart.isReturn) {
      addProduct(addToCart, -addToCart.weight);
      if (
        db.logConfiguration.toArray().then((fetched) => {
          fetched.map((item) => {
            item.deleteLines.filter((item) => item.log === "Y").length > 0;
          });
        })
      ) {
        posLogActivity(addToCart, "DLN");
      }
    }
  };

  const decreaseProductQty = (addToCart) => {
    if (!addToCart.isReturn) {
      if (!addToCart.isManualQty) {
        addProduct(addToCart, -1);
        if (
          db.logConfiguration.toArray().then((fetched) => {
            fetched.map((item) => {
              item.decreaseQty.filter((item) => item.log === "Y").length > 0;
            });
          })
        ) {
          posLogActivity(addToCart, "RQT");
        }
      }
    }
  };

  const increaseProductQty = (addToCart) => {
    if (!addToCart.isReturn) {
      if (!addToCart.isManualQty) {
          addProduct(addToCart, 1);
      }
    }
  };

  const deleteReceipt = () => {
    let cartObj = {
      items: [],
      total: 0,
      tax: 0,
      discount: 0,
      paid: 0,
      change: 0,
      totalQty: 0,
      roundOff: 0,
      payments: [],
      redemptionPoints: 0,
      accumulationPoints: 0,
      creditAmount: 0,
      sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
      customer: defaultCustomer,
      salesRepId: null,
      documentno: `${tillData.tillAccess.cwrTill.prefix}${documentSequence+2}`,
    }
    setCart(cartObj);
    localStorage.setItem("cartObj",JSON.stringify(cartObj));
    setSelectedRowKeys([]);
    setSelectedProductInCart({});
    setAmount("");
    setSelectedPaymentMethod("");
  };

  const deleteCart = (status = false) => {
    if (status === true) {
      if (
        db.logConfiguration.toArray().then((fetched) => {
          fetched.map((item) => {
            item.deleteOrder.filter((item) => item.log === "Y").length > 0;
          });
        })
      ) {
        posLogActivity(cart.items, "DOR");
      }
    }
    deleteReceipt();
    /* if (parkedList.length > 0) {
      selectParkedBill(parkedList[0]);
    } */
  };

  // ORDER HISTORY BLOCK START
  const [displayOrderHistory, setDisplayOrderHistory] = useState(false);
  const [orderHistoryDetails, setOrderHistoryDetails] = useState([]);
  const [ordersCopy, setOrdersCopy] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const [salesHistoryType, setSalesHistoryType] = useState([]);
  const [startRowData, setStartRowData] = useState({ startRow: "0", endRow: "10" });

  const [orderHistorySearchType, setOrderHistorySearchType] = useState("orderDocumentNo");
  const changeOrderHistorySearchType = (value, e) => {
    setOrderHistorySearchType(value);
    setSelectDate(e);
  };

  const showOrderHistory = () => {
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        setOrdersCopy([...data]);
        setOrderHistoryDetails([...data]);
        setDisplayOrderHistory(true);
      });
  };

  const [selectedOrderHistoryLine, setSelectedOrderHistoryLine] = useState("");
  const showOrderHistoryLine = (orderID) => {
    if (selectedOrderHistoryLine === orderID) {
      setSelectedOrderHistoryLine("");
    } else {
      setSelectedOrderHistoryLine(orderID);
    }
  };

  // PARKED BILL BLOCK START

  const searchOrderHistory = async (date, dateValue, row) => {
    let setAuthTokens;
    let data;
    let dateFilter = null;
    let startDate
    let endDate
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    // Search by customer key
    const searchByCustomerKey = async (customerKey) => {
      return db.orders?.where("documentno").equalsIgnoreCase(dateValue?.target?.value).or("customerSearchKey").equalsIgnoreCase(dateValue?.target?.value).limit(20).toArray();
    };

    // Search by date
    const searchByDate = async () => {
      return db.orders.where("orderTime").equalsIgnoreCase(moment(dateValue[0]).format("YYYY-MM-DD")).limit(20).toArray();
    };
    if (date === "orderDateSearchKey") {
      localStorage.setItem("orderType", JSON.stringify([date, dateValue]));
      dateFilter = JSON.stringify(JSON.stringify({ startDate: moment(dateValue[0]).format("YYYY-MM-DD"), endDate: moment(dateValue[1]).format("YYYY-MM-DD") }));
      startDate = moment(dateValue[0]).format("YYYY-MM-DD");
      endDate = moment(dateValue[1]).format("YYYY-MM-DD");
      data = await searchByDate();
    } else {
      // Assuming date is empty when searching by customer
      const customerKey = dateValue?.target?.value;
      localStorage.setItem("orderType", JSON.stringify([date, dateValue?.target?.value]));
      data = await searchByCustomerKey(customerKey);
    }

    const sortOptions = {
      documentno: "desc",
      dateordered: "desc",
      customer: null,
      totalAmount: null
    };
    
    const filteredSortOptions = Object.fromEntries(
      Object.entries(sortOptions).filter(([key, value]) => value !== null)
    );
    
    const sortData = JSON.stringify(filteredSortOptions).replace(/"/g, '\\"');
    const dateFiltered =
    date === "customer"
      ? null
      : `"{\\"DateRange\\":[\\"${startDate}\\",\\"${endDate}\\"]}"`;

      if (data.length > 0) {
        setOrdersCopy([...data]);
        setOrderHistoryDetails([...data]);
      } else {
        let orderHistoryData = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query {
              salesHistory(
                q: null,
                filter_by: ${dateFiltered},
                startRow: "${orderHistoryDetails.length}",
                endRow: "100",
                sort_by: "${sortData}"
              )          {
              sOrderID
              created
              createdby
              updated
              updatedby
              documentno
              dateordered
              cwrProductQty
              orderTime
              taxamt
              grosstotal
              discAmount
              csUser {
                user
              }
              csBUnit {
                csBunitId
                name
              }
              csbUnitLocation {
                fulladdress
              }
              cwrB2cCustomer {
                cwrCustomerId
                code
                name
                mobileNo
                pincode
                email
                retlLoyaltyBalance
                sCustomer {
                  sCustomerID
                  customerCategory {
                    sCustomerCateforyId
                    value
                    name
                    description
                  }
                }
              }
              saleType {
                cwrSaletypeId
                name
                value
              }
              cwrTill {
                cwrTillID
                till
              }
              tablename
              fboRder {
                guests
              }
              saLesRep {
                waitername
              }
              finReceiptPlan {
                finReceiptPlanDetails {
                  amount
                  cwrPaymentmethod {
                    cWRPaymentMethodID
                    finFinancialAccountId
                    finPaymentmethodId
                    integratedPayment
                    isloyalty
                    paymentProvider
                  }
                }
              }
              line {
                sOrderlineID
                sOrderId
                line
                description
                qty
                netlist
                netunit
                created
                linetax
                unittax
                linenet
                linegross
                grosslist
                grossstd
                returnline
                returnQty
                discount
                product {
                  mProductId
                  name
                  value
                  upc
                  hsncode
                  imageurl
                  isManualQty
                  shortDescription
                  returnable
                  returnDays
                }
                uom {
                  csUomId
                  name
                }
                tax {
                  csTaxID
                  name
                  rate
                }
                pricingRule {
                  mPricingrulesId
                  name
                }
              }
            }
          }
          `,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        });
  
        let totalQty = 0;
        let arrayData = [];
        orderHistoryData.data.data.salesHistory.map((item) => {
          let paymentMethods = [];
          let change = 0;
          item.line.map((pro) => {
            pro.name = pro.product.name;
            pro.weight = pro.qty;
            pro.salePrice = pro.linegross;
            pro.nettotal = pro.netlist;
          });
          item?.finReceiptPlan[0]?.finReceiptPlanDetails.forEach((ele) => {
            let payment_method = tillDataPaymentMethods.find((pi) => pi.finPaymentmethodId === ele.cwrPaymentmethod.finPaymentmethodId);
            if (payment_method && parseInt(ele.amount) > 0) {
              payment_method.amount = ele.amount;
              paymentMethods.push(payment_method);
            } else if (parseInt(ele.amount) < 0) {
              change = parseInt(ele.amount);
            }
          });
  
          const obj = {
            change,
            items: item.line,
            originalPrice: item.grosstotal,
            customer: item.cwrB2cCustomer,
            documentno: item.documentno,
            total: item.grosstotal.toFixed(2),
            discount: item.discAmount,
            tax: item.taxamt,
            orderTime: item.orderTime,
            orderDate: item.dateordered,
            totalQty: item.cwrProductQty,
            payments: paymentMethods,
            roundOff: 0,
            key: item.documentno,
            salesHistory: "Y",
            status: "Success",
            isSynced: 0,
            creditAmount: 0,
            createdBy: "",
          };
          if (obj.items.length > 0) {
            arrayData.push(obj);
          }
          return obj;
        });
        // setStartRowData()
        setOrdersCopy([...arrayData]);
        setOrderHistoryDetails([...arrayData]);
        // Promise.all(promises)
        //   .then((resolvedArrayData) => {
        //     arrayData = resolvedArrayData;
        //     setOrderHistoryDetails([...arrayData]);
        //   })
        //   .catch((error) => {
        //     console.error("Error processing data:", error);
        //   });
      }
    setOrderHistoryInput("");
  };

  const storedParkedList = JSON.parse(localStorage.getItem("parkedList"));
  const initialParkedList = storedParkedList ? storedParkedList : [];
  const [displayParkedBillModal, setDisplayParkedBillModal] = useState(false);
  const [parkedList, setParkedList] = useState(initialParkedList);
  const [filterdParkedList, setFilterdParkedList] = useState(initialParkedList);

  useEffect(() => {
    setFilterdParkedList(parkedList);
  }, [parkedList]);

  const discardParkedBill = async(record) => {
    let array = [];
    filterdParkedList.map((item) => {
      if (item.key !== record.key) {
        array.push(item);
      }
    });
    localStorage.setItem("parkedList", JSON.stringify(array));
    setParkedList([...array]);
    setFilterdParkedList(array);
    const tillSession = JSON.parse(localStorage.getItem("tillSession"));
    const tillSessionId = tillSession.tillSessionId;
    let cartToDb = record.parkedCart;
    cartToDb.orderTime = timeStamp();
    cartToDb.createdBy = tillData.tillAccess.csUserId;
    cartToDb.orderType = orderType.cwrSaletype.cwrSaletypeId;
    cartToDb.orderDate = moment(new Date()).format("YYYY-MM-DD");
    cartToDb.tillSessionId = tillSessionId;
    cartToDb.key = uuidv4().replace(/-/g, "").toUpperCase();
    cartToDb.isSynced = 0;
    cartToDb.syncAttempts = 0;
    cartToDb.customerSearchKey = cart.customer.code;
    await db.orders
    .add(cartToDb)
  };

  const openDisplayParkedBillModal = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            You can retrieve the bill later by selecting the 'Retrieve' option in Parked Bills.
            <br />
            Do you want to continue parking the bill?
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          parkBill();
          // message.info("Please wait...");
          setTimeout(() => {
            setDisplayParkedBillModal(true);
          }, 2000);
        },
        onCancel() {
          setDisplayParkedBillModal(true);
        },
      });
    } else {
      setDisplayParkedBillModal(true);
    }
  };

  const parkedListRef = useRef(initialParkedList);
  useEffect(() => {
    parkedListRef.current = parkedList;
  }, [parkedList]);

  const parkBill = (value) => {
    const presentParkedList = value === "parkKey" ? parkedListRef.current : parkedList;
    const presentCart = cart;
    const presentTimeStamp = timeStamp();

    const parkedBill = {
      parkedCart: presentCart,
      parkedTime: presentTimeStamp,
      parkedBillId: uuidv4().replace(/-/g, "").toUpperCase(),
    };
    presentParkedList.push(parkedBill);
    productsList.map((ele) => {
      ele.selected = "N";
    });
    setProductsList(productsList);
    localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
    setParkedList([...presentParkedList]);
    deleteReceipt();
    message.success("Bill Parked Successfully");
  };

  const selectParkedBill = (item, fieldName) => {
    if (fieldName === "management") {
      const listItemIndex = parkedList.findIndex((bill) => bill.parkedBillId === item.parkedBillId);
      const selectedParkedBill = parkedList[listItemIndex].parkedCart;
      const presentParkedList = parkedList;
      presentParkedList.splice(listItemIndex, 1);
      localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
      setParkedList([...presentParkedList]);
      setCart(selectedParkedBill);
      localStorage.setItem("cartObj",JSON.stringify(selectedParkedBill));
      setDisplayParkedBillModal(false);
      setManagementScreenShow(false);
    } else {
      const listItemIndex = parkedList.findIndex((bill) => bill.parkedBillId === item.parkedBillId);
      const selectedParkedBill = parkedList[listItemIndex].parkedCart;
      const presentParkedList = parkedList;
      presentParkedList.splice(listItemIndex, 1);
      localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
      setParkedList([...presentParkedList]);
      setCart(selectedParkedBill);
      localStorage.setItem("cartObj",JSON.stringify(selectedParkedBill));
      setDisplayParkedBillModal(false);
    }
  };

  const [parkedBillSearchInput, setParkedBillSearchInput] = useState("");
  const [salesHistoryCustomerSearchInput, setSalesHistoryCustomerSearchInput] = useState("");
  const [salesHistoryDocumentNoSearchInput, setSalesHistoryDocumentNoSearchInput] = useState("");

  const handleParkedBillSearchInput = (e) => {
    setParkedBillSearchInput(e.target.value);
    searchParkedBill(e.target.value);
  };

  const searchParkedBill = (key) => {
    let tempArray = [];
    let tempKey = typeof key === "string" ? key : "";
    parkedList.map((list) => {
      let listKey = list.parkedCart.customer.name.toLowerCase();

      if (listKey.includes(tempKey?.toLowerCase())) {
        tempArray.push(list);
      }
    });

    if (tempArray.length > 0) {
      setFilterdParkedList([...tempArray]);
    } else {
      setFilterdParkedList(parkedList);
    }
  };

  const closeParkedBillModal = () => {
    setDisplayParkedBillModal(false);
    setParkedBillSearchInput("");
    setFilterdParkedList([...parkedList]);
  };

  // PARKED BILL BLOCK END

  //// CENTER BUTTON BLOCK END ////

  //// CART OPERATIONS START ////

  // DEFAULT CART START
  const [cart, setCart] = useState({
    items: [],
    total: 0,
    tax: 0,
    discount: 0,
    paid: 0,
    change: 0,
    totalQty: 0,
    roundOff: 0,
    payments: [],
    redemptionPoints: 0,
    accumulationPoints: 0,
    creditAmount: 0,
    sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
    customer: defaultCustomer,
    salesRepId: null,
    documentno: `${tillData.tillAccess.cwrTill.prefix}${tillDocumentSequence + 1}`,
  });
  // DEFAULT CART END

  // BARCODE READER BLOCK START
  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);

  const padleft = (nr, n, str) => {
    return Array(n - String(nr).length + 1).join(str || "0") + nr;
  };

  const decTobin = (dec) => {
    return (dec >>> 0).toString(2);
  };

  const onBarcodeInput = (data, flag) => {
    let chackBarcodeFlag = false;
    barcodeScaner(
      data,
      tillData,
      tillLayout,
      addDefinedProduct,
      setBatchSetAvailable,
      setDisplayBatchSelection,
      setLayoutType,
      setIsProductsVisible,
      setProductsList,
      chackBarcodeFlag,
      setSelectedProductInCart,
      setProductSearchInput,
      cart
    );
  };

  const addDefinedProduct = (productObjs, upc, batchno, mBatchId, price, checkWeight, modifiedQty, batchedItem) => {
    const productObj = { ...productObjs };
    if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
      // prettier-ignore
      const originalPrice = price - (price - (price * (100 / (100 + productObj.taxRate))));
      const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
      price = taxedPrice;
      productObj.cTaxId = productObj.contraTaxId;
      productObj.taxRate = productObj.contraRate;
    }

    const productDefined = {
      batchno: batchno,
      description: productObj.description,
      discount: 0,
      discountName: "",
      imageurl: productObj.imageurl,
      isDecimal: productObj.isDecimal,
      isManualQty: productObj.isManualQty,
      isPromoApplicable: productObj.isPromoApplicable,
      isReturn: false,
      mBatchId: mBatchId,
      mPricingruleId: null,
      name: productObj.name,
      name2: productObj.name2,
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj.mProductId,
      realPrice: price,
      returnQty: null,
      salePrice: price,
      stock: productObj.onhandQty,
      tax: productObj.cTaxId,
      taxAmount: 0,
      taxRate: productObj.taxRate,
      uom: productObj.csUomId,
      uom_name: productObj.uomName,
      isDecimalQty: productObj.uomData[0]?.decimal === "Y" ? true : false,
      isQtyDesimal: productObj.uomData[0]?.stdprecision ? productObj.uomData[0]?.stdprecision : 2,
      upc: upc,
      value: productObj.value,
      weight: 0,
      order: "N",
      productionCenter: productObj.productionCenter,
      shortDescription: productObj.shortDescription,
      hsncode: productObj.hsncode,
      csBunitId: productObj.csBunitId,
      mProductCategoryId: productObj.mProductCategoryId,
      productManufacturerId: productObj.productManufacturerId,
      productBrandId: productObj.productBrandId,
      productCategoryName: productObj?.productCategoryName || "",
      productAddons: productObj?.productAddons || [],
      batchedProduct: productObj.batchedProduct,
      batchedForSale: productObj.batchedForSale,
      batchedForStock: productObj.batchedForStock,
      multiPrice: productObj.multiPrice,
      shelfLife: productObj.shelfLife,
    };
    setSelectedProductInCart(productDefined);
    if (checkWeight === true) {
      let index = cart.items.findIndex((item) => item.value === productDefined.value);
      if (batchedItem === true) {
        modifiedQty = cart.items > 0 ? cart.items[index].weight + 1 : 1;
      }
      addProduct(productDefined, modifiedQty);
    } else {
      checkIsManualWeight(productDefined);
    }
    setFilterDrawer(false);
    setIsProductsVisible(false);
    setQtyNumberFlag(0);
  };

  const selectProductToCart = (data) => {
    checkIsManualWeight(data);
    setDisplayBatchSelection(false);
  };
  // BARCODE READER BLOCK END

  // PRODUCT WEIGHT MODAL START
  const [displayManualQtyWeightInput, setDisplayManualQtyWeightInput] = useState(false);
  const [productWeightModalInput, setProductWeightModalInput] = useState("");
  const [currentWeightSelectedProduct, setCurrentWeightSelectedProduct] = useState({});

  const onProductModalChangeWeight = (event) => {
    setProductWeightModalInput(event.target.value);
  };

  const handleWeightManual = (value) => {
    if (productWeightModalInput === "" && value === "x") {
      setProductWeightModalInput("");
    } else if (value === "x") {
      setProductWeightModalInput(`${productWeightModalInput.toString().substring(0, productWeightModalInput.toString().length - 1)}`);
    } else {
      setProductWeightModalInput(`${productWeightModalInput}${value}`);
    }
  };

  const pickProduct = (obj) => {
    if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
      if (obj.mBatch.length === 1) {
        addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
      } else {
        const productSet = [];
        const localObj = obj;
        for (let i = 0; i < obj.mBatch.length; i += 1) {
          const batchItem = { ...localObj.mBatch[i] };
          const obj = { ...localObj };
          if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
            // prettier-ignore
            const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
            const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
            batchItem.price = taxedPrice;
            obj.cTaxId = obj.contraTaxId;
            obj.taxRate = obj.contraRate;
          }
          const productDefined = {
            batchno: batchItem.batchno,
            description: obj.description,
            discount: 0,
            discountName: "",
            imageurl: obj.imageurl,
            isDecimal: obj.isDecimal,
            isManualQty: obj.isManualQty,
            isPromoApplicable: obj.isPromoApplicable,
            isReturn: false,
            mBatchId: batchItem.mBatchId,
            mPricingruleId: null,
            name: obj.name,
            name2: obj.name2,
            nettotal: 0,
            primaryOrderLine: null,
            productId: obj.mProductId,
            realPrice: batchItem.price,
            returnQty: null,
            salePrice: batchItem.price,
            mrpPrice: batchItem.listPrice,
            stock: obj.onhandQty,
            tax: obj.cTaxId,
            taxAmount: 0,
            taxRate: obj.taxRate,
            uom: obj.csUomId,
            uom_name: obj.uomName,
            isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
            isQtyDesimal: obj.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
            upc: batchItem.upc,
            value: obj.value,
            weight: 0,
            order: "N",
            shortDescription: obj.shortDescription,
            hsncode: obj.hsncode,
            csBunitId: obj.csBunitId,
            mProductCategoryId: obj.mProductCategoryId,
            productManufacturerId: obj.productManufacturerId,
            productBrandId: obj.productBrandId,
            batchedProduct: obj.batchedProduct,
            batchedForSale: obj.batchedForSale,
            batchedForStock: obj.batchedForStock,
            multiPrice: obj.multiPrice,
            shelfLife: obj.shelfLife,
          };
          productSet.push(productDefined);
        }
        setBatchSetAvailable([...productSet]);
        setDisplayBatchSelection(true);
      }
    } else {
      let array = [...productsList];
      array.map((item) => {
        if (obj.mProductId === item.mProductId) {
          item.selected = "Y";
        } else {
          item.selected = "N";
        }
      });
      addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
    }
  };

  const checkIsManualWeight = (prod) => {
    if (prod.isManualQty && posConfig.showWeightPopup === "Y") {
      setDisplayManualQtyWeightInput(true);
      setCurrentWeightSelectedProduct(prod);
    } else {
      addProduct(prod, 1);
    }
  };

  const addManualWeightToProduct = () => {
    let inputValidator = new RegExp(/^[0-9]*$/);
    if (currentWeightSelectedProduct.isDecimal) inputValidator = new RegExp(/^[0-9]\d*(\.\d+)?$/);
    if (inputValidator.test(productWeightModalInput) && parseFloat(productWeightModalInput) > 0) {
      setDisplayManualQtyWeightInput(false);
      addProduct(currentWeightSelectedProduct, productWeightModalInput);
      setTimeout(() => {
        setProductWeightModalInput("");
        setCurrentWeightSelectedProduct({});
      }, 500);
    } else {
      console.warn("Invalid weight input: ", productWeightModalInput);
      message.warning("Invalid input value");
    }
  };

  // PRODUCT WEIGHT MODAL END

  // PRODUCT FILTER START
  const [isProductsFilter, setIsProductsFilter] = useState(() => (posConfig.defaultSearchScreen === "Category Search" ? true : false));
  const [productCategories, setProductCategories] = useState([]);
  const [allProductCategories, setAllProductCategories] = useState([]);
  const [selectedProductBrand, setSelectedProductBrand] = useState([]);
  const [productBrands, setProductBrands] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState("allProducts");
  const [selectCategotyList, setSelectCategotyList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const prevProductsListRef = useRef(productsList);
  const [productsCopy, setProductsCopy] = useState([]);

  useEffect(() => {
    db.productCategories.toArray().then((res) => {
      res.map((item) => {
        item.label = item.value;
        item.value = item.mProductCategoryId;
      });
      setProductCategories([...res]);
    });

    db.productBrands.toArray().then((res) => {
      res.map((item) => {
        item.label = item.name;
        item.value = item.brandId;
      });
      setProductBrands([...res]);
    });

    db.AllProductCategories.toArray().then((res) => {
      setAllProductCategories(res);
    });
  }, []);

  useEffect(() => {
    getCategoryProducts(selectedProductCategory);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectProductCategory = (category) => {
    if (selectedProductCategory !== category) {
      setSelectedProductCategory(category);
      setProductsList([]);
    }
  };

  const handleBrandCheckboxChange = (brandId) => {
    const isSelected = selectedProductBrand.includes(brandId);
    if (isSelected) {
      // Brand is already selected, remove it from the selectedProductBrand array
      const updatedSelectedBrands = selectedProductBrand?.filter((id) => id !== brandId);
      setSelectedProductBrand(updatedSelectedBrands);
      // If there are still selected brands, filter productsList accordingly
      if (updatedSelectedBrands.length > 0) {
        const filteredItems = productsList.filter((item) => updatedSelectedBrands.includes(item.brandId));
        setProductsList(filteredItems.slice(0, 100));
      } else {
        if (selectCategotyList.length > 0) {
          // If categories are selected, filter productsList by both brand and category
          const filteredItems = productsCopy.filter((item) => selectCategotyList.includes(item.mProductCategoryId));
          setProductsList(filteredItems.slice(0, 100));
        } else {
          // If no brands or categories are selected, show all products from the original productsList
          setProductsList(productsCopy.slice(0, 100));
        }
      }
    } else {
      // Brand is not selected, add it to the selectedProductBrand array
      const updatedSelectedBrands = [...selectedProductBrand, brandId];
      setSelectedProductBrand(updatedSelectedBrands);
      const filteredItems = productsCopy.filter((item) => updatedSelectedBrands.includes(item.brandId));
      if(selectCategotyList.length>0){
        const finalFilteredItems = filteredItems.filter((item)=>selectCategotyList.includes(item.mProductCategoryId))
        setProductsList(finalFilteredItems.slice(0, 100));
      }else{
        setProductsList(filteredItems.slice(0, 100));
      }
    }
  };

  const handleCategoryCheckboxChange = (checkedValues) => {
    setSelectCategotyList(checkedValues);
    // If no categories are selected, check if any brands are selected
    if (checkedValues.length === 0) {
      if (selectedProductBrand.length > 0) {
        // If brands are selected, filter productsCopy by both brand and category
        const filteredItems = productsCopy.filter((item) => selectedProductBrand.includes(item.brandId));
        setProductsList(filteredItems);
      } else {
        // If no categories or brands are selected, show all products from the original productsCopy
        setProductsList([...productsCopy.slice(0, 100)]);
      }
      return;
    }

    // Filter productsCopy based on the selected categories
    // const filteredItems = productsCopy.filter((item) => checkedValues.includes(item?.mProductCategoryId));
    // If brands are selected, further filter productsCopy by brand
    if (selectedProductBrand.length > 0) {
      const filteredItems = productsCopy.filter((item) => selectedProductBrand.includes(item.brandId));
      const finalFilteredItems = filteredItems.filter((item) => checkedValues.includes(item?.mProductCategoryId));
      setProductsList(finalFilteredItems);
    } else {
      const filteredItems = productsCopy.filter((item) => checkedValues.includes(item.mProductCategoryId));
      // If no brands are selected, set the productsList with filtered categories
      setProductsList(filteredItems);
    }
  };

  const handleSelectProduct = () => {
    prevProductsListRef.current = productsList;
    const lowerCaseSearchInput = productSearchInput.toLowerCase();
    const filteredProducts = productsCopy.filter(
      (product) =>
        product?.name?.toLowerCase().includes(lowerCaseSearchInput) ||
        product?.batchIndex === lowerCaseSearchInput ||
        product?.upcIndex === lowerCaseSearchInput ||
        product?.value === lowerCaseSearchInput ||
        product?.upc === lowerCaseSearchInput
    );
    if (filteredProducts.length === 0) {
      message.info("No product found!");
    } else {
      setProductsList(filteredProducts);
    }
  };

  useEffect(() => {
    if (selectedProductCategory !== "all") {
      getCategoryProducts();
    }
  }, [selectedProductCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMoreProducts = () => {
    if (productSearchInput === "") {
      getCategoryProducts(selectedProductCategory);
    }
  };

  const getCategoryProducts = () => {
    if (selectedProductCategory === "allProducts") {
      db.products.toArray().then((productsFetched) => {
        const additionalProductsFetched = productsList.concat(productsFetched);
        additionalProductsFetched.map((ele) => {
          cart.items.map((item) => {
            if (ele.mProductId === item.productId) {
              ele.selected = "Y";
            }
          });
        });
        setProductsList([...additionalProductsFetched.slice(0, 100)]);
        setProductsCopy([...additionalProductsFetched]);
      });
    } else {
      db.products
        .where("mProductCategoryId")
        .equalsIgnoreCase(selectedProductCategory)
        .offset(productsList.length)
        .limit(100)
        .toArray()
        .then((productsFetched) => {
          const additionalProductsFetched = productsList.concat(productsFetched);
          setProductsList([...additionalProductsFetched]);
        });
    }
  };
  // PRODUCT FILTER END

  // PRODUCT SEARCH START
  const [isSearchProducts, setIsSearchProducts] = useState(() =>
    posConfig.defaultSearchScreen === "Product Search" || posConfig.defaultSearchScreen === "Category Search" || posConfig.defaultSearchScreen === undefined ? true : false
  );
  const [isProductsVisible, setIsProductsVisible] = useState(false);
  const [productSearchInput, setProductSearchInput] = useState("");
  const [numb, setNumb] = useState(0);
  const [qtyNumberFlag, setQtyNumberFlag] = useState(0);

  /* useEffect(() => {
    if (productSearchInput !== "" && productSearchInput.length >= 3) {
      getSearchedProducts();
    }
  }, [productSearchInput]); */ // eslint-disable-line

  const getProductData = (data) => {
    return new Promise(function (resolve) {
      db.products
        .where("mProductId")
        .equalsIgnoreCase(data)
        .toArray()
        .then((product) => {
          if (product.length > 0) {
            const obj = { ...product[0] };
            if (obj.overRideTax === "Y" && obj.sunitprice <= obj.overRideCondition) {
              // prettier-ignore
              const originalPrice = obj.sunitprice - (obj.sunitprice - (obj.sunitprice * (100 / (100 + obj.taxRate))));
              const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
              obj.sunitprice = taxedPrice;
              obj.cTaxId = obj.contraTaxId;
              obj.taxRate = obj.contraRate;
            }
            const productDefined = {
              batchno: null,
              description: obj.description,
              discount: 0,
              discountName: "",
              imageurl: obj.imageurl,
              isDecimal: obj.isDecimal,
              isManualQty: obj.isManualQty,
              isPromoApplicable: false,
              isReturn: true,
              mBatchId: null,
              mPricingruleId: null,
              name: obj.name,
              name2: obj.name2,
              nettotal: 0,
              primaryOrderLine: null,
              productId: obj.mProductId,
              realPrice: obj.sunitprice,
              returnQty: null,
              salePrice: obj.sunitprice,
              mrpPrice: obj.sunitprice,
              stock: obj.onhandQty,
              tax: obj.cTaxId,
              taxAmount: 0,
              taxRate: obj.taxRate,
              uom: obj.csUomId,
              uom_name: obj.uomName,
              isDecimalQty: obj.uomData?.length > 0 ? (obj.uomData[0].decimal === "Y" ? true : false) : false,
              isQtyDesimal: obj.uomData?.length > 0 ? obj.uomData[0].stdprecision : 2,
              upc: obj.upc,
              value: obj.value,
              weight: 0,
              shortDescription: obj.shortDescription,
              hsncode: obj.hsncode,
              csBunitId: obj.csBunitId,
              mProductCategoryId: obj.mProductCategoryId,
              productManufacturerId: obj.productManufacturerId,
              productBrandId: obj.productBrandId,
              batchedProduct: obj.batchedProduct,
              batchedForSale: obj.batchedForSale,
              batchedForStock: obj.batchedForStock,
              multiPrice: obj.multiPrice,
              shelfLife: obj.shelfLife,
            };
            resolve(productDefined);
          } else {
            message.warning("Product Not Found !");
            resolve(null);
          }
        });
    });
  };

  const getSearchedProducts = () => {
    db.products
      .filter(
        (product) =>
          (typeof product.name === "string" && product.name.toLowerCase().includes(productSearchInput.toLowerCase())) ||
          (typeof product.value === "string" && product.value.toLowerCase() === productSearchInput.toLowerCase())
      )
      .limit(100)
      .toArray()
      .then((productsFetched) => {
        if (productsFetched.length > 1) {
          setIsProductsVisible(true);
          setProductsList([...productsFetched]);
        } else if (productsFetched.length === 1) {
          const obj = productsFetched[0];
          if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
            const batchProductIndex = obj.mBatch.findIndex((bp) => bp.batchno.toLowerCase() === productSearchInput.toLowerCase());
            if (batchProductIndex >= 0 && obj.multiPrice === "N") {
              addDefinedProduct(
                obj,
                obj.mBatch[batchProductIndex].upc,
                obj.mBatch[batchProductIndex].batchno,
                obj.mBatch[batchProductIndex].mBatchId,
                obj.mBatch[batchProductIndex].price
              );
              setNumb(0);
              paymentModalInputRef.current.focus();
            } else if (obj.mBatch.length === 1) {
              addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
              paymentModalInputRef.current.focus();
              setNumb(0);
            } else {
              // let upcKeys = 0;
              const productSet = [];
              const localObj = obj;
              for (let i = 0; i < obj.mBatch.length; i += 1) {
                const batchItem = { ...localObj.mBatch[i] };
                const obj = { ...localObj };
                if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
                  // prettier-ignore
                  const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
                  const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
                  batchItem.price = taxedPrice;
                  obj.cTaxId = obj.contraTaxId;
                  obj.taxRate = obj.contraRate;
                }
                const productDefined = {
                  batchno: batchItem.batchno,
                  description: obj.description,
                  discount: 0,
                  discountName: "",
                  imageurl: obj.imageurl,
                  isDecimal: obj.isDecimal,
                  isManualQty: obj.isManualQty,
                  isPromoApplicable: obj.isPromoApplicable,
                  isReturn: false,
                  mBatchId: batchItem.mBatchId,
                  mPricingruleId: null,
                  name: obj.name,
                  name2: obj.name2,
                  nettotal: 0,
                  primaryOrderLine: null,
                  productId: obj.mProductId,
                  realPrice: obj.cTaxId,
                  returnQty: null,
                  salePrice: obj.cTaxId,
                  mrpPrice: batchItem.listPrice,
                  stock: obj.onhandQty,
                  tax: obj.cTaxId,
                  taxAmount: 0,
                  taxRate: obj.taxRate,
                  uom: obj.csUomId,
                  uom_name: obj.uomName,
                  isDecimalQty: obj?.uomData[0]?.decimal === "Y" ? true : false,
                  isQtyDesimal: obj?.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
                  upc: batchItem.upc,
                  value: obj.value,
                  weight: 0,
                  shortDescription: obj.shortDescription,
                  hsncode: obj.hsncode,
                  csBunitId: obj.csBunitId,
                  mProductCategoryId: obj.mProductCategoryId,
                  productManufacturerId: obj.productManufacturerId,
                  productBrandId: obj.productBrandId,
                  batchedProduct: obj.batchedProduct,
                  batchedForSale: obj.batchedForSale,
                  batchedForStock: obj.batchedForStock,
                  multiPrice: obj.multiPrice,
                  shelfLife: obj.shelfLife,
                };
                // if (batchItem.upc.toLowerCase() === data.toLowerCase()) upcKeys += 1;
                productSet.push(productDefined);
              }
              setBatchSetAvailable([...productSet]);
              setDisplayBatchSelection(true);
            }
          } else {
            addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
            paymentModalInputRef?.current?.focus();
            setNumb(0);
          }
        } else {
          message.warning("Product Not Found !");
        }
      });
  };

  const kioskFilteredProducts = (filter) => {
    db.products
      .limit(100)
      .toArray()
      .then((productsFetched) => {
        if (productsFetched.length > 1) {
          let filteredData = [];
          productsFetched.forEach((item) => {
            const itemName = item.name[0].toUpperCase();

            if (filter.includes("-")) {
              const filters = filter.split("-");
              const start = filters[0];
              const end = filters[filters.length - 1];

              if (itemName >= start && itemName <= end) {
                filteredData.push(item);
              }
            } else if (itemName === filter) {
              filteredData.push(item);
            }
          });

          setIsProductsVisible(true);

          if (filter === "All") {
            setProductsList([...productsFetched]);
          } else {
            setProductsList([...filteredData]);
          }
        }
      });
  };

  const getSearchedItem = () => {
    db.products
      .where("name")
      .startsWithIgnoreCase(productSearchInput)
      .or("batchIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("upcIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("value")
      .equalsIgnoreCase(productSearchInput)
      .limit(100)
      .toArray()
      .then((product) => {
        if (product.length > 0) {
          const obj = product[0];
          if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
            const batchProductIndex = obj.mBatch.findIndex((bp) => bp.batchno.toLowerCase() === productSearchInput.toLowerCase());
            if (batchProductIndex >= 0 && obj.multiPrice === "N") {
              addDefinedProduct(
                obj,
                obj.mBatch[batchProductIndex].upc,
                obj.mBatch[batchProductIndex].batchno,
                obj.mBatch[batchProductIndex].mBatchId,
                obj.mBatch[batchProductIndex].price
              );
            } else if (obj.mBatch.length === 1) {
              addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
            } else {
              // let upcKeys = 0;
              const productSet = [];
              const localObj = obj;
              for (let i = 0; i < obj.mBatch.length; i += 1) {
                const batchItem = { ...localObj.mBatch[i] };
                const obj = { ...localObj };
                if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
                  // prettier-ignore
                  const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
                  const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
                  batchItem.price = taxedPrice;
                  obj.cTaxId = obj.contraTaxId;
                  obj.taxRate = obj.contraRate;
                }
                const productDefined = {
                  batchno: batchItem.batchno,
                  description: obj.description,
                  discount: 0,
                  discountName: "",
                  imageurl: obj.imageurl,
                  isDecimal: obj.isDecimal,
                  isManualQty: obj.isManualQty,
                  isPromoApplicable: obj.isPromoApplicable,
                  isReturn: false,
                  mBatchId: batchItem.mBatchId,
                  mPricingruleId: null,
                  name: obj.name,
                  name2: obj.name2,
                  nettotal: 0,
                  primaryOrderLine: null,
                  productId: obj.mProductId,
                  realPrice: obj.cTaxId,
                  returnQty: null,
                  salePrice: obj.cTaxId,
                  mrpPrice: batchItem.listPrice,
                  stock: obj.onhandQty,
                  tax: obj.cTaxId,
                  taxAmount: 0,
                  taxRate: obj.taxRate,
                  uom: obj.csUomId,
                  uom_name: obj.uomName,
                  isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
                  isQtyDesimal: obj.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
                  upc: batchItem.upc,
                  value: obj.value,
                  weight: 0,
                  shortDescription: obj.shortDescription,
                  hsncode: obj.hsncode,
                  csBunitId: obj.csBunitId,
                  mProductCategoryId: obj.mProductCategoryId,
                  productManufacturerId: obj.productManufacturerId,
                  productBrandId: obj.productBrandId,
                  batchedProduct: obj.batchedProduct,
                  batchedForSale: obj.batchedForSale,
                  batchedForStock: obj.batchedForStock,
                  multiPrice: obj.multiPrice,
                  shelfLife: obj.shelfLife,
                };
                // if (batchItem.upc.toLowerCase() === data.toLowerCase()) upcKeys += 1;
                productSet.push(productDefined);
              }
              setBatchSetAvailable([...productSet]);
              setDisplayBatchSelection(true);
            }
          } else {
            addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
          }
        } else {
          message.warning("Product Not Found !");
        }
      });
  };

  const clearProductSearchResults = () => {
    setProductSearchInput("");
    // setProductsList([]);
    // if (selectedProductCategory !== "allProducts") {
    //   setSelectedProductCategory("allProducts");
    // } else {
    //   setSelectedProductCategory("all");
    //   setTimeout(() => {
    //     setSelectedProductCategory("allProducts");
    //   }, 200);
    // }
  };

  const productSearchInputRef = useRef(null);
  const productListCardRef = useRef(null);

  const closeProductPanel = () => {
    setIsSearchProducts(false);
    setIsProductsVisible(false);
    setIsProductsFilter(false);
    setProductSearchInput("");
    clearProductSearchResults();
    productSearchInputRef.current.blur();
  };
  // PRODUCT SEARCH END

  const cartRef = useRef();
  useEffect(() => {
    cartRef.current = { ...cart };
    if (cart.items.length === 0) {
      setOverPayedAmount(0);
      setSelectedProductInCart({});
      setShowPaymentMethods(false);
    }
  }, [cart]);

  const addProduct = async(addToCart, qty, totalQtyFlag) => {
    setShowPaymentMethods(false);
    const cart = { ...cartRef.current };
    setProductSearchInput("");
    const weight = parseFloat(qty);
    const addedToCart = cart.items;
    let index = addedToCart.findIndex((p) => p.productId === addToCart.productId && p.upc === addToCart.upc && p.mBatchId === addToCart.mBatchId && p.isReturn === false && !p.bundleId);
    let packFlag = true;

    if(index>=0){
      let checkWeight = totalQtyFlag ? parseFloat(weight.toFixed(addToCart.isQtyDesimal)) : parseFloat(addedToCart[index].weight) + parseFloat(weight);
      if(parseFloat(checkWeight) === 0 && addToCart.linkingProduct === addedToCart[index].linkingProduct){
        let tempBundleId = addToCart.bundleId ? addToCart.bundleId : null
        addedToCart.map((pro) => {
          if(tempBundleId === pro.bundleId){
            delete pro.bundleId;
          }
        })
      }
    }

    if(addedToCart[index]?.bundleId){
      packFlag = false;
    }

    if(qty <0){
      let bundleFlag = addToCart.bundleId ? true : true;
      index = addedToCart.findIndex((p) => p.productId === addToCart.productId && p.upc === addToCart.upc && p.mBatchId === addToCart.mBatchId && p.isReturn === false && p.linkingProduct === addToCart.linkingProduct && bundleFlag);
      packFlag = true;
      let tempBundleId = addToCart.bundleId ? addToCart.bundleId : null
      addedToCart.map((pro) => {
        if(tempBundleId === pro.bundleId){
          delete pro.bundleId;
        }
      })
    }

    if (index >= 0 && !addedToCart?.[index]?.parkedItem && packFlag) {
      addedToCart[index].weight = totalQtyFlag ? parseFloat(weight.toFixed(addToCart.isQtyDesimal)) : parseFloat(addedToCart[index].weight) + parseFloat(weight);
      
      addToCart.weight = parseFloat(addedToCart[index].weight.toFixed(addToCart.isQtyDesimal));
      if (parseFloat(addedToCart[index].weight) === 0 && addToCart.linkingProduct === addedToCart[index].linkingProduct) {
        setSelectedProductInCart({});
        addedToCart.splice(index, 1);
      } else {
        let manualDiscountInput = 0;
        if (addedToCart[index].discountType === "PD") {
          manualDiscountInput = addedToCart[index].discountValue;
        }
        const discountAmt = (parseFloat(manualDiscountInput) / 100) * parseFloat(addedToCart[index].realPrice);
        addedToCart[index].discount = totalQtyFlag ? discountAmt * weight : discountAmt * addedToCart[index].weight;
        const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
        const mrp = totalQtyFlag ? parseFloat(sp) * weight : parseFloat(sp) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = mrp;
        delete addedToCart[index].nextRule;
        message.success(`${addedToCart[index].name} Added Successfully`, 0.1);
      }
    } else {
      if (parseFloat(weight) !== 0) {
        addToCart.linkingProduct = uuidv4().replace(/-/g, "").toUpperCase();
        addToCart.weight = parseFloat(weight.toFixed(addToCart.isQtyDesimal));
        delete addToCart.nextRule;
        const mrp = parseFloat(addToCart.salePrice) * addToCart.weight;
        const tax = mrp - mrp / (1 + addToCart.taxRate / 100);
        addToCart.taxAmount = tax;
        addToCart.nettotal = mrp;
        addToCart.salesRepId = salesRepresentDefaultLine.salesRepresentId;
        addToCart.salesRepName = Object.keys(salesRepresent).length > 0 ? salesRepresentDefaultLine.name : "";
        addedToCart.unshift(addToCart);
        message.success(`${addToCart.name} Added Successfully`, 0.1);
      }
    }
  
    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }
  
    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    if (cart.items.length === 0) {
      deleteCart();
    }
    let cartObj = {
      ...cart,
      items: [...addedToCart],
      total: parseFloat(totalPrice.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    };
    let updatedCart = await pricingRuleController(addToCart, cartObj, cart, setCart, cartRef, orderType);
    
    if (cart.manualDiscountApplied && cart.manualDiscountApplied !== 0) {
      setLoader(true);
      setTimeout(() => {
        processTotalManualDiscount(cart.manualDiscountApplied);
        setLoader(false);
      }, 200);
    }
  
    let updatedTotalTax = 0;
    let updatedTotalPrice = 0;
    let updatedTotalItemsQty = 0;
    let updatedTotalDiscounts = 0;
    for (let i = 0; i < updatedCart.items.length; i += 1) {
      updatedTotalPrice += updatedCart.items[i].nettotal;
      updatedTotalItemsQty += updatedCart.items[i].weight;
      updatedTotalTax += updatedCart.items[i].taxAmount;
      updatedTotalDiscounts += updatedCart.items[i].discount;
      updatedCart.items[i].key = i;
    }
  
    const updatedRoundOffValue = Math.round(updatedTotalPrice);
    const updatedTotalRoundOff = updatedTotalPrice - updatedRoundOffValue;
  
    let finalCartObj = {
      ...updatedCart,
      items: [...updatedCart.items],
      total: parseFloat(updatedTotalPrice.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
      tax: updatedTotalTax,
      discount: updatedTotalDiscounts,
      totalQty: updatedTotalItemsQty,
      roundOff: updatedTotalRoundOff,
    };
    setCart(finalCartObj);
    localStorage.setItem("cartObj",JSON.stringify(finalCartObj));
    setProductsList(productsCopy.slice(0, 100));
    setSelectCategotyList();
    setSelectedProductBrand();
    
    // quantityInputRef.current.focus();
    // quantityInputRef.current.select();
  };

  const [documentSequence, setDocumnetSequence] = useState(tillDocumentSequence);
  const [activeOrderProcess, setActiveOrderProcess] = useState(false);
  const orderState = useRef(0);

  const processOrder = (param) => {
    orderState.current += 1;
    if (orderState.current === 1 && cart.items.length !== 0) {
      processOrderApproved(param);
    } else {
      setPaymentModal(false);
    }
    // }
  };
  const openDrawer = ()=>{
    let openDrawerPayload = `<?xml version="1.0" encoding="UTF-8"?>
    <output>
    <opendrawer/>
    </output>`
    Axios
    .post(`${printerURL}printer`, openDrawerPayload, {
      headers: {
        'Content-Type': 'application/xml; charset=UTF-8',
        'Accept': 'application/xml'
      }
    })
    .then(() => {
      console.log("openDrawer success!");
    })
    .catch((response) => {
      console.log("openDrawer failed!", response);
    });
  
  }
  const processOrderApproved = async (param, obj) => {
    if (!activeOrderProcess) {
      setActiveOrderProcess(true);
      const cartToDb = typeof obj === "object" ? obj : cart;
      const newDocumentSequence = documentSequence + 1;

      const tillSession = JSON.parse(localStorage.getItem("tillSession"));
      const tillSessionId = tillSession.tillSessionId;

      if (param !== "layaway") {
        cartToDb.layAway = "N";
        const change = cartToDb.paid - cartToDb.total;

        if (cart.items.filter((it) => it.isReturn === true).length > 0) {
          cartToDb.change = 0;
          let paymentType = selectedPaymentMethod.name.toLowerCase() === "cash" ? "cash" : selectedPaymentMethod.name.toLowerCase() === "card" ? "card" : "cash";
          const pMindex = tillDataPaymentMethods.findIndex((pi) => pi.name.toLowerCase() === paymentType);
          cartToDb.payments.push({ ...tillDataPaymentMethods[pMindex], amount: 0 });
        } else {
          cartToDb.change = change;
        }
        const cPi = cartToDb.payments.findIndex((pi) => pi.name.toLowerCase() === "cash");

        if (cPi >= 0) {
          const updatedCashAmt = parseFloat(cartToDb.payments[cPi].amount) - change;
          cartToDb.payments[cPi].amount = `${updatedCashAmt}`;
        } else {
          const updatedCashAmt = parseFloat(cartToDb.payments[0].amount) - change;
          cartToDb.payments[0].amount = `${updatedCashAmt}`;
        }
      } else {
        cartToDb.layAway = "Y";
      }

      if (posConfig.loyaltyApplicable === "Y") {
        if (cartToDb.customer.loyaltyLevel.cwrLoyaltyLevelId) {
          let totalPrice = 0;
          tillData.loyaltyApply.map((item, index) => {
            if (item.applicableFor === "All") {
              cartToDb.accumulationPoints = parseFloat(cartToDb.customer.loyaltyLevel.accumulationRate) * cartToDb.total;
            } else if (item.applicableFor === "CST") {
              cartToDb.items.map((cartItem, cartIndex) => {
                let filterData =
                  item.prodCategories.length > 0 &&
                  item.prodCategories.filter((categoryItem) => categoryItem.include === "Y" && categoryItem.mProductCategoryId === cartItem.mProductCategoryId);
                if (filterData.length > 0) {
                  totalPrice += cartToDb.items[cartIndex].salePrice;
                }
              });
            }
            cartToDb.accumulationPoints = parseFloat(cartToDb.customer.loyaltyLevel.accumulationRate) * totalPrice;
          });
        }
      }

      // cartToDb.sOrderID = uuidv4().replace(/-/g, "").toUpperCase();
      cartToDb.orderTime = timeStamp();
      cartToDb.createdBy = tillData.tillAccess.csUserId;
      cartToDb.documentno = `${tillData.tillAccess.cwrTill.prefix}${newDocumentSequence}`;
      cartToDb.orderType = orderType.cwrSaletype.cwrSaletypeId;
      cartToDb.orderDate = moment(new Date()).format("YYYY-MM-DD");
      cartToDb.tillSessionId = tillSessionId;
      cartToDb.key = uuidv4().replace(/-/g, "").toUpperCase();
      cartToDb.isSynced = 0;
      cartToDb.syncAttempts = 0;
      cartToDb.customerSearchKey = cartToDb.customer.code;
      setPaymentProcessFlag(false);
      await db.orders
        .add(cartToDb)
        .then(async () => {
          setPaymentModal(false);
          let takeAway = localStorage.getItem("dineIn");
          if (takeAway === "Y") {
            let tableData = JSON.parse(localStorage.getItem("tableName"));
            await db.tableData
              .where("cwrFbTableId")
              .equals(tableData.cwrFbTableId)
              .toArray()
              .then(async (response) => {
                if (response.length > 0) {
                  // let finalData = {...response[0]};
                  response[0].salesHistory = "Y";
                  response[0].fbOrderSync = "N";
                  response[0].tableSync = "N";
                  response[0].statusType = "OPN";
                  response[0].fbOrderStatus = "CO";
                  response[0].color = "#a7c957";
                  response[0].ordered = "N";
                  let obj;
                  await db.fbOrderData
                    .where("cwrFbTableId")
                    .equals(tableData?.cwrFbTableId)
                    .toArray()
                    .then((ordersFetched) => {
                      if (ordersFetched.length > 0) {
                        ordersFetched.map(async (fbOrder) => {
                          if (fbOrder.fbOrderStatus === "IP") {
                            fbOrder.salesHistory = "Y";
                            fbOrder.fbOrderSync = "N";
                            fbOrder.tableSync = "N";
                            fbOrder.statusType = "OPN";
                            fbOrder.fbOrderStatus = "CO";
                            fbOrder.color = "#a7c957";
                            fbOrder.sOrderID = cart.sOrderID;
                          }
                          obj = {
                            fbOrderId: fbOrder.fbOrderId,
                            order: fbOrder,
                          };
                          await db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
                        });
                      }
                    });
                  await db.tableData.put(response[0], response[0].cwrFbTableId);
                  SyncData(response[0], "upsertTableStatus");
                  SyncData(response[0], "upsertFbOrder");
                  const orderData = {
                    tableDetails: {
                      cwrFbTableId: response[0].cwrFbTableId,
                      data: response[0],
                    },
                    fbOrder: obj,
                  };
                  sendOrder(orderData);
                }
              });
          }
          deleteCart();
          localStorage.setItem("cartObj", []);
          localStorage.setItem("documentSequence", newDocumentSequence);
          setDocumnetSequence(newDocumentSequence);
          message.success(`Order ${cartToDb.documentno} Created Successfully`);
          orderState.current = 0;
          setActiveOrderProcess(false);
          localStorage.removeItem("totalDiscountsCartRef");
          if (cartToDb.hasOwnProperty("giftVoucherType")) {
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `mutation {
              generateGiftVoucher(
                giftVoucher: {
                  cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
                  tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                  b2cCustomerId: "${cartToDb.customer.cwrCustomerId}"
                  giftVoucherType: "${cartToDb.giftVoucherType}"
                  voucherAmount: ${cartToDb.giftVoucherAmount}
                }
              ) {
                status
                message
                cwrGiftVoucherId
                voucherAmount
                voucherCode
              }
            }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${tokens.token_type} ${tokens.access_token}`,
              },
            })
              .then((generateGiftVoucher) => {
                const gGV = generateGiftVoucher.data.data.generateGiftVoucher;
                if (gGV.status === "200") {
                  cartToDb.voucherCode = gGV.voucherCode;
                  PrintController(cartToDb,cart);
                } else {
                  throw new Error(gGV);
                }
              })
              .catch((error) => {
                console.error(error);
                cartToDb.voucherCode = null;
                PrintController(cartToDb,cart);
              });
          } else {
            cartToDb.voucherCode = null;
            if (tillaccess?.layout === "2") {
              cartToDb["orderSelection"] = orderTypeSelection;
            }
            PrintController(cartToDb,cart);
          }
        })
        .catch((error) => {
          console.error("Failed to place an order: ", error);
        });
      if (tillLayout === 2) {
        setTimeout(() => {
          setLoader(false);
          history.push("/table-management");
        }, 4000);
      }
    }
  };

  //// CART OPERATIONS END ////

  // OFFERS AND DISCOUNTS BLOCK START
  const [offerProductsList, setOfferProductList] = useState();
  const [displayOfferProductSelectiton, setDisplayOfferProductSelection] = useState(false);

  const selectOfferProduct = (product) => {
    const pro = product;
    addProduct(pro, 0);
    setDisplayOfferProductSelection(false);
    message.success(`${pro.name} (Free) Added Successfully`);
    if (tillLayout === 2) {
      setPaymentModal(true);
    }
  };
  // OFFERS AND DISCOUNTS BLOCK START

  //// PAYMENTS BLOCK START ////
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentProcessFlag, setPaymentProcessFlag] = useState(false);
  const [amount, setAmount] = useState("");
  const [overPayedAmount, setOverPayedAmount] = useState(0);
  const [denaminationsKeyboard, setDenaminationsKeyboard] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});

  const [loader, setLoader] = useState(false);

  const onChangeAmount = (event) => {
    let type = "3"
    let value = event.target.value;
   paymentProcess(type,value,setAmount,amount,cart,tillData,selectedPaymentMethod,setOverPayedAmount,setNumb,numb);
  };

  const handleAmount = (value) => {
    let type = "1"
   paymentProcess(type,value,setAmount,amount,cart,tillData,selectedPaymentMethod,setOverPayedAmount,setNumb,numb);
  };

  const handleCashPayment = (value) => {
    let type = "2"
    paymentProcess(type,value,setAmount,amount,cart,tillData,selectedPaymentMethod,setOverPayedAmount,setNumb,numb);

  };

  const [paymentModalByCustomerState, setPaymentModalByCustomerState] = useState(false);

  const openPaymentModal = () => {
    if (tillLayout === 2) {
      setPaymentModal(true);
    }
    setPaymentProcessFlag(true);
    openPaymentModalByCustomer();
  };

  const openPaymentModalByCustomer = (cartObj) => {
    paymentModalInputRef?.current?.select();
    paymentModalInputRef?.current?.focus();
    const cartData = cartObj ? { ...cartObj } : cart;
    localStorage.setItem("totalDiscountsCartRef", JSON.stringify(cartData));
    setPaymentModalByCustomerState(false);
    if (cartData?.items?.length > 0) {
      db.pricingRules.toArray().then((pr) => {
        const pricingRuleCount = pr.length;
        if (pricingRuleCount > 0) {
          pr.sort((a, b) => {
            if (a.priority === null && b.priority === null) {
              return 0;
            } else if (a.priority === null) {
              return 1;
            } else if (b.priority === null) {
              return -1;
            }
            return parseInt(a.priority, 10) - parseInt(b.priority, 10);
          });
          for (let i = 0; i < pricingRuleCount; i++) {
            const pricingRule = pr[i];
            if (dateValidator(pricingRule.startDate, pricingRule.endDate)) {
              if (pricingRule.timeSpecific === "Y") {
                const weekDay = currentDay();
                const pStartTime = pricingRule.starttime.substring(11);
                const pEndTIme = pricingRule.endtime !== null ? pricingRule.endtime.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
                const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                  processBillDiscounts(pricingRule, cartData);
                }
              } else {
                processBillDiscounts(pricingRule, cartData);
              }
            }
          }
        }
      });
    }
  };

  const processBillDiscounts = (pricingRule, cartObj) => {
    if (pricingRule.type === "TD" && pricingRule.manualDiscount === "N") {
      TotalBillDiscount(pricingRule, setCart, cart, orderType, cartObj);
    }

    if (pricingRule.type === "TDF" && pricingRule.manualDiscount === "N") {
      TotalBillFreeProductDiscount(pricingRule, setCart, cart, orderType, cartObj, addProduct, setOfferProductList, setDisplayOfferProductSelection);
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setSelectedPaymentMethod("");
    // const billCart = JSON.parse(localStorage.getItem("totalDiscountsCartRef"));
    // localStorage.removeItem("totalDiscountsCartRef");
    // setCart({ ...billCart });
  };

  const [loyaltyPaymentOtp, setLoyaltyPaymentOtp] = useState();
  const [loyalityOtpModalVisible, setLoyalityOtpModalVisible] = useState(false);
  const [loyalityOtpData, setLoyalityOtpData] = useState();

  const processOtpInput = () => {
    const { otp, paymentMethod, value, redeemPoints } = loyalityOtpData;
    setLoyalityOtpModalVisible(false);
    if (otp === loyaltyPaymentOtp) {
      message.success("Loyality Points Redeemed !");
      paymentMethod.redemptionPoints = parseInt(redeemPoints);
      processPayment(paymentMethod, value);
    } else {
      message.warning("Invalid OTP");
    }
    setLoyalityOtpData();
    setLoyaltyPaymentOtp();
  };

  const processInnovitiEDCPayment = (amountAdded, currentPaymentMethod, paymentMethod, value) => {
    if (amountAdded >= 1) {
      const requestCode = innovitiPaymentCodes[currentPaymentMethod.name];
      const trxId = uuidv4().replace(/-/g, "").toUpperCase();
      const cashierId = tillData.tillAccess.csUserId;
      const customerMobileNo = cart.customer.mobileNo;
      setLoader(true);
      setPaymentModal(false);
      console.info(`Innoviti Request URL: ${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`);
      Axios.get(`${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`)
        .then((response) => {
          const result = response.data;
          const { ResponseCode, ResponseMessage } = result;
          if (ResponseCode === "00") {
            setLoader(false);
            if (tillLayout === 2) {
              setPaymentModal(true);
            }
            message.success("Payment Success");
            processPayment(paymentMethod, value);
          } else {
            setLoader(false);
            if (tillLayout === 2) {
              setPaymentModal(true);
            }
            message.error(`Payment Failed: ${ResponseMessage}`);
          }
        })
        .catch((error) => {
          console.error("Payment Failed:", error);
          setLoader(false);
          if (tillLayout === 2) {
            setPaymentModal(true);
          }
          message.error("Payment Failed: Transaction timeout / Check EDC Connection");
        });
    } else {
      console.warn("Minimum amount not satisfied");
    }
  };

  const cancelPaytmVerifyPaymentRequest = (options) => {
    options.data.type = 6;
    Axios(options);
  };

  const paytmVerifyEDCPaymentStatus = (options) => {
    return new Promise(async function (verify) {
      Modal.confirm({
        title: "Payment request initiated",
        content: "Please verify",
        okText: "Verify",
        onOk: () => {
          verify("retry");
        },
        onCancel: () => {
          cancelPaytmVerifyPaymentRequest(options);
          verify("cancel");
        },
      });
    });
  };

  const chekPaytmEDCPaymentStatus = (options) => {
    return new Promise(async function (process, reject) {
      let retries = true;
      while (retries) {
        try {
          const response = await Axios(options);
          const result = response.data;
          // const result = { "result": 0, "resultCode": "101", "resultMessage": "Transaction already present", "transactionID": null, "authorizationID": null, "request": null, "properties": null };
          if (result.result?.toString() === "200") {
            retries = false;
            process(true);
          } else if (result.result?.toString() === "202" || result.result?.toString() === "203") {
            message.info(result.resultMessage);
            const retryStatus = await paytmVerifyEDCPaymentStatus(options);
            if (retryStatus === "retry") {
              retries = true;
            } else {
              message.error("Payment canceled");
              retries = false;
              process(false);
            }
          } else {
            message.error(result.resultMessage);
            retries = false;
            process(false);
          }
        } catch (err) {
          retries = false;
          reject(err);
        }
      }
    });
  };

  const processPaytmEDCPayment = (amountAdded, currentPaymentMethod, paymentMethod, value) => {
    const trxId = uuidv4().replace(/-/g, "").toUpperCase();
    setLoader(true);
    if (tillLayout === 2) {
      setPaymentModal(true);
    }
    const requestData = {
      url: `${tillData.tillAccess.cwrTill.hardwareController.imageUrl}payment`,
      method: "POST",
      data: {
        type: 0,
        transactionID: trxId,
        terminalID: "111",
        currency: "INR",
        amount: amountAdded,
        properties: {},
        paymentProvider: "Paytm",
      },
      headers: {
        "Content-Type": "Application/json",
      },
    };

    chekPaytmEDCPaymentStatus(requestData)
      .then((response) => {
        if (response) {
          setLoader(false);
          if (tillLayout === 2) {
            setPaymentModal(true);
          }
          message.success("Payment Success");
          processPayment(paymentMethod, value);
        } else {
          setLoader(false);
          if (tillLayout === 2) {
            setPaymentModal(true);
          }
        }
      })
      .catch((error) => {
        console.error("Payment Failed:", error);
        setLoader(false);
        if (tillLayout === 2) {
          setPaymentModal(true);
        }
        message.error("Payment Failed: Transaction timeout / Check EDC Connection");
      });
  };

  const [paymentModalLoyalityMessages, setPaymentModalLoyalityMessages] = useState({ inputMessage: "Enter Amount", buttonText: "Add Payment" });
  useEffect(() => {
    if (selectedPaymentMethod?.name?.toLowerCase() === "cash") {
      setDenaminationsKeyboard(true);
    }
    if (selectedPaymentMethod.isloyalty) {
      setPaymentModalLoyalityMessages({
        inputMessage: "Enter Points to Redeem",
        buttonText: "Redeem",
      });
    } else if (selectedPaymentMethod.isGiftCard) {
      setPaymentModalLoyalityMessages({
        inputMessage: "Enter Coupon Code",
        buttonText: "Redeem",
      });
    } else {
      setPaymentModalLoyalityMessages({
        inputMessage: "Enter Amount",
        buttonText: "Add Payment",
      });
    }
  }, [selectedPaymentMethod]);

  const requestPayment = async (paymentMethod, value, grant) => {
    const newPaymentAmount = parseFloat(value).toFixed(2);
    let setAuthTokens;
    let amountAdded;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    if (cart.payments.length > 0) {
      const currentPaymentsTotal = cart.payments.reduce((total, payment) => {
        const paymentAmount = parseFloat(payment.amount);
        return isNaN(paymentAmount) ? total : total + paymentAmount;
      }, 0).toFixed(2);
  
      // Calculate the new total by adding the currentPaymentsTotal and newPaymentAmount
       amountAdded = (parseFloat(currentPaymentsTotal) + parseFloat(newPaymentAmount)).toFixed(2);
  
    
      // Now, amountAdded contains the sum of amounts in the payments array
    }else{
       amountAdded = parseFloat(value).toFixed(2);
    }
    if (grant !== undefined) {
      processPayment(paymentMethod, value);
    } else {
      const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
      if (paymentMethod !== undefined && paymentMethod !== "" && validation.test(parseFloat(value))) {
        const currentPaymentMethod = paymentMethod;
        if (currentPaymentMethod.integratedPayment) {
          if (currentPaymentMethod.paymentProvider === "INV") {
            processInnovitiEDCPayment(amountAdded, currentPaymentMethod, paymentMethod, value);
          } else if (currentPaymentMethod.paymentProvider === "PTM") {
            processPaytmEDCPayment(amountAdded, currentPaymentMethod, paymentMethod, value);
          } else {
            message.error("Invalid payment provider configuration");
          }
        } else if (currentPaymentMethod.integratedPayment === false && currentPaymentMethod.paymentProvider === "PTM") {
          processQrPaytmPayment(amountAdded, currentPaymentMethod, paymentMethod, value);
        } else if (currentPaymentMethod.iscredit) {
          if (cart.customer.iscredit) {
            setLoader(true);
            setPaymentModal(false);
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `query{
                verifyCredit(customerId:"${cart.customer.cwrCustomerId}"){
                    iscredit
                    creditLimit
                }
            }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${setAuthTokens}`,
              },
            })
              .then((creditResponse) => {
                const { iscredit, creditLimit } = creditResponse.data.data.verifyCredit;
                if (iscredit) {
                  if (amountAdded > 0 && amountAdded <= creditLimit) {
                    message.success("Credit Payment Granted");
                    processPayment(paymentMethod, value);
                  } else {
                    message.warning("Cannot process,Available Credit is less than payment amount!");
                  }
                } else {
                  message.warning("Customer not eligible for credit payment !");
                }
              })
              .catch((err) => {
                console.error(err);
                message.error("Payment Failed !");
              })
              .finally(() => {
                setLoader(false);
                if (tillLayout === 2) {
                  setPaymentModal(true);
                }
              });
          } else {
            message.warning("Credit payment not available for customer !");
          }
        } else if (currentPaymentMethod.isloyalty) {
          setLoader(true);
          setPaymentModal(false);
          if (cart.customer.loyaltyLevel.cwrLoyaltyLevelId && posConfig.loyaltyApplicable === "Y") {
            const redeemptionRate = cart.customer.loyaltyLevel.redemptionRate;
            const redeemPoints = parseFloat(value);
            const amountForRedeem = parseFloat(redeemptionRate) * redeemPoints;
            if (redeemPoints <= parseFloat(cart.customer.retlLoyaltyBalance)) {
              Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `mutation{
                    verifyLoyalty(customerId:"${cart.customer.cwrCustomerId}", redeemPoint:${redeemPoints}){
                      status
                      message
                      otp
                    }
                  }`,
                },
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${setAuthTokens}`,
                },
              })
                .then((loyalityResponse) => {
                  // const loyalityResponse = {data:{data:{verifyLoyalty:{ status: "Success", otp: "12345", message: "OTP Generated !"}}}};
                  const { status, otp } = loyalityResponse.data.data.verifyLoyalty;
                  const responseMessage = loyalityResponse.data.data.verifyLoyalty.message;
                  if (status === "Success") {
                    message.success(responseMessage);
                    setLoyalityOtpData({
                      otp: otp,
                      paymentMethod: paymentMethod,
                      value: amountForRedeem,
                      redeemPoints: redeemPoints,
                    });
                    setLoyalityOtpModalVisible(true);
                  } else {
                    message.warning(responseMessage);
                  }
                })
                .catch((err) => {
                  console.error(err);
                  message.error("Payment Failed !");
                })
                .finally(() => {
                  setLoader(false);
                  if (tillLayout === 2) {
                    setPaymentModal(true);
                  }
                });
            } else {
              message.warning("Insuffient loyality points !");
              setLoader(false);
              if (tillLayout === 2) {
                setPaymentModal(true);
              }
            }
          } else {
            message.warning("Loyalty not found for selected customer !");
            setLoader(false);
            if (tillLayout === 2) {
              setPaymentModal(true);
            }
          }
        } else if (currentPaymentMethod.isGiftCard) {
          const couponCodeFromAmount = parseFloat(value);
          setLoader(true);
          Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query{
                redeemVoucher(couponCode:${couponCodeFromAmount}, bunitId:"${tillData.tillAccess.csBunit.csBunitId}"){
                  cwrGiftVoucherId
                  cSBunitID
                  pinRequired
                  pinNo
                  voucherAmount
                  amountAvailable
                  redeemed
                  tillId
                  b2cCustomerId
                }
              }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${setAuthTokens}`,
            },
          })
            .then((redeemVoucherResponse) => {
              const redeemVoucherResponseData = redeemVoucherResponse.data.data.redeemVoucher;
              if (redeemVoucherResponseData !== null) {
                let amountRedeemedValue = parseFloat(redeemVoucherResponseData[0].amountAvailable);
                if (amountRedeemedValue > parseFloat(cart.total)) {
                  amountRedeemedValue = parseFloat(cart.total);
                }
                message.success("Coupon Redeemed !");
                processPayment(paymentMethod, amountRedeemedValue);
              } else {
                message.warn("Invalid Coupon !");
              }
            })
            .catch((error) => {
              console.error(error);
              message.warn("Unable to access info right now !");
            })
            .finally(() => {
              setLoader(false);
            });
        } else {
          processPayment(paymentMethod, value);
        }
      } else {
        message.warn("Invalid Payment !");
      }
    }
  };

  const processPayment = (paymentMethod, value) => {
    const paymentAddons = cart.payments;
    const amountAdded = parseFloat(value);
    const currentPaymentMethod = paymentMethod;
    let shouldRemoveRedeemPoints = false;

    if (paymentMethod.isloyalty && amountAdded < 0) {
      shouldRemoveRedeemPoints = true;
    }

    const index = paymentAddons.findIndex((p) => p.finPaymentmethodId === currentPaymentMethod.finPaymentmethodId);
    if (index >= 0) {
      const newAmount = parseFloat(paymentAddons[index].amount) + parseFloat(amountAdded);
      paymentAddons[index].amount = parseFloat(newAmount).toFixed(2);
      if (parseFloat(paymentAddons[index].amount) === 0) {
        paymentAddons.splice(index, 1);
      }
    } else {
      if (amountAdded !== 0) {
        currentPaymentMethod.amount = parseFloat(amountAdded).toFixed(2);
        paymentAddons.push(currentPaymentMethod);
      }
    }

    let totalAmountAdded = 0;
    const paymentMethodsCount = paymentAddons.length;
    for (let i = 0; i < paymentMethodsCount; i += 1) {
      totalAmountAdded += parseFloat(paymentAddons[i].amount);
    }

    if (paymentMethod.redemptionPoints) {
      cart.redemptionPoints = paymentMethod.redemptionPoints;
    }

    const redemptionPts = shouldRemoveRedeemPoints ? 0 : cart.redemptionPoints;

    let creditAmountValue = cart.creditAmount;
    if (paymentMethod.iscredit && amountAdded > 0) {
      creditAmountValue = amountAdded;
    }

    if (paymentMethod.iscredit && amountAdded < 0) {
      creditAmountValue = 0;
    }
    cart.total = parseFloat(cart.total.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    const finalCartObj = {
      ...cart,
      paid: parseFloat(totalAmountAdded.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
      payments: [...paymentAddons],
      redemptionPoints: redemptionPts,
      creditAmount: creditAmountValue,
    }
    setCart(finalCartObj);
    localStorage.setItem("cartObj",JSON.stringify(finalCartObj));
    setSelectedPaymentMethod("");
    setAmount(
      parseFloat(cart.total) > parseFloat(totalAmountAdded) ? parseFloat(cart.total - totalAmountAdded).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision) : ""
    );
    // if(cart.total<=totalAmountAdded){
    //   processOrder();
    // }
  };
  //// PAYMENTS BLOCK END ////

  const eBillConfig = useRef({});
  useEffect(async () => {
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
          getPOSConfig(tillId: "${tillValue.cwr_till_id}",name:null) {
            cwrPosConfigId
            name
            posType
            application
            configJson
            PricingRule
            ExpiryDiscount
            activateExpiryDiscount
          }
        }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${setAuthTokens}`,
      },
    }).then((resp) => {
      if (resp.data.data.getPOSConfig.length > 0) {
        const ebConfig = JSON.parse(resp.data.data.getPOSConfig[0]?.configJson);
        eBillConfig.current = ebConfig;
      }
    });
  }, []);

  const sendWebHookData = async (pendingOrders) => {
    try {
      // const orders = await db.orders.where("isSynced").equals(0).toArray();
      const orders = [...pendingOrders];
      for (const order of orders) {
        let shortId = "";
        const { eBillWebHookURL } = eBillConfig.current;
        try {
          const { data } = await Axios.get(`${eBillWebHookURL}/ebill/uniqueid`);
          shortId = data;
        } catch (error) {
          console.error(error);
          throw new Error("Failed to Fetch ID");
        }

        const orderItems = [];
        const taxKeyValue = [];
        const taxType = [];

        order.items.forEach((orderItem, i) => {
          let taxRate = orderItem.taxRate;
          if (taxType.indexOf(taxRate) !== -1) {
            let taxVal = orderItem.taxAmount / 2;
            for (let j = 0; j < taxKeyValue.length; j += 1) {
              const keys = Object.keys(taxKeyValue[j]);
              if (keys[0].toString() === taxRate.toString()) {
                taxKeyValue[j][taxRate] = taxKeyValue[j][taxRate] + taxVal;
              }
            }
          } else {
            taxType.push(taxRate);
            let taxVal = orderItem.taxAmount / 2;
            taxKeyValue.push({ [taxRate]: taxVal });
          }

          orderItems.push({
            line_no: i + 1,
            sku: orderItem.value,
            name: orderItem.name,
            description: null,
            hsn_code: null,
            selling_price: orderItem.salePrice,
            quantity: orderItem.weight,
            amount: orderItem.nettotal,
            list_price: orderItem.realPrice,
            uom: orderItem.uom_name,
            attributes: {
              mc: "",
              symc: "",
            },
            taxes: [
              {
                tax_name: "GST",
                percentage: orderItem.taxRate,
                amount: orderItem.taxAmount,
                taxableAmount: orderItem.nettotal,
              },
            ],
            brand: null,
            categoryId: orderItem.mProductCategoryId,
            categoryName: null,
          });
        });

        const payments = [];
        order.payments.forEach((payment, i) => {
          payments.push({
            payment_method: payment.name,
            amount: payment.amount,
          });
        });

        const orderData = {
          clientId: tillData.tillAccess.csClientId,
          storeId: tillData.tillAccess.csBunit.csBunitId,
          eBillCommType: eBillConfig.current.eBillCommType,
          created: order.orderTime,
          updated: order.orderTime,
          short_id: shortId,
          customer: {
            customer_id: order.customer.cwrCustomerId,
            first_name: order.customer.name,
            last_name: "",
            mobile: order.customer.mobileNo,
          },
          order_type: order.orderType,
          order_id: order.sOrderID,
          bill_no: order.documentno,
          bill_status: "PAID",
          order_date: order.orderTime,
          items: orderItems,
          item_count: order.items.length,
          total_qty: order.totalQty,
          net_total: order.total,
          gross_total: order.total,
          payment: payments,
          taxes: [
            {
              tax_name: "GST",
              amount: order.tax,
              taxableAmount: order.total,
            },
          ],
          additonalTaxinfo: taxKeyValue,
          feedback: false,
          feedbackStars: 0,
        };

        try {
          if (orderData.customer.mobile !== "9999999999") {
            await Axios.post(`${eBillWebHookURL}/ebill/webhook`, orderData);
          }
        } catch (error) {
          console.error(error);
          throw new Error("Failed to POST Webhook");
        }
      }
    } catch (e) {
      console.error("Webhook Error", e);
    }
  };

  // ORDER SYNC BLOCK START
  const syncOrders = async (syncTrigger) => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    if (nWStatus === "online") {
      db.logInformation.toArray().then((fetched) => {
        if (fetched.length > 0) {
          const posLogArray = [];
          fetched.forEach((item) => {
            let value = { ...item };
            db.logInformation.where("id").equals(value.id).delete();
            delete value.id;
            posLogArray.push(`{
            type: "${value.type}", 
            action: "LOG", 
            description: "${value.description}", 
            date: "${value.date}",
            time: "${value.time}",    
            orderNo: "${value.orderNo}",
            remarks: "${value.remarks}"
        }`);
          });
          Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `mutation {
      upsertPOSLog(order: {
          tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
          userId: "${tillData.tillAccess.csUserId}" 
          bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
          lines: [${posLogArray}]
        }) {
        status   
        message
      }
    }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${setAuthTokens}`,
            },
          });
        }
      });

      const csCurrencyId = localStorage.getItem("csCurrencyId");
      db.orders
        .where("isSynced")
        .equals(0)
        .or("isSynced")
        .equals(2)
        .toArray()
        .then((orders) => {
          const pendingOrdersCount = orders.length;
          if (pendingOrdersCount > 0) {
            if (eBillConfig.current.eBill === "Y") {
              sendWebHookData(orders);
            }
            for (let i = 0; i < pendingOrdersCount; i += 1) {
              const orderLines = [];
              for (let j = 0; j < orders[i].items.length; j += 1) {
                let unitPrice = orders[i].items[j].nettotal / orders[i].items[j].weight;
                unitPrice = parseFloat(unitPrice).toFixed(2);
                orderLines.push(`{
              sOrderlineID: "${uuidv4().replace(/-/g, "").toUpperCase()}",
              sOrderlineReturnId: ${orders[i].items[j].sOrderlineReturnId ? `"${orders[i].items[j].sOrderlineReturnId}"` : null}
              created: "${orders[i].orderTime}",
              createdby: "${orders[i].createdBy}",
              updated: "${orders[i].orderTime}",
              updatedby: "${orders[i].createdBy}",
              sOrderId: "${orders[i].sOrderID}",
              line: ${(j + 1) * 10},
              description: "",
              mProductId: "${orders[i].items[j].productId}",
              csUomId: "${orders[i].items[j].uom}",
              csTaxId: "${orders[i].items[j].tax}",
              qty: ${orders[i].items[j].weight},
              unitprice: ${unitPrice},
              netlist: ${orders[i].items[j].salePrice}
              discount: ${orders[i].items[j].discount},
              returnline: ${orders[i].items[j].isReturn},
              returnQty: ${orders[i].items[j].isReturn === true ? Math.abs(orders[i].items[j].weight) : 0},
              mBatchId: ${orders[i].items[j].mBatchId !== null ? `"${orders[i].items[j].mBatchId}"` : null},
					    mPricingruleId: ${orders[i].items[j].mPricingruleId !== null && orders[i].items[j].mPricingruleId !== undefined ? `"${orders[i].items[j].mPricingruleId}"` : null},
              batchedForSale:"${orders[i].items[j].batchedForSale}",
              batchedForStock:"${orders[i].items[j].batchedForStock}",
              batchedProduct:"${orders[i].items[j].batchedProduct}",
              salesRepId: ${orders[i].items[j].salesRepId !== null && orders[i].items[j].salesRepId !== undefined ? `"${orders[i].items[j].salesRepId}"` : null},
              multiPrice: "${orders[i].items[j].multiPrice}",
              discountTypeId: null,
              discountAmount: null,
            }`);
              }
              const paymentsList = [];
              let amtMax = -100000000000000000000;
              let maxFinPaymentMethod;
              for (let k = 0; k < orders[i].payments.length; k += 1) {
                if (amtMax < parseFloat(orders[i].payments[k].amount)) {
                  amtMax = parseFloat(orders[i].payments[k].amount);
                  maxFinPaymentMethod = orders[i].payments[k].finPaymentmethodId;
                }
                paymentsList.push(`{
                  finPaymentmethodID: "${orders[i].payments[k].finPaymentmethodId}",
                  amount: "${orders[i].payments[k].amount}"
                }`);
              }
              let tableData = JSON.parse(localStorage.getItem("tableName"));
              let metaData = [];
              if (localStorage.getItem("dineIn") === "Y") {
                const keysToCheck = ["guestName", "guestType", "cwrFbTableId", "cwrFbsectionId", "noOfPersons", "referredBy", "orderId"];
                localStorage.removeItem("tableName");
                for (const key of keysToCheck) {
                  if (tableData.hasOwnProperty(key)) {
                    metaData.push(`{
                      key: "${key}",
                      value: "${tableData[key]}"
                    }`);
                  }
                }
              }

              const paramsInput = {
                query: `mutation{
              posOrderProcessor(posOrder:{
              sOrderID: "${orders[i].sOrderID}",
              cSClientID: "${tillData.tillAccess.csClientId}",
              cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
              created: "${orders[i].orderTime}",
              createdby: "${orders[i].createdBy}",
              updated: "${orders[i].orderTime}",
              updatedby: "${orders[i].createdBy}",
              csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
              sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
              sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
              sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
              sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
              documentno: "${orders[i].documentno}",
              dateordered: "${orders[i].orderTime}",
              datepromised: "${orders[i].orderTime}",
              csPaymenttermID: null,
              finPaymentmethodId: ${orders[i].layAway === "N" ? `"${maxFinPaymentMethod}"` : null},
              csCurrencyId: "${csCurrencyId}",
              mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
              cwrLongitude: "",
              cwrLatitude: "",
              csUserId: "${tillData.tillAccess.csUserId}",
              cwrB2cCustomerId: "${orders[i].customer.cwrCustomerId}",
              orderreference: "",
              cwrPayref: "",
              cwrPayremarks: "",
              description: "",
              cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
              redemption: ${orders[i].redemptionPoints},
              accumulation: ${orders[i].accumulationPoints},
              roundoff: 0,
              cwrProductQty: ${orders[i].items.length},
              cwrProductCount: ${orders[i].totalQty},
              ofdStatus: "Delivered",
              ofdIspaid: "Y",
              mPricingruleId: ${orders[i].mPricingruleId !== null && orders[i].mPricingruleId !== undefined ? `"${orders[i].mPricingruleId}"` : null},
			        cwrSaletypeId: "${orders[i].orderType}",
              salesRepId:${orders[i].salesRepId !== null && orders[i].salesRepId !== undefined ? `"${orders[i].salesRepId}"` : null},
              discAmount: ${orders[i].discount},
              creditAmount: ${orders[i].creditAmount},
              metaData: [${metaData}], 
              pricingCoupon:{
                mPricingCouponId:${orders[i].mPricingCouponId ? `"${orders[i].mPricingCouponId}"` : null},
                redemptionCount:${orders[i].couponRedemptionCount ? parseInt(orders[i].couponRedemptionCount) : null},
              }
              orderTotal: ${orders[i].total}
              isReturn: ${orders[i].items.filter((f) => f.isReturn === true).length > 0 ? `"Y"` : `"N"`},
              sOrderReturnId: ${orders[i].items?.[0]?.sOrderReturnId ? `"${orders[i].items?.[0]?.sOrderReturnId}"` : null},
              layAway: "${orders[i].layAway}",
              payments: [${paymentsList}],
              line: [${orderLines}],
              })
            {
              documentno 
              status
              message
             }
            }
            `,
              };
              Axios({
                url: serverUrl,
                method: "POST",
                data: paramsInput,
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${setAuthTokens}`,
                },
              })
                .then(async (response) => {
                  const result = response.data.data.posOrderProcessor;
                  const { status } = result;
                  if (status === "200") {
                    console.info(`Order ${orders[i].documentno} synced to Server`);
                    db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 1 });
                    let rfidData = [];
                    await db.rfidData.toArray((products) => {
                      products.map((ele) => {
                        if (ele.tag_status === "SO") {
                          rfidData.push(` {
                             tagValue: "${ele.tag_value}"
                             taggingDate: null
                             batchNumber: null
                             batchId: null
                             warehouseId: null
                             tagStatus: "SO"
                             lastScannedDate: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                             scannedBy: null
                             expirydate: null
                             customAttribute: null
                             tagType: null
                             productCode: "${ele.product_code}"
                             }`);
                          //  db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
                        }
                      });
                    });
                    await Axios({
                      url: serverUrl,
                      method: "POST",
                      data: {
                        query: `mutation{
                          RFIDTag(rfidTag:[${rfidData}]){
                          status
                          message
                          }
                          }`,
                      },
                      headers: {
                        "Content-Type": "Application/json",
                        Authorization: `${setAuthTokens}`,
                      },
                    });
                  } else {
                    console.error("Failed Order Sync ====> ", response);
                    const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                    if (parseInt(orders[i].syncAttempts) < 100) {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                    } else {
                      db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                    }
                  }
                })
                .catch((error) => {
                  // const err = JSON.parse(JSON.stringify(error));
                  // const { message } = err;
                  // if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
                  //   const refToken = tokens.refresh_token;
                  //   const requestBody = {
                  //     grant_type: "refresh_token",
                  //     refresh_token: refToken,
                  //   };
                  //   const config = {
                  //     headers: {
                  //       "Content-Type": "application/x-www-form-urlencoded",
                  //       Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
                  //     },
                  //   };
                  //   Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((newToken) => {
                  //     setAuthTokens=newToken.data;
                  //   });
                  // } else {
                  //   const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                  //   if (parseInt(orders[i].syncAttempts) < 100) {
                  //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                  //   } else {
                  //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                  //   }
                  // }
                });
            }
          }
        });
    }
    if (syncTrigger === "orderHistory") {
      showOrderHistory();
    }
  };

  useEffect(() => {
    const syncOrdersInterval = setInterval(() => syncOrders(), 10000);
    return () => {
      clearTimeout(syncOrdersInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // ORDER SYNC BLOCK END

  const handleCloseModal = () => {
    history.push("/dashboard");
  };

  const confirmDiscardCart = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        className: "parkedItemsClass",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            You can retrieve the bill later by selecting the 'Retrieve' option in Parked Bills.
            <br />
            Do you want to continue parking the bill?
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          parkBill();
          setTimeout(() => {
            history.push("/dashboard");
          }, 700);
        },
        onCancel: () => {
          handleCloseModal();
        },
      });
    } else {
      history.push("/dashboard");
    }
  };

  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [couponInput, setCouponInput] = useState("");

  const closeCouponModal = () => {
    setCouponModalVisible(false);
    setCouponInput("");
  };

  const checkCoupon = async () => {
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    setCouponModalVisible(false);
    const verifyCouponResponse = await Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
          verifyCoupons(couponcode: "${couponInput}") {
            mPricingCouponId
            csClientId
            csBunitId
            created
            createdBy
            updated
            updatedBy
            line
            mPricingRulesId
            couponcode
            status
            redemptionCount
            usedDate
            upc
          }
        }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${setAuthTokens}`,
      },
    });
    const { verifyCoupons } = verifyCouponResponse.data.data;
    if (verifyCoupons.length > 0) {
      if (verifyCoupons[0].status === "A") {
        const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(verifyCoupons[0].mPricingRulesId).toArray();

        if (matchingPricingRules.length > 0) {
          const pricingRule = matchingPricingRules[0];
          if (pricingRule.type === "TD" && pricingRule.iscoupon === "Y") {
            const billCart = { ...cart };
            let discountValue = 0;
            discountValue = pricingRule.amountDiscount !== null ? pricingRule.amountDiscount : (pricingRule.percentageDiscount / 100) * billCart.total;
            /* const roundOffValue = Math.round(billCart.total - discountValue);
            const totalRoundOff = billCart.total - roundOffValue;
            billCart.mPricingruleId = pricingRule.mPricingrulesId;
            billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;
            billCart.roundOff = totalRoundOff;
            billCart.total = roundOffValue;
            billCart.discount += discountValue;
            billCart.mPricingCouponId = verifyCoupons[0].mPricingCouponId;
            billCart.couponRedemptionCount = parseFloat(verifyCoupons[0].redemptionCount) + 1;
            setCart({ ...billCart }); */

            const cartItemsFiltered = billCart.items.filter((i) => i.nextRule === "Y" || i.nextRule === undefined);
            let salePricesTotal = 0;
            for (let i = 0; i < cartItemsFiltered.length; i += 1) {
              salePricesTotal += parseFloat(cartItemsFiltered[i].realPrice);
            }

            billCart.items.map((addedToCart, index) => {
              if (addedToCart.nextRule === "Y" || addedToCart.nextRule === undefined) {
                // const productAmount = billCart.manualDiscountApplied ? addedToCart.realPrice : addedToCart.salePrice;
                const discountAmt = parseFloat((addedToCart.realPrice / salePricesTotal) * discountValue) / addedToCart.weight;
                const sp = parseFloat(addedToCart.realPrice) - discountAmt;
                const mrp = parseFloat(sp) * addedToCart.weight;
                const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
                addedToCart.salePrice = sp;
                addedToCart.taxAmount = tax;
                addedToCart.nettotal = mrp;
                addedToCart.discount = discountAmt * addedToCart.weight;
                addedToCart.discountName = pricingRule.printedName;
                addedToCart.mPricingruleId = pricingRule.mPricingrulesId;
                billCart.items[index] = addedToCart;
              }
            });

            billCart.mPricingruleId = pricingRule.mPricingrulesId;
            billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;

            const addedToCart = billCart.items;

            let totalTax = 0;
            let totalPrice = 0;
            let totalItemsQty = 0;
            let totalDiscounts = 0;
            for (let i = 0; i < addedToCart.length; i += 1) {
              totalPrice += addedToCart[i].nettotal;
              totalItemsQty += addedToCart[i].weight;
              totalTax += addedToCart[i].taxAmount;
              totalDiscounts += addedToCart[i].discount;
              addedToCart[i].key = i;
            }

            const roundOffValue = Math.round(totalPrice);
            const totalRoundOff = totalPrice - roundOffValue;
            totalPrice = roundOffValue;
            const finalCartObj = {
              ...cart,
              items: [...addedToCart],
              total: totalPrice,
              tax: totalTax,
              discount: totalDiscounts,
              totalQty: totalItemsQty,
              roundOff: totalRoundOff,
            }
            setCart(finalCartObj);
            localStorage.setItem("cartObj",JSON.stringify(finalCartObj));
            if (tillLayout === 2) {
              setPaymentModal(true);
            }
            message.success(`Coupon Applied: ${pricingRule.printedName}`);
          }

          if (pricingRule.type === "TDF" && pricingRule.iscoupon === "Y") {
            if (pricingRule.mPricingXProducts.length > 0) {
              if (pricingRule.mPricingXProducts.length === 1) {
                db.products
                  .where("mProductId")
                  .equalsIgnoreCase(pricingRule.mPricingXProducts[0].mProductId)
                  .toArray()
                  .then((product) => {
                    if (product.length > 0) {
                      const obj = { ...product[0] };
                      let batchno = null;
                      let mBatchId = null;
                      let upc = null;
                      if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                        const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[0].mBatchId);
                        batchno = obj.mBatch[bI].batchno;
                        mBatchId = obj.mBatch[bI].mBatchId;
                        upc = obj.mBatch[bI].upc;
                      }
                      if (obj.overRideTax === "Y" && 0 <= obj.overRideCondition) {
                        obj.cTaxId = obj.contraTaxId;
                        obj.taxRate = obj.contraRate;
                      }
                      const productDefined = {
                        batchno: batchno,
                        description: obj.description,
                        discount: 0,
                        discountName: "",
                        imageurl: obj.imageurl,
                        isDecimal: obj.isDecimal,
                        isManualQty: obj.isManualQty,
                        isPromoApplicable: false,
                        isReturn: false,
                        mBatchId: mBatchId,
                        mPricingruleId: null,
                        name: obj.name,
                        name2: obj.name2,
                        nettotal: 0,
                        primaryOrderLine: null,
                        productId: obj.mProductId,
                        realPrice: 0,
                        returnQty: null,
                        salePrice: 0,
                        stock: obj.onhandQty,
                        tax: obj.cTaxId,
                        taxAmount: 0,
                        taxRate: obj.taxRate,
                        uom: obj.csUomId,
                        uom_name: obj.uomName,
                        isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
                        isQtyDesimal: obj.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
                        upc: upc,
                        value: obj.value,
                        weight: 1,
                        shortDescription: obj.shortDescription,
                        hsncode: obj.hsncode,
                        csBunitId: obj.csBunitId,
                        mProductCategoryId: obj.mProductCategoryId,
                        productManufacturerId: obj.productManufacturerId,
                        productBrandId: obj.productBrandId,
                        batchedProduct: obj.batchedProduct,
                        batchedForSale: obj.batchedForSale,
                        batchedForStock: obj.batchedForStock,
                        multiPrice: obj.multiPrice,
                        shelfLife: obj.shelfLife,
                      };
                      addProduct(productDefined, 1);
                      message.success(pricingRule.printedName);
                      if (tillLayout === 2) {
                        setPaymentModal(true);
                      }
                    }
                  });
              } else {
                const offerProductsStack = [];
                for (let i = 0; i < pricingRule.mPricingXProducts.length; i++) {
                  db.products
                    .where("mProductId")
                    .equalsIgnoreCase(pricingRule.mPricingXProducts[i].mProductId)
                    .toArray()
                    .then((product) => {
                      if (product.length > 0) {
                        const obj = { ...product[0] };
                        let batchno = null;
                        let mBatchId = null;
                        let upc = null;
                        if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                          const bI = obj.mBatch.findIndex((p) => p.mBatchId === pricingRule.mPricingXProducts[i].mBatchId);
                          if (bI >= 0) {
                            batchno = obj.mBatch[bI].batchno;
                            mBatchId = obj.mBatch[bI].mBatchId;
                            upc = obj.mBatch[bI].upc;
                          }
                        }
                        if (obj.overRideTax === "Y" && 0 <= obj.overRideCondition) {
                          obj.cTaxId = obj.contraTaxId;
                          obj.taxRate = obj.contraRate;
                        }
                        const productDefined = {
                          batchno: batchno,
                          description: obj.description,
                          discount: 0,
                          discountName: "",
                          imageurl: obj.imageurl,
                          isDecimal: obj.isDecimal,
                          isManualQty: obj.isManualQty,
                          isReturn: false,
                          isPromoApplicable: false,
                          mBatchId: mBatchId,
                          mPricingruleId: null,
                          name: obj.name,
                          name2: obj.name2,
                          nettotal: 0,
                          primaryOrderLine: null,
                          productId: obj.mProductId,
                          realPrice: 0,
                          returnQty: null,
                          salePrice: 0,
                          stock: obj.onhandQty,
                          tax: obj.cTaxId,
                          taxAmount: 0,
                          taxRate: obj.taxRate,
                          uom: obj.csUomId,
                          uom_name: obj.uomName,
                          isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
                          isQtyDesimal: obj.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
                          upc: upc,
                          value: obj.value,
                          weight: 1,
                          shortDescription: obj.shortDescription,
                          hsncode: obj.hsncode,
                          csBunitId: obj.csBunitId,
                          mProductCategoryId: obj.mProductCategoryId,
                          productManufacturerId: obj.productManufacturerId,
                          productBrandId: obj.productBrandId,
                          batchedProduct: obj.batchedProduct,
                          batchedForSale: obj.batchedForSale,
                          batchedForStock: obj.batchedForStock,
                          multiPrice: obj.multiPrice,
                          shelfLife: obj.shelfLife,
                        };
                        offerProductsStack.push(productDefined);
                      }
                    });
                }
                setTimeout(() => {
                  setOfferProductList([...offerProductsStack]);
                  const finalCartObj = {
                    ...cart,
                    mPricingCouponId: verifyCoupons[0].mPricingCouponId,
                    couponRedemptionCount: parseFloat(verifyCoupons[0].redemptionCount) + 1,
                    mPricingrulesId: pricingRule.mPricingrulesId,
                  }
                  setCart(finalCartObj);
                  localStorage.setItem("cartObj",JSON.stringify(finalCartObj));
                  setDisplayOfferProductSelection(true);
                }, 1000);
              }
            }
          }

          if (pricingRule.type === "FD" && pricingRule.iscoupon === "Y") {
            const addedToCart = cart.items;
            const cartItemProductsIndexes = [];
            pricingRule.mPricingXProducts.map((prod) => {
              const cartProductIndex = addedToCart.findIndex((p) => p.productId === prod.mProductId);
              if (cartProductIndex >= 0) {
                cartItemProductsIndexes.push(cartProductIndex);
              }
              return null;
            });

            if (cartItemProductsIndexes.length > 0) {
              cartItemProductsIndexes.map((index) => {
                const discountAmt = pricingRule.amountDiscount;
                const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
                const mrp = parseFloat(sp) * addedToCart[index].weight;
                const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
                addedToCart[index].salePrice = sp;
                addedToCart[index].taxAmount = tax;
                addedToCart[index].nettotal = mrp;
                addedToCart[index].discount = discountAmt * addedToCart[index].weight;
                addedToCart[index].discountName = pricingRule.printedName;
                addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
                message.success(pricingRule.printedName);
                return null;
              });

              let totalTax = 0;
              let totalPrice = 0;
              let totalItemsQty = 0;
              let totalDiscounts = 0;
              for (let i = 0; i < addedToCart.length; i += 1) {
                totalPrice += addedToCart[i].nettotal;
                totalItemsQty += addedToCart[i].weight;
                totalTax += addedToCart[i].taxAmount;
                totalDiscounts += addedToCart[i].discount;
                addedToCart[i].key = i;
              }

              const roundOffValue = Math.round(totalPrice);
              const totalRoundOff = totalPrice - roundOffValue;
              totalPrice = roundOffValue;
              const finalCartObj = {
                ...cart,
                items: [...addedToCart],
                total: totalPrice,
                tax: totalTax,
                discount: totalDiscounts,
                totalQty: totalItemsQty,
                roundOff: totalRoundOff,
                mPricingCouponId: verifyCoupons[0].mPricingCouponId,
                couponRedemptionCount: parseFloat(verifyCoupons[0].redemptionCount) + 1,
              }
              setCart(finalCartObj);
              localStorage.setItem("cartObj",JSON.stringify(finalCartObj));
            }
          }

          if (pricingRule.type === "PD" && pricingRule.iscoupon === "Y") {
            const addedToCart = cart.items;
            const cartItemProductsIndexes = [];
            pricingRule.mPricingXProducts.map((prod) => {
              const cartProductIndex = addedToCart.findIndex((p) => p.productId === prod.mProductId);
              if (cartProductIndex >= 0) {
                cartItemProductsIndexes.push(cartProductIndex);
              }
              return null;
            });
            if (cartItemProductsIndexes.length > 0) {
              cartItemProductsIndexes.map((index) => {
                const discountAmt = (pricingRule.percentageDiscount / 100) * parseFloat(addedToCart[index].realPrice);
                const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
                const mrp = parseFloat(sp) * addedToCart[index].weight;
                const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
                addedToCart[index].salePrice = sp;
                addedToCart[index].taxAmount = tax;
                addedToCart[index].nettotal = mrp;
                addedToCart[index].discount = discountAmt * addedToCart[index].weight;
                addedToCart[index].discountName = pricingRule.printedName;
                addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
                message.success(pricingRule.printedName);
                return null;
              });

              let totalTax = 0;
              let totalPrice = 0;
              let totalItemsQty = 0;
              let totalDiscounts = 0;
              for (let i = 0; i < addedToCart.length; i += 1) {
                totalPrice += addedToCart[i].nettotal;
                totalItemsQty += addedToCart[i].weight;
                totalTax += addedToCart[i].taxAmount;
                totalDiscounts += addedToCart[i].discount;
                addedToCart[i].key = i;
              }

              const roundOffValue = Math.round(totalPrice);
              const totalRoundOff = totalPrice - roundOffValue;
              totalPrice = roundOffValue;
              const finalCartObj = {
                ...cart,
                items: [...addedToCart],
                total: totalPrice,
                tax: totalTax,
                discount: totalDiscounts,
                totalQty: totalItemsQty,
                roundOff: totalRoundOff,
                mPricingCouponId: verifyCoupons[0].mPricingCouponId,
                couponRedemptionCount: parseFloat(verifyCoupons[0].redemptionCount) + 1,
              }
              setCart(finalCartObj);
              localStorage.setItem("cartObj",JSON.stringify(finalCartObj));
            }
          }
        } else {
          message.warning("No offer found for coupon");
        }
      } else {
        message.warning("Coupon Expired");
      }
    } else {
      message.warning("Invalid Coupon");
    }
  };

  // Side Menu Drawer Starts //

  const [sideMenuDrawervisible, setSideMenuDrawervisible] = useState(false);
  const openSideMenu = () => {
    setSideMenuDrawervisible(true);
  };
  const onClose = () => {
    setSideMenuDrawervisible(false);
  };

  const [displayReturnOrderSearch, setDisplayReturnOrderSearch] = useState(false);

  const handleSalesReturnFromSideMenu = () => {
    setSideMenuDrawervisible(false);
    setDisplayReturnOrderSearch(true);
  };

  // Side Menu Drawer Ends //

  // OMS Orders Modal Starts //

  const omsOrderDetailsList = JSON.parse(localStorage.getItem("omsOrderDetails"));
  const initialOmsOrderList = omsOrderDetailsList ? omsOrderDetailsList : [];
  const [omsOrdersList, setOmsOrderList] = useState(initialOmsOrderList);

  const omsOrderStatus = [
    {
      title: "New",
      imgSrc: NewWhite,
      selectedImgSrc: New,
      statusValue: "NE",
    },
    {
      title: "Preparing",
      imgSrc: PreparingWhite,
      selectedImgSrc: Preparing,
      statusValue: "UP",
    },
    {
      title: "Ready",
      imgSrc: ReadyWhite,
      selectedImgSrc: Ready,
      statusValue: "PK",
    },
    {
      title: "Today's Orders",
      imgSrc: CompletedWhite,
      selectedImgSrc: Completed,
      statusValue: "DE",
    },
  ];

  const [displayOMSOrderItemsModal, setDisplayOMSOrderItemsModal] = useState(false);
  const [selectedOMSOrderStatus, setSelectedOMSOrderStatus] = useState(omsOrderStatus[0]);

  const [order, setOrder] = useState("");
  const [searchOrders, setSearchOrders] = useState(omsOrdersList);

  const [selectedOrder, setSelectedOrder] = useState({});

  const handleOmsOrders = () => {
    setSideMenuDrawervisible(false);
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        if (data?.length > 0) {
          data?.map((item) => {
            let time = new Date(item.orderTime);
            let newTime = time.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            let newLineItems = item?.items?.map((itemLine) => ({
              ...itemLine,
              price: itemLine?.salePrice || 0,
              quantity: itemLine?.weight || 0,
            }));
            let orderObj = {
              cWCOrderId: item.sOrderID || "",
              customerId: item?.customer?.cwrCustomerId || "",
              customerName: item?.customer?.name || "",
              lineItems: [...newLineItems],
              mobileNo: item?.customer?.mobileNo,
              noOfItems: item?.items?.length,
              orderNo: item.documentno,
              posOrders: "Y",
              status: "NE",
              total: item?.total || 0,
              totalQty: item?.totalQty || 0,
              dateCreated: newTime,
            };
            let findIndexOrder = _.findIndex(omsOrdersList, ["cWCOrderId", orderObj.cWCOrderId]);
            if (findIndexOrder === -1) {
              omsOrdersList.push(orderObj);
            } else {
              omsOrdersList[findIndexOrder] = orderObj;
            }
            return null;
          });
        }
      });
    const paramsInput = {
      query: `query{
        getNewOmsOrders(bunitId:"${tillData.tillAccess.csBunit.csBunitId}"){
        cWCOrderId
        orderNo
        dateCreated
        customerId
        customerName
        mobileNo
        total
        noOfItems
        totalQty
        lineItems{
            productId
            name
            productCode
            quantity
            price
            subTotal
            addOnProducts{
                id
                name
                price
            }
        }
    }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.access_token}`,
      },
    }).then((response) => {
      const { getNewOmsOrders } = response.data.data;
      getNewOmsOrders?.map((item, index) => {
        let obj = { ...item };
        obj.status = "NE";
        obj.noOfItems = item.lineItems.length;
        obj.posOrders = "N";
        let time = new Date(item.dateCreated);
        let newTime = time.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        obj.dateCreated = newTime;
        let findIndex = _.findIndex(omsOrdersList, ["cWCOrderId", item.cWCOrderId]);
        if (findIndex === -1) {
          omsOrdersList.push(obj);
        } else {
          omsOrdersList[findIndex] = obj;
          let prodCode = _.map(omsOrdersList?.[findIndex]?.lineItems || [], "productCode");
          db.products
            .where("value")
            .startsWithAnyOfIgnoreCase(prodCode)
            .toArray()
            .then((productsFetched) => {
              if (productsFetched?.length > 0) {
                let newLineItems = [...omsOrdersList?.[findIndex]?.lineItems].map((itemLine) => {
                  let itemImageIndex = _.findIndex(productsFetched, (item) => item.value === itemLine.productCode);
                  return itemImageIndex >= 0
                    ? {
                        ...itemLine,
                        img: productsFetched[itemImageIndex]?.imageurl,
                      }
                    : { ...itemLine };
                });
                omsOrdersList[findIndex].lineItems = [...newLineItems];
              }
            });
        }
        return null;
      });
      localStorage.setItem("omsOrderDetails", JSON.stringify([...omsOrdersList]));
      setOmsOrderList([...omsOrdersList]);
    });
    setDisplayOMSOrderItemsModal(true);
  };

  const handleOMSOrderStatusSelection = (record) => {
    setSelectedOMSOrderStatus(record);
  };

  const setOMSStatus = (status) => {
    let newOMSStatus = "";
    switch (status) {
      case "NE":
        newOMSStatus = "UP";
        break;
      case "UP":
        newOMSStatus = "PK";
        break;
      case "PK":
        newOMSStatus = "DE";
        break;
      default:
        newOMSStatus = "NE";
    }
    return newOMSStatus;
  };

  const nextOMSOrderStatus = () => {
    let newOMSOrderStatus = [...omsOrderStatus];
    let statusIndex = _.findIndex(newOMSOrderStatus, (item) => item.title === selectedOMSOrderStatus.title);
    if (statusIndex <= newOMSOrderStatus.length - 1) setSelectedOMSOrderStatus(newOMSOrderStatus[statusIndex + 1]);
  };

  const handleOMSStatusButton = (record) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const paramsInput = {
      query: `mutation{
        updateOMSOrderStatus(order:{
            cWCOrderId: "${record.cWCOrderId}"
            status: "${setOMSStatus(record.status)}"
        })
        {
            status
            message
        }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.data.data.updateOMSOrderStatus.status === "200") {
        let newOMSOrderDetails = [...omsOrdersList];
        let recordIndex = _.findIndex(newOMSOrderDetails, ["cWCOrderId", record.cWCOrderId]);
        newOMSOrderDetails[recordIndex].status = setOMSStatus(record.status);
        localStorage.setItem("omsOrderDetails", JSON.stringify(omsOrdersList));
        setOmsOrderList([...newOMSOrderDetails]);
        nextOMSOrderStatus();
      }
    });
  };

  const handleOrderCard = (record) => {
    setSelectedOrder(record);
    handleOMSOrderStatusSelection(_.filter(omsOrderStatus, (statusItem) => statusItem.statusValue === record.status)?.[0]);
  };

  const handleOrderSearchInput = (value) => {
    if (value !== "") {
      const results = omsOrdersList.filter((orderDetails) => {
        return orderDetails.orderNo.startsWith(value);
      });
      setSearchOrders(results);
    } else {
      setSearchOrders(omsOrdersList);
    }
    setOrder(value);
  };

  const omsOrderTotalPrice = _.sumBy(selectedOrder.lineItems, "price");

  // OMS Orders Modal Ends //

  // Sales Representative Modal Starts //

  const [salesRepModalOpen, setSalesRepModalOpen] = useState({
    status: false,
    title: "",
  });
  const [salesRepresent, setSalesRepresent] = useState({});
  const [salesRepresentDefaultLine, setSalesRepresentDefaultLine] = useState({ salesRepresentId: null, name: null });

  const handleSalesRepresentive = (record) => {
    if (salesRepModalOpen.title === "orderSalesRep") {
      setSalesRepresentDefaultLine({ ...record });
      setCart({
        ...cart,
        salesRepId: record.salesRepresentId,
      });
      localStorage.setItem("cartObj",JSON.stringify({
        ...cart,
        salesRepId: record.salesRepresentId,
      }));
      setSalesRepresent(record);
    } else if (salesRepModalOpen.title === "itemSalesRep" && !_.isEmpty(selectedProductInCart)) {
      setSalesRepresentDefaultLine({ ...record });
      let lineItemsData = [...cart.items];
      const lineItemsIndex = _.findIndex(lineItemsData, (item) => item.productId === selectedProductInCart.productId);
      lineItemsData[lineItemsIndex]["salesRepId"] = record.salesRepresentId;
      lineItemsData[lineItemsIndex]["salesRepName"] = record.name;

      setCart({
        ...cart,
        items: lineItemsData,
      });
      localStorage.setItem("cartObj",JSON.stringify({
        ...cart,
        items: lineItemsData,
      }));
    }
    setSalesRepModalOpen({ status: false, title: "" });
  };

  const [salesRepresentSearchInput, setSalesRepresentSearchInput] = useState("");
  const salesReprestiveList = tillData.tillAccess.csBunit.salesRep;
  const [filteredSalesRepresentList, setFilteredSalesRepresentList] = useState(salesReprestiveList);

  const handleSalesRepresentSearchInput = (value) => {
    if (value !== "") {
      const results = salesReprestiveList.filter((list) => {
        return list.name.toLowerCase().startsWith(value.toLowerCase());
      });
      setFilteredSalesRepresentList(results);
    } else {
      setFilteredSalesRepresentList(salesReprestiveList);
    }
    setSalesRepresentSearchInput(value);
  };

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey) {
      if (keyCode === 83) {
        if (posConfig.showLineSalesRep === "Y") {
          setSalesRepModalOpen({ status: true, title: "itemSalesRep" });
          setSalesRepresentSearchInput("");
          setFilteredSalesRepresentList(salesReprestiveList);
        }
      }
      if (keyCode === 67) {
        setSelectedRowKeys((v) => {
          if (v.length > 0) {
            return [];
          } else {
            return [0];
          }
        });
      }
      if (keyCode === 80) {
        openPaymentModal();
      }

      if (keyCode === 79) {
        setDisplayOrderHistory(true);
      }

      if (keyCode === 66) {
        setDisplayParkedBillModal(true);
      }

      if (keyCode === 73) {
        if (selectedRowKeysRef.current.length > 0) {
          setIsQtyUpdate(selectedProductInCartRef.current);
        }
      }

      if (keyCode === 76) {
        parkBill("parkKey");
      }

      if (keyCode === 72) {
        confirmDiscardCart();
      }
    }

    if (keyCode === 115) {
      setTimeout(() => {
        productSearchInputRef.current.focus();
      }, 100);
    }
    if (keyCode === 27) {
      if ((e.target.id === "productSearchInputId" || e.target.id.search("productCardItem")) >= 0) {
        closeProductPanel();
      }
      setDisplayBatchSelection((b) => {
        if (b) {
          return !b;
        } else {
          return b;
        }
      });
      setSalesRepModalOpen({ status: false, title: "" });
      setSalesRepresentSearchInput("");
      closeCustomerSearch();

      if (paymentModalStateRef.current) {
        closePaymentModal();
      }
    }
    if (keyCode === 38) {
      setSelectedRowKeys((v) => {
        if (v.length > 0 && v[0] > 0) {
          return [v[0] - 1];
        } else {
          return [v[0]];
        }
      });
    }
    if (keyCode === 40) {
      setSelectedRowKeys((v) => {
        if (v.length > 0 && v[0] < cartItemsLengthRef.current - 1) {
          return [v[0] + 1];
        } else {
          return [v[0]];
        }
      });
    }

    if (keyCode === 187 || keyCode === 107) {
      if (selectedRowKeysRef.current.length > 0) {
        increaseProductQty(selectedProductInCartRef.current);
      }
    }
    if (keyCode === 109 || keyCode === 189) {
      if (selectedRowKeysRef.current.length > 0) {
        if (parseFloat(selectedProductInCartRef.current.weight) > 1) {
          decreaseProductQty(selectedProductInCartRef.current);
        }
      }
    }
    if (keyCode === 46) {
      if (selectedRowKeysRef.current.length > 0) {
        deleteProduct(selectedProductInCartRef.current);
      }
    }
  };

  const cartItemsLengthRef = useRef(0);
  const [cartObj,setOrderObj] = useState(null);

  useEffect(() => {
    cartItemsLengthRef.current = cart.items.length;
    setAmount((Math.abs(cart.total) - cart.paid).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    let flag = tillLayout !== 1 ? true : false;
    if (Math.abs(cart.total) <= cart.paid && cart.payments.length > 0 && flag) {
      orderState.current = 0;
      setPaymentProcessFlag(false);
      processOrder();
    }
    
    let newOrderObj = JSON.parse(localStorage.getItem("cartObj")) !== null ? JSON.parse(localStorage.getItem("cartObj")) : cart
    setOrderObj(newOrderObj);
  }, [cart]);

  const selectedProductInCartRef = useRef({});
  useEffect(() => {
    selectedProductInCartRef.current = selectedProductInCart;
  }, [selectedProductInCart]);

  const selectedRowKeysRef = useRef([0]);
  useEffect(() => {
    selectedRowKeysRef.current = selectedRowKeys;
    if (selectedRowKeys.length > 0) {
      const cartIndex = cart.items.findIndex((ci) => ci.key === selectedRowKeys[0]);
      // setSelectedProductInCart({ ...cart.items[cartIndex] });
    }
  }, [selectedRowKeys]);

  const paymentModalStateRef = useRef(false);
  useEffect(() => {
    paymentModalStateRef.current = paymentModal;
    if (paymentModal) {
      setSelectedPaymentMethod(tillDataPaymentMethods.filter((item, index) => item.isDefault === "Y")?.[0] || {});
    }
  }, [paymentModal]);

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  });

  // Sales Representative Modal Ends //

  const [manualDiscountModalVisible, setManualDiscountModalVisible] = useState(false);
  const [manualDiscountInput, setManualDiscountInput] = useState("");
  const [manualDiscountTypes, setManualDiscountTypes] = useState([]);
  const [selectedManualDiscountType, setSelectedManualDiscountTypeValue] = useState("");
  const [enableManualDiscountInput, setEnableManualDiscountInput] = useState(false);

  const handleManualDiscountKeyPress = (value) => {
    if (!enableManualDiscountInput) {
      if (manualDiscountInput === "" && value === "x") {
        setManualDiscountInput("");
      } else if (value === "x") {
        setManualDiscountInput(`${manualDiscountInput.toString().substring(0, manualDiscountInput.toString().length - 1)}`);
      } else {
        setManualDiscountInput(`${manualDiscountInput}${value}`);
      }
    }
  };

  useEffect(() => {
    db.pricingRules.toArray().then((pr) => {
      const manualPricingRules = pr.filter((rule) => rule.manualDiscount === "Y");
      setManualDiscountTypes([...manualPricingRules]);
    });
  }, []);

  const setSelectedManualDiscountType = (value) => {
    setSelectedManualDiscountTypeValue(value);
    if (value !== "") {
      const mdi = manualDiscountTypes.findIndex((md) => md.mPricingrulesId === value);
      const discountValue = manualDiscountTypes[mdi].amountDiscount ? manualDiscountTypes[mdi].amountDiscount : manualDiscountTypes[mdi].percentageDiscount;
      if (discountValue) {
        setManualDiscountInput(discountValue);
        setEnableManualDiscountInput(true);
      } else {
        setManualDiscountInput("");
        setEnableManualDiscountInput(false);
      }
    }
  };

  const applyManualDiscount = () => {
    if (selectedManualDiscountType && manualDiscountInput && cart.items.length > 0) {
      setManualDiscountModalVisible(false);
      if (selectedRowKeys.length > 0) {
        // Process line discount
        processLineManualDiscount();
      } else {
        // Process a total discount
        const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === selectedManualDiscountType)];
        if (selectedManualDiscountType === "F797B4AEB15149B1AC0A3E2CA39EB97A" && pricingRule.foc === "N") {
          processTotalManualDiscount();
        } else if (selectedManualDiscountType !== "F797B4AEB15149B1AC0A3E2CA39EB97A") {
          processTotalManualDiscount();
        }
      }
    } else {
      message.warning("Please provide a valid input !");
    }
  };

  const processLineManualDiscount = () => {
    const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === selectedManualDiscountType)];
    if (pricingRule.type === "FD") {
      CheckoutFlatDiscount(cart, setCart, selectedRowKeys, pricingRule, manualDiscountInput, tillaccess);
    }

    if (pricingRule.type === "PD") {
      CheckoutPercentageDiscount(cart, setCart, selectedRowKeys, pricingRule, manualDiscountInput, tillaccess);
    }
    setSelectedRowKeys([]);
  };

  const processTotalManualDiscount = (discountParam) => {
    CheckoutTotalManualDiscount(discountParam, setCart, cart, manualDiscountTypes, selectedManualDiscountType, manualDiscountInput, tillaccess);
  };

  const removeAllDiscounts = () => {
    setManualDiscountModalVisible(false);
    setManualDiscountInput("");
    setSelectedManualDiscountTypeValue("");
    const cartItems = cart.items;

    cartItems.forEach((addedToCart, i) => {
      const sp = parseFloat(addedToCart.realPrice);
      const mrp = parseFloat(sp) * addedToCart.weight;
      const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
      addedToCart.salePrice = sp;
      addedToCart.taxAmount = tax;
      addedToCart.nettotal = mrp;
      addedToCart.discount = 0;
      cartItems[i] = addedToCart;
    });

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;

    for (let i = 0; i < cartItems.length; i += 1) {
      totalPrice += cartItems[i].nettotal;
      totalItemsQty += cartItems[i].weight;
      totalTax += cartItems[i].taxAmount;
      totalDiscounts += cartItems[i].discount;
      cartItems[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;

    delete cart["manualDiscountApplied"];

    if (tillaccess?.layout === "2" && localStorage.getItem("dineIn") === "Y") {
      let obj;
      const fbOrderData = JSON.parse(localStorage.getItem("tableName"));
      db.fbOrderData
        .where("cwrFbTableId")
        .equals(fbOrderData?.cwrFbTableId)
        .toArray()
        .then((ordersFetched) => {
          if (ordersFetched.length > 0) {
            ordersFetched.map(async (fbOrder) => {
              if (fbOrder.fbOrderStatus === "IP") {
                let orderLines = [];
                fbOrder.cart = {
                  ...cart,
                  items: [...cartItems],
                  total: totalPrice,
                  tax: totalTax,
                  discount: totalDiscounts,
                  totalQty: totalItemsQty,
                  roundOff: totalRoundOff,
                };
                fbOrder.fbOrderSync = "N";
                fbOrder.lines = orderLines;
                cartItems.map((obj) => {
                  orderLines.push(`{
                  fbOrderId: "${fbOrder.fbOrderId}"
                  fbOrderLineId: "${obj.fbOrderLineId}"
                  mPoductId: "${obj.productId}"
                  mBatchId: null
                  description: "good"
                  csUomId: "${obj.uom}"
                  csTaxId: "${obj.tax}"
                  discount:${obj.discount}
                  line: 1
                  qty: ${obj.weight}
                  unitPrice: ${obj.realPrice}
                  listPrice: 30
                  lineNet: 2.6
                  lineTax: ${obj.taxRate}
                  lineGross: 30
                  sOrderLineId: null
                  isOrdered: "Y"
                  meta:[]
                  }`);
                });
                await db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
                obj = {
                  fbOrder: {
                    fbOrderId: fbOrder.fbOrderId,
                    order: fbOrder,
                  },
                };
                sendOrder(obj);
              }
            });
          }
        });
      SyncData(fbOrderData, "upsertFbOrder");
    }
    const finalCartObj = {
      ...cart,
      items: [...cartItems],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    }
    setCart(finalCartObj);
    localStorage.setItem("cartObj",JSON.stringify(finalCartObj));
  };

  const clearSelectedProductInCart = () => {
    setSelectedProductInCart({});
    // setTimeout(()=>{
    setSelectedRowKeys([]);
    // },100)
  };

  const posLogActivity = (record, activity) => {
    const timeMark = timeStamp();
    const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
    let products = "";
    if (_.isArray(record)) {
      record.forEach((item) => {
        products += `SKU: ${item.value}, Qty: ${item.weight}, Unit Price: ${item.salePrice}, `;
      });
    } else {
      products = `SKU: ${record.value}, Qty: ${record.weight}, Unit Price: ${record.salePrice}, `;
    }

    db.logInformation.add({
      type: activity,
      action: "LOG",
      description: activity === "DLN" || activity === "DOR" || activity === "RQT" || activity === "SLR" ? products : activity === "SRD" || activity === "ACT" ? record : "",
      date: currentDate,
      time: timeMark,
      orderNo: "",
      remarks: "",
    });
  };

  // Paytm QR Code Starts //

  const [paytmQrCodeModalOpens, setPaytmQrCodeModalOpens] = useState(false);
  const [qrCodeResponse, setQrCodeResponse] = useState({});

  const processQrPaytmPayment = (amountAdded, currentPaymentMethod, paymentMethod, value) => {
    setPaytmQrCodeModalOpens(true);
    let hostUrl = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
    let paytmUrl = `${hostUrl}paytm/generateQRCode`;
    const getQRCodeValues = {
      midId: "Excelo34085435005810",
      orderId: `${cart.sOrderID}`,
      amount: amountAdded,
      businessType: "UPI_QR_CODE",
      posId: `${tillData.tillAccess.cwrTill.cwrTillID}`,
      merchantKey: "qQUxrwRx@qE6zTxt",
      payTMQRUrl: "https://securegw-stage.paytm.in/paymentservices/qr/create",
      clientId: "C11",
      version: "v1",
    };
    Axios({
      url: paytmUrl,
      method: "POST",
      data: getQRCodeValues,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${tokens.access_token}`,
      },
    }).then((response) => {
      setQrCodeResponse(response.data);
    });
  };

  const handleVerifyPayment = () => {
    let hostUrl = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
    let verifyPaytmUrl = `${hostUrl}paytm/verifyPayment`;
    const getPaymentSuccessValues = {
      midId: "Excelo34085435005810",
      orderId: `${cart.sOrderID}`,
      merchantKey: "qQUxrwRx@qE6zTxt",
      payUrlForVerify: "https://securegw-stage.paytm.in/v3/order/status",
      clientId: "C11",
      version: "v1",
    };
    Axios({
      url: verifyPaytmUrl,
      method: "POST",
      data: getPaymentSuccessValues,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.data.body.resultInfo.resultStatus === "TXN_SUCCESS") {
        setPaytmQrCodeModalOpens(false);
        processPayment(selectedPaymentMethod, amount);
      }
    });
  };

  // Paytm QR Code Ends //

  const removeCutomer = () => {
    let cartObj = {
      ...cart,
      customer: defaultCustomer,
    };
    localStorage.setItem("cartObj",JSON.stringify(cartObj));
    setCart(cartObj);
  };

  // Bill Management //

  const handleManagement = () => {
    // history.push("/bill-management");
    setManagementScreenShow(true);
  };

  const [managementScreenShow, setManagementScreenShow] = useState(false);
  const [orderTypeSelection, setOrderTypeSelection] = useState("Dine In");

  const componentProps = {
    checkIsManualWeight,
    parkBill,
    openPaymentModal,
    addProduct,
    cart,
    setCart,
    cartObj,
    setOrderObj,
    clearProductSearchResults,
    closeProductPanel,
    confirmDiscardCart,
    decreaseProductQty,
    deleteCart,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    setIsProductsVisible,
    isProductsVisible,
    getSearchedItem,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    onBarcodeInput,
    openDisplayParkedBillModal,
    orderType,
    parkedList,
    productCategories,
    productBrands,
    setAllProductCategories,
    allProductCategories,
    handleBrandCheckboxChange,
    selectCategotyList,
    setSelectCategotyList,
    handleCategoryCheckboxChange,
    setSelectedProductBrand,
    selectedProductBrand,
    productSearchInput,
    productSearchInputRef,
    productsList,
    setProductsList,
    productsCopy,
    selectProductCategory,
    selectProductInCart,
    selectedProductQty,
    selectProduct,
    selectSalseProduct,
    selectedProductInCart,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedKeys,
    setDisplayCustomerSearch,
    setDisplayOrderType,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    setParkedBillSearchInput,
    showOrderHistory,
    tillData,
    displayCustomerSearch,
    closeCustomerSearch,
    setCustomerSearchType,
    customerSearchType,
    handleCustomerSearch,
    customerSearchInput,
    setCustomerSearchResults,
    setCloseCustomerFlag,
    setCustomerSearchInput,
    customerSearchResults,
    selectCustomer,
    showEditOldCustomerFields,
    showAddNewCustomerFields,
    setDisplayAddNewCustomer,
    displayAddNewCustomer,
    form,
    addNewCustomer,
    setDisplayEditOldCustomer,
    displayEditOldCustomer,
    editFlag,
    setEditFlag,
    editOldCustomer,
    displayOrderHistory,
    setDisplayOrderHistory,
    changeOrderHistorySearchType,
    searchOrderHistory,
    setOrderHistoryInput,
    orderHistoryDetails,
    showOrderHistoryLine,
    selectedOrderHistoryLine,
    isPrintModeXML,
    syncOrders,
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    searchParkedBill,
    parkedBillSearchInput,
    salesHistoryCustomerSearchInput,
    salesHistoryDocumentNoSearchInput,
    setSalesHistoryCustomerSearchInput,
    setSalesHistoryDocumentNoSearchInput,
    filterdParkedList,
    discardParkedBill,
    selectParkedBill,
    paymentModal,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    requestPayment,
    setDenaminationsKeyboard,
    denaminationsKeyboard,
    setCouponModalVisible,
    closePaymentModal,
    paymentModalLoyalityMessages,
    amount,
    onChangeAmount,
    processOrder,
    handleAmount,
    handleCashPayment,
    overPayedAmount,
    setOverPayedAmount,
    handleTotalQty,
    enterTotalQty,
    showPaymentMethods,
    setShowPaymentMethods,
    onChangeTotalQuantity,
    displaySetOrderType,
    posSaleTypes,
    changeOrderType,
    displayOfferProductSelectiton,
    offerProductsList,
    selectOfferProduct,
    displayBatchSelection,
    setDisplayBatchSelection,
    batchSetAvailable,
    selectProductToCart,
    displayManualQtyWeightInput,
    setDisplayManualQtyWeightInput,
    setDefaultImage,
    currentWeightSelectedProduct,
    productWeightModalInput,
    onProductModalChangeWeight,
    addManualWeightToProduct,
    couponModalVisible,
    closeCouponModal,
    checkCoupon,
    setCouponInput,
    loyalityOtpModalVisible,
    setLoyalityOtpModalVisible,
    processOtpInput,
    loyaltyPaymentOtp,
    setLoyaltyPaymentOtp,
    currencyType,
    setCurrencyType,
    setDisplayClock,
    pickProduct,
    addDefinedProduct,
    handleWeightManual,
    selectedProductCategory,
    filterDrawer,
    setFilterDrawer,
    // OMS Order MOdal and Side menu drawer//,
    openSideMenu,
    onClose,
    displayOMSOrderItemsModal,
    omsOrderStatus,
    selectedOMSOrderStatus,
    setSelectedOrder,
    handleOMSOrderStatusSelection,
    order,
    handleOrderSearchInput,
    searchOrders,
    selectedOrder,
    handleOrderCard,
    handleOmsOrders,
    setDisplayOMSOrderItemsModal,
    handleOMSStatusButton,
    omsOrderTotalPrice,
    sideMenuDrawervisible,
    setDisplayOfferProductSelection,
    // Sales Representative Modal //
    salesRepModalOpen,
    setSalesRepModalOpen,
    handleSalesRepresentive,
    salesRepresent,
    setSalesRepresent,
    salesRepresentSearchInput,
    setSalesRepresentSearchInput,
    handleSalesRepresentSearchInput,
    salesReprestiveList,
    filteredSalesRepresentList,
    setFilteredSalesRepresentList,
    prevProductsListRef,
    manualDiscountModalVisible,
    setManualDiscountModalVisible,
    manualDiscountInput,
    setManualDiscountInput,
    manualDiscountTypes,
    setManualDiscountTypes,
    handleManualDiscountKeyPress,
    applyManualDiscount,
    selectedManualDiscountType,
    setSelectedManualDiscountType,
    enableManualDiscountInput,
    setEnableManualDiscountInput,
    removeAllDiscounts,
    clearSelectedProductInCart,
    setProductWeightModalInput,
    productListCardRef,
    paymentModalByCustomerState,
    posLogActivity,
    // Paytm QR Code //
    paytmQrCodeModalOpens,
    setPaytmQrCodeModalOpens,
    qrCodeResponse,
    setQrCodeResponse,
    handleVerifyPayment,
    removeCutomer,
    posConfig,
    displayReturnOrderSearch,
    setDisplayReturnOrderSearch,
    handleSalesReturnFromSideMenu,
    // Bill Management //
    handleManagement,
    managementScreenShow,
    setManagementScreenShow,
    orderTypeSelection,
    setOrderTypeSelection,

    // Cash Management
    setAddCashFlag,
    addCashFlag,
    paymentModal,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    requestPayment,
    setCouponModalVisible,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    setSelectedKeys,

    // payment
    setAmount,
    setNumb,
    amount,
    onChangeAmount,
    openDrawer,
    processOrder,
    paymentModalLoyalityMessages,
    paymentModalInputRef,
    quantityInputRef,
    // Cash Management
    setCashAddInFlag,
    cashAddInFlag,
    handleCahInOut,
    cashManagementForm,
    cashIn,
    pettCashIn,
    onChangeCheckbox,
    setSelectedProductInCart,
    // kiosk Screen
    kioskUI,
    setKioskUI,
    kioskLogin,
    layoutType,
    setLayoutType,
    kioskFilteredProducts,
    // gift card
    giftCardFlag,
    setGiftCardFlag,
    // keybord
    setKeyboardType,
    keyboardType,
    layout,
    setLayout,
    inputName,
    setInputName,
    keyboardParkbill,
    keyboardProduct,
    handleKeyboardInput,
    handleKeyPress,
    orderHistorySearchInputRef,
    orderHistoryInput,
    setLoader,
    openPaymentModalByCustomer,
    paymentProcessFlag,
    setPaymentProcessFlag,
    selectedEditOldCustomer,
    setIsInputFocused,
    isInputFocused,
    handleSelectProduct,
    getCategoryProducts,
    setSalesHistoryType,
    salesHistoryType,
    setStartRowData,
    startRowData,
    setOrderHistoryDetails,
    setOrdersCopy,
    ordersCopy,
  };

  return (
    <div>
      <RenderComponent {...componentProps} />
      <CoreModals {...componentProps} />
      <ReturnBill {...componentProps} />
    </div>
  );
};
// PointOfSale Component End

export default PointOfsaleCore;
