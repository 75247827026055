import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Input, Divider, Table, Empty, Modal, Button } from "antd";
import { InfoCircleFilled, PlusSquareOutlined, MinusSquareOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import Quantity from "../../../assets/images/weigher.png";
import Sales from "../../../assets/images/sales.png";
import Offers from "../../../assets/images/offers.png";
import Stock from "../../../assets/images/stock-white.png";
import Info from "../../../assets/images/Info.png";
import Aclose from "../../../assets/images/close.png";
import ok from "../../../assets/images/tick.svg";
import Backspace from "../../../assets/images/backspac.png";
import DefaultProductImage from "../../../assets/images/no-image.svg";
import "../../style.css";
import "./styles.css";
import AspectRatio from "react-aspect-ratio";
import DiscountImg from "../../../assets/images/discountQty.png";
import StockImg2 from "../../../assets/images/home.png";
import DownArrowGreen from "../../../assets/images/downArrowGreen.svg";
import DownArrowRed from "../../../assets/images/downArrowRed.svg";
import UpArrowGreen from "../../../assets/images/upArrowGreen.svg";
import CircleCloseIcon from "../../../assets/images/circle-close-icon.svg";
import data from "../../../constants/retailPos.json";
import Axios from "axios";
// import { serverUrl } from "../../../constants/config";
import _ from "lodash";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";

const QuantityUpdate = (props) => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const { selectedProductInCart, addProduct, setIsQtyUpdate, isQtyUpdate, decreaseProductQty, increaseProductQty, removeProductModal, setIsSearchProducts } = props;
  let cleanToken;
  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };
  const [weight, setWeight] = useState(selectedProductInCart.weight);
  const [weightFlag, setWeightFlag] = useState(false);
  const onChangeWeight = (event) => {
    // setWeight(event.target.value);
    setWeightFlag(true);
    setWeight(weight + 1);
  };

  const handleChange = () => {
    setWeightFlag(true);
    if (weight > 0) {
      setWeight(weight - 1);
    }
  };

  const handleWeight = (value) => {
    if (weight === "" && value === "x") {
      setWeight("");
    } else if (value === "x") {
      setWeight(`${weight.toString().substring(0, weight.toString().length - 1)}`);
    } else {
      setWeight(`${weight}${value}`);
    }
  };

  const selectedProductInCartRef = useRef({});
  useEffect(() => {
    selectedProductInCartRef.current = selectedProductInCart;
    setWeight(selectedProductInCart.weight)
  }, [selectedProductInCart]);

  const weightRef = useRef("");
  useEffect(() => {
    const authHeaders = getOAuthHeaders();
    if (authHeaders) {
      cleanToken = authHeaders.access_token;
    }
    weightRef.current = weight;
  }, [weight]);

  const addQuantity = (value) => {
    let newWeight = value === "keycodeCal" ? weightRef.current : weight;
    let newSelectedProduct = value === "keycodeCal" ? selectedProductInCartRef.current : selectedProductInCart;
    const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
    if (validation.test(parseFloat(newWeight))) {
      const netWeight = parseFloat(newWeight) - parseFloat(newSelectedProduct.weight);
      if (!newSelectedProduct.isReturn) {
        // addProduct(newSelectedProduct, netWeight);
      }
      setIsQtyUpdate(false);
      setIsSearchProducts(true);
    } else {
      console.warn("Invalid input", weight);
    }
  };

  const infoCardRef = useRef();

  // const focusFirstElement = () => {
  //   setTimeout(() => {
  //     infoCardRef.current.focus();
  //   }, 100);
  // };

  // useEffect(() => {
  //   focusFirstElement();
  // }, []);

  const handleKey = (e) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      addQuantity("keycodeCal");
    }

    if (keyCode === 27) {
      setIsQtyUpdate(false);
      setIsSearchProducts(true);
    }

    if (keyCode === 39) {
      infoCardRef.current.focus();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const full = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  };

  const four = {
    xs: { span: 4 },
    sm: { span: 4 },
    md: { span: 4 },
    lg: { span: 4 },
    xl: { span: 4 },
    xxl: { span: 4 },
  };

  const nine = {
    xs: { span: 9 },
    sm: { span: 9 },
    md: { span: 9 },
    lg: { span: 9 },
    xl: { span: 9 },
    xxl: { span: 9 },
  };

  const half = {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 12 },
  };

  const ten = {
    xs: { span: 10 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 10 },
    xl: { span: 10 },
    xxl: { span: 10 },
  };

  const fourteen = {
    xs: { span: 14 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 14 },
    xl: { span: 14 },
    xxl: { span: 14 },
  };

  const twenty = {
    xs: { span: 20 },
    sm: { span: 20 },
    md: { span: 20 },
    lg: { span: 20 },
    xl: { span: 20 },
    xxl: { span: 20 },
  };

  const [offersList, setOffersList] = useState([]);

  const [stockList, setStockList] = useState({ stores: [], qty: 0 });

  const [salesInfo, setSalesInfo] = useState({});

  const [selectedOption, setSelectedOption] = useState("Quantity");

  const productInfoData = [
    {
      title: "Quantity",
      imgSrc: Quantity,
    },
    {
      title: "Offers",
      imgSrc: Offers,
    },
    {
      title: "Stock",
      imgSrc: Stock,
    },
    {
      title: "Sales",
      imgSrc: Sales,
    },
    {
      title: "Info",
      imgSrc: Info,
    },
  ];

  const handleProductInfo = (record) => {
    setSelectedOption(record);
  };

  const manualWeightInputRef = useRef();
  // useEffect(() => {
  //   setTimeout(() => {
  //     manualWeightInputRef?.current.focus();
  //   }, 100);
  // }, []);

  // Product Info Services //

  useEffect(() => {
    const offersInputValues = {
      query: `query{
        getProductOffers(productId:"${selectedProductInCart.productId}"){
          name
        }  
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: offersInputValues,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    }).then((response) => {
      const { getProductOffers } = response.data.data;
      console.log(getProductOffers, "getProductOffers");
      setOffersList([...getProductOffers]);
    });
  }, []);

  useEffect(() => {
    const stockInputValues = {
      query: `query{     getProductsNearbyStock(bUnitId:"${tillData.tillAccess.csBunit.csBunitId}" code:"${selectedProductInCart.value}"){         csBunitId         value         name    totalQty     stores{             csNearbyStoreId             name             nearbybUnitId             viewStock             isIssue             warehouse{                 mWarehouseID                 name                 value                 storageBin{                     mStoragebinID                     name                     stocks{                         mStockID                         qtyonhand                         product{                             mProductId                             value                             name                             upc                             uom{                                 csUomId                                 name                             }                             productCategory{                                 mProductCategoryId                                 value                                 name                             }                         }                         batch{                             mBatchId                             batchno                             upc                             price                             startdate                             enddate                             life                         }                      }                     totalQty                 }             }         }     } }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: stockInputValues,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    }).then((response) => {
      console.log("response", response);
      const { stores } = response.data.data.getProductsNearbyStock;
      console.log(stores, "getProductsNearbyStock");
      setStockList({ stores: [...stores], qty: response.data.data.getProductsNearbyStock.totalQty });
    });
  }, []);

  useEffect(() => {
    const salesInputValues = {
      query: `query{     getProductSales(bUnitId:"${tillData.tillAccess.csBunit.csBunitId}", code:"${selectedProductInCart.value}"){         cSBunitID         cSClientID         product{             mProductId             value             name             upc             csUomId             uomName             mProductCategoryId             weekSale             weekSaleQty             twoweekSale             twoWeekSaleQty             fourWeekSale             fourWeekSaleQty             yearSale             yearSaleQty           }     } }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: salesInputValues,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    }).then((response) => {
      console.log("response", response);
      const { getProductSales } = response.data.data;
      console.log(getProductSales, "getProductSales");
      setSalesInfo({ ...getProductSales });
    });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {tillLayout === 3 ? (
        <Modal
          visible={isQtyUpdate}
          closable={true}
          onCancel={() => {
            setIsQtyUpdate(false);
            setIsSearchProducts(true);
          }}
          width="61%"
          bodyStyle={{ padding: 0, paddingBottom: "2vh" }}
          footer={
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "3vh", paddingBottom: "1vh" }}>
              <Button
                style={{ background: "white", color: "#EEC302", borderColor: "#EEC302", textAlign: "center", width: "46%", height: "6.5vh", borderRadius: "3px", fontSize: "1.6vw", textAlign: "center" }}
                onClick={() => {
                  setIsQtyUpdate(false);
                  removeProductModal();
                  setIsSearchProducts(true);
                }}
              >
                Remove From Cart
              </Button>
              <p style={{ width: "4%" }}></p>
              <Button
                type="primary"
                style={{ background: "#EEC302", borderColor: "#EEC302", width: "46%", height: "6.5vh", borderRadius: "3px", color: "#fff", fontSize: "1.8vw", textAlign: "center" }}
                onClick={() => {
                  addQuantity();
                }}
              >
                Apply
              </Button>
            </div>
          }
        >
          <Row>
            <Col {...full}>
              <Row gutter={[0, 10]}>
                <Col {...full}>
                  <div>
                    <Row>
                      <Col {...nine}>
                        <img
                          style={data.posScreenStyles.quantityUpdateCard.kioskProductImg}
                          onError={setDefaultImage}
                          src={selectedProductInCart.imageurl || DefaultProductImage}
                          alt=""
                        />
                      </Col>
                      <Col {...fourteen} style={{ padding: "1.2vw", paddingRight: "0" }}>
                        <Row>
                          <Col {...full}>
                            <Row>
                              <Col {...full}>
                                <span style={{ textAlign: "left", fontSize: "1.4vh", color: "#929098" }}>Paper boat</span>
                              </Col>
                              <label
                                title={selectedProductInCart.name}
                                style={{
                                  maxWidth: "65% !important",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  position: "relative",
                                  objectFit: "cover",
                                }}
                              >
                                <span style={data.posScreenStyles.quantityUpdateCard.kioskProdNameDetails} className="prodNameInfo">
                                  {selectedProductInCart.name}
                                </span>
                              </label>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <p style={data.posScreenStyles.quantityUpdateCard.kioskProdDescLbl} className="prod-desc-lbl">
                              450 gm
                            </p>
                          </Col>
                          <Col span={6}>
                            <span
                              style={{
                                padding: "0.7vh",
                                marginBottom: "0px",
                                fontSize: "1.3vh",
                                fontFamily: "Inter",
                                color: "#fff",
                                fontWeight: "normal",
                                width: "80%",
                                overflow: "hidden",
                                backgroundColor: "#A4CD38",
                                textAlign: "center",
                                borderRadius: "3px",
                              }}
                            >
                              100 IN STOCK
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={24}>
                            <p className="KioskDescription">It has all the natural goodness of Toned milk and with its live cultures. It does not have at all preservatives. This is extremely helpful in treating indigestion.</p>
                          </Col>
                          <Col span={24}>
                            <span className="offers-text">
                              Offers Available : 10% OFF
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: "2vh" }}>
                          <Col span={13}>
                            <label style={data.posScreenStyles.quantityUpdateCard.kioskProdPriceLbl} className="priceNameInfo">
                              {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}&nbsp;
                              <span>
                                {selectedProductInCart.salePrice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                              </span>
                            </label>
                          </Col>
                          <Col span={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <label style={data.posScreenStyles.quantityUpdateCard.kioskProdPriceLb2} className="priceNameInfo">
                              Qty
                            </label>
                          </Col>
                          <Col span={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MinusOutlined
                              style={{ fontSize: "1.6vh", padding: "1.5vh 1.2vw", border: "1px solid black", borderRadius: "7px", alignSelf: "right" }}
                              onClick={() => handleChange()}
                            />
                          </Col>
                          <Col span={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "1.6vw", fontWeight: "600" }}>
                            {/* <label style={data.posScreenStyles.quantityUpdateCard.prodPriceLb2} className="priceNameInfo"> */}
                            {weightFlag === true ? weight : selectedProductInCart.weight}
                            {/* </label> */}
                          </Col>
                          <Col span={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <PlusOutlined
                              style={{ fontSize: "1.6vh", padding: "1.5vh 1.2vw", border: "1px solid black", borderRadius: "7px", alignSelf: "right" }}
                              onClick={() => onChangeWeight()}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      ) : (
        <Modal
          visible={isQtyUpdate}
          closable={true}
          onCancel={() => {
            setIsQtyUpdate(false);
            setIsSearchProducts(true);
          }}
          width="50%"
          bodyStyle={{ padding: 0, paddingBottom: "2vh", backgroundColor: "#F3F4F9" }}
          footer={null}
        >
          <Row>
            <Col {...full}>
              <Row gutter={[0, 10]}>
                <Col {...full}>
                  <div>
                    <Row>
                      <Col {...ten}>
                        <img
                          style={data.posScreenStyles.quantityUpdateCard.productImg}
                          onError={setDefaultImage}
                          src={selectedProductInCart.imageurl || DefaultProductImage}
                          alt=""
                        />
                      </Col>
                      <Col {...fourteen} style={{ padding: "1.2vw" }}>
                        <Row>
                          <Col {...full}>
                            <Row>
                              <Col {...full}>
                                <span style={{ textAlign: "left", fontSize: "1.4vh", color: "#929098" }}>Paper boat</span>
                              </Col>
                              <label
                                title={selectedProductInCart.name}
                                style={{
                                  maxWidth: "65% !important",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  position: "relative",
                                  objectFit: "cover",
                                }}
                              >
                                <span style={data.posScreenStyles.quantityUpdateCard.prodNameDetails} className="prodNameInfo">
                                  {selectedProductInCart.name}
                                </span>
                              </label>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <p style={data.posScreenStyles.quantityUpdateCard.prodDescLbl} className="prod-desc-lbl">
                              {selectedProductInCart.description}
                            </p>
                          </Col>
                          <Col span={24}>
                            <span
                              style={{
                                padding: "0.7vh",
                                marginBottom: "0px",
                                fontSize: "1.3vh",
                                fontFamily: "Inter",
                                fontWeight: "500",
                                width: "100%",
                                backgroundColor: "#fff",
                                overflow: "hidden",
                                textAlign: "center",
                                borderRadius: "3px",
                              }}
                            >
                              {`${stockList.qty || 0} IN STOCK`}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={24}>
                            <ul className="listItems">
                              <li>{selectedProductInCart.description}</li>
                            </ul>
                          </Col>
                          <Col span={24}>
                            <span
                              style={{
                                padding: "1.16vh",
                                marginBottom: "0px",
                                fontSize: "1.2vh",
                                fontFamily: "Inter",
                                color: "#fff",
                                fontWeight: "normal",
                                width: "80%",
                                overflow: "hidden",
                                backgroundColor: "#0F0718",
                                textAlign: "center",
                                borderRadius: "3px",
                                fontWeight: "400",
                              }}
                            >
                              Offers Available : 10% OFF
                            </span>
                          </Col>
                        </Row>
                        {/* <Row>
                          <label style={data.posScreenStyles.quantityUpdateCard.prodPriceLbl} className="priceNameInfo">
                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}&nbsp;
                            <span>
                              {selectedProductInCart.salePrice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                            </span>
                          </label>
                        </Row> */}
                        <Row style={{ paddingTop: "3vh", display: "flex", alignItems: "center" }}>
                          <Col span={11}>
                            <label style={data.posScreenStyles.quantityUpdateCard.prodPriceLbl} className="priceNameInfo">
                              {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}&nbsp;
                              <span>
                                {selectedProductInCart.salePrice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                              </span>
                            </label>
                          </Col>
                          <Col span={3}>
                            <label style={data.posScreenStyles.quantityUpdateCard.prodPriceLb2} className="priceNameInfo">
                              <span>Qty</span>
                            </label>
                          </Col>
                          <Col span={4}>
                            <MinusOutlined
                              style={{ marginRight: "5px", fontSize: "2.4vh", padding: "15% 18%", border: "1px solid #fff", borderRadius: "5px", backgroundColor: "#fff", }}
                              onClick={() => handleChange()}
                            />
                          </Col>
                          <Col span={2}>
                            <label style={data.posScreenStyles.quantityUpdateCard.prodPriceLb2} className="priceNameInfo">
                              <span>{weightFlag === true ? weight : selectedProductInCart.weight}</span>
                            </label>
                          </Col>
                          <Col span={4}>
                            <PlusOutlined
                              style={{ marginRight: "5px", fontSize: "2.4vh", padding: "15% 18%", border: "1px solid #fff", borderRadius: "5px", backgroundColor: "#fff" }}
                              onClick={() => onChangeWeight()}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#0F0718" }}>
                      <div style={{ width: "94%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", backgroundColor: "#FFF", padding: "1vw", borderRadius: "5px" }}>
                        <p style={{ textAlign: "left", width: "100%", fontSize: "2.1vh", fontWeight: "500" }}>Stock Information</p>
                        <div style={{ width: "98%", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid rgba(0, 0, 0, 0.17)", paddingBottom: "1vh" }}>
                          <span style={{ padding: "0 1vw", fontSize: "1.7vh", fontWeight: "500" }}>
                            Store
                          </span>
                          <span style={{ padding: "0 1vw", fontSize: "1.7vh", fontWeight: "500" }}>
                            {stockList.qty || 0}
                          </span>
                        </div>
                        {stockList.stores.map((ele) => (
                          <div style={{ width: "98%", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid rgba(0, 0, 0, 0.17)", padding: "1vh 0 1vh 0" }}>
                            <span style={{ padding: "0 1vw", fontSize: "1.7vh", fontWeight: "500" }}>
                              {ele.name}
                            </span>
                            <span style={{ padding: "0 1vw", fontSize: "1.7vh", fontWeight: "500" }}>
                              {ele.warehouse?.storageBin?.totalQty || 0}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col {...full}>
              <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#F3F4F9", padding: "1.5vh 0 0 0" }}>
                <Button
                  style={{ background: "white", color: "#0F0718", borderColor: "#FFF", textAlign: "center", width: "45%", height: "5.5vh", borderRadius: "3px" }}
                  onClick={() => {
                    setIsQtyUpdate(false);
                    removeProductModal(selectedProductInCart);
                    setIsSearchProducts(true);
                  }}
                >
                  <span style={{ fontSize: "1.9vh" }}>Remove From Cart</span>
                </Button>
                <p style={{ width: "3.4%" }}></p>
                <Button
                  type="primary"
                  style={{ background: "#2F3856", borderColor: "#2F3856", width: "45%", height: "5.5vh", borderRadius: "3px" }}
                  onClick={() => {
                    addQuantity();
                  }}
                >
                  <span style={{ fontSize: "2.1vh" }}>Apply</span>
                </Button>
              </div>
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default QuantityUpdate;
