import { useEffect } from "react";
let dataString = "";
let timeoutId;
let timeStamp;

const BarCodeComponent = (props) => {
  const { onScan, isInputFocused } = props;
  const handleKeyPress = (e) => {
    const { charCode, key } = e;
    if (timeoutId === undefined && dataString !== "") {
      timeoutId = setTimeout(() => {
        dataString = "";
        timeoutId = undefined;
      }, 500);
    }
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode === 45) {
      let curentTimeStampDiff = Date.now() - (timeStamp ? timeStamp : 0);
      if (timeStamp === undefined || (curentTimeStampDiff / 1000) < 100) {
        timeStamp = Date.now();
        dataString += key;
      } else {
        dataString = "";
      }
    }
    if (dataString !== "" && charCode === 13 && !isInputFocused) {
      timeStamp = undefined;
      // if(dataString.length === 16)
      onScan(dataString);
      dataString = "";
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      dataString = "";
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []); // eslint-disable-line

  return null;
};

export default BarCodeComponent;
