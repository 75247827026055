import axios from "axios";
import { currencyTypes } from "../../constants/taxCurrencies";
import _ from "lodash";
import moment from "moment"
import { sendOrder } from "../../socket";

export const kotPrinter = (printReciptData,fbOrderId) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let selectedTableData = JSON.parse(localStorage.getItem("tableName"));

  let printData = Object.entries(printReciptData).map((element, index) => {
    if (element[1].length > 0) {
      let newData = element[1].reduce((result, current) => {
        const category = current.productCategoryName;
        if (!result[category]) {
          result[category] = [];
        }
        if (current.order === "N") {
          result[category].push(current);
        }
        return result;
      }, {});
      if (Object.keys(newData).length === 0) {
        delete element.newData;
      } else {
        element.newData = newData;
      }
    } else {
      delete element.newData;
    }
    return element;
  });
  
let data = "";
printData.map((item)=>{ 
  for (const key in item.newData) {
    if (Array.isArray(item.newData[key]) && item.newData[key].length === 0) {
      delete item.newData[key];
    }
  }
  if(Object.keys(item.newData).length !== 0){
    let printDataXml = tillData.tillAccess.cwrTill.kotPrintTemplate?.xmlcode2;
    printDataXml = printDataXml.replace("<output>", "");
    printDataXml = printDataXml.replace("</output>", "");
    printDataXml = printDataXml.replace("@@Printer@@", `${item[0]}`);
    printDataXml = printDataXml.replace("@@OrderNumber@@", `KOT - ${localStorage.getItem("orderId") === null || localStorage.getItem("orderId") === undefined || localStorage.getItem("orderId") === "null" ? "" : localStorage.getItem("orderId")}`);
    printDataXml = printDataXml.replace("@@name@@", `${selectedTableData?.name === undefined ? "" : selectedTableData?.name}`);
    printDataXml = printDataXml.replace("@@sectionName@@", `${selectedTableData?.sectionName === undefined ? "" : selectedTableData?.sectionName }`);
    printDataXml = printDataXml.replace("@@date@@", `${moment(new Date()).format("YYYY-MM-DD")}`);
    printDataXml = printDataXml.replace("@@time@@", `${moment(new Date()).format("HH:mm")}`);
    let newData = "";
    Object.entries(item?.newData).map((ele,index)=>{
    let uniqueRates = new Set();
    if(ele[1].length>0 && !uniqueRates.has(index)){
      uniqueRates.add(index)
     printDataXml = printDataXml.replace("@@mproductcategory@@", `${ele[0]}`);
      ele[1]?.map((element) => {
        newData += `
        <line>
            <text align="left" length="25"> ${element.name.substring(0,20).replace(/&/g, '&amp;')}</text>
            <text align="left" length="5">${element.weight}</text>
            <text align="left" length="12">${element?.notes === undefined ? "" :  element?.notes?.substring(0,12)}</text>
           </line>
           <line>
            <text align="left" length="25"> ${element.name.substring(20).replace(/&/g, '&amp;')}</text>
            <text align="left" length="5"></text>
            <text align="left" length="12">${element?.notes === undefined ? "" :  element?.notes.substring(12)}</text>
           </line>
           <line>
           <text align="left" length="25"> ${element.name2 === null || element.name2 === null ? "" : element.name2.substring(0,20).replace(/&/g, '&amp;')}</text>
       </line>
       <line>
       <text align="left" length="25"> ${element.name2 === null || element.name2 === null ? "" : element.name2.substring(20).replace(/&/g, '&amp;')}</text>
   </line>
        `
        }); 
    }
    });
    printDataXml = printDataXml.replace("@@printReciptDataItems@@", `${newData}`);
    data += printDataXml;
  }
});

    let finalValue = `<?xml version="1.0" encoding="UTF-8"?><output>${data}</output>`
    const order = {
      KOTPrint:{
        print:finalValue.toString(),
        fbOrderId:fbOrderId
      },
    };
    
    if(tillData.tillAccess.salesRep === "N"){
      axios
      .post(`${printerURL}printer`, finalValue.toString(), {
        headers: {
          'Content-Type': 'application/xml; charset=UTF-8',
          'Accept': 'application/xml'
        }
      })
      .then(() => {
        console.log("XML print success !");
      })
      .catch((response) => {
        console.log("XML print failed !",response);
      });
    }else {
      sendOrder(order);
    }
};
