const moment = require('moment');
module.exports = {
  clock: (dateObj) => {
    let date = new Date();
    if (dateObj !== null && dateObj !== undefined) {
      date = new Date(dateObj);
    }
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const time = hours + ":" + minutes + " " + ampm;
    return time;
  },
  clockFullLength: (dateObj) => {
    let date = new Date();
    if (dateObj !== null && dateObj !== undefined) {
      date = new Date(dateObj);
    }
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    const time = hours + ":" + minutes + ":" + seconds + " " + ampm;
    return time;
  },
  timeStamp: (dateObj) => {
    let date = new Date();
    if (dateObj !== null && dateObj !== undefined) {
      date = new Date(dateObj);
    }
    return date.toISOString().split("T")[0] + " " + date.toTimeString().split(" ")[0];
  },
  timeValidator: (sTime, eTime, cTime) => {
    const startDate = sTime !== undefined && sTime !== null ? new Date(sTime) : new Date("2000-01-01 00:00:00");
    const endDate = eTime !== undefined && eTime !== null ? new Date(eTime) : new Date("2050-01-01 00:00:00");
    const curDate = cTime !== undefined && cTime !== null ? new Date(cTime) : new Date();
    return curDate >= startDate && curDate <= endDate;
  },

  dateValidator: (sDate, eDate, cDate) => {
    const startDate = sDate ? moment(sDate).format("YYYY-MM-DD") : moment("2000-01-01").format("YYYY-MM-DD");
    const endDate = eDate ? moment(eDate ? eDate : new Date()).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD");
    const currentDate = cDate ? moment(cDate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD");
    return currentDate >= startDate && currentDate <= endDate;
  },

  currentDay: () => {
    const date = new Date();
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    return days[date.getDay()];
  },
};
