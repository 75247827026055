import { flatDiscount } from "./PricingRules/FlatDiscount";
import { PercentageDiscount } from "./PricingRules/PercentageDiscount";
import { QuantityDiscount } from "./PricingRules/QuantityDiscount";
import { PackageDiscount } from "./PricingRules/PackageDiscount";
import { BuyXGetYDiscount } from "./PricingRules/BuyXGetYDiscount";
import { priceAdjustment } from "./PricingRules/priceAdjustment";
import db from "../../../database";
import moment from "moment";
import { clock, timeStamp, timeValidator, currentDay ,dateValidator} from "../../../utility/clock";

export const pricingRuleController = async (addToCart, cartObj, cart, setCart, cartRef, orderType) => {
  let product = addToCart;
  let finalData = cartObj;
  let matchFound = false;

  const pr = await db.pricingRules.toArray();
  const pricingRuleCount = pr.length;

  if (pricingRuleCount > 0) {
    pr.sort((a, b) => {
      if (a.priority === null && b.priority === null) {
        return 0;
      } else if (a.priority === null) {
        return 1;
      } else if (b.priority === null) {
        return -1;
      }
      return parseInt(b.priority, 10) - parseInt(a.priority, 10);
    });

    for (const pricingRule of pr) {
      if (!matchFound && dateValidator(pricingRule.startDate, pricingRule.endDate)) {
        const minQtyFlag = pricingRule.minimumQty ? product.weight >= pricingRule.minimumQty : true;
        const maxQtyFlag = pricingRule.maximumQty ? product.weight <= pricingRule.maximumQty : true;
        const minAmountFlag = pricingRule.billAmount ? product.nettotal >= pricingRule.billAmount : true;
        const maxAmountFlag = pricingRule.maxBillAmount ? product.nettotal <= pricingRule.maxBillAmount : true;

        let excludingFlag = true;

        if (pricingRule.mPricingB2CCustomerSegments.length > 0) {
          const matchingCustomerSegment = pricingRule.mPricingB2CCustomerSegments.find((pc) => pc.cwrB2CCustomerSegmentId === cartObj.customer?.b2cCustomerSegment?.cwrB2CCustomerSegmentId);

          if ((pricingRule.excludeB2CSegment === "Y" && matchingCustomerSegment) || (pricingRule.excludeB2CSegment !== "Y" && !matchingCustomerSegment)) {
            excludingFlag = false;
          }
        }

        if (pricingRule.mPricingB2CCustomers.length > 0) {
          const matchingCustomer = pricingRule.mPricingB2CCustomers.find((pc) => pc.b2cCustomerId === cartObj.customer.cwrCustomerId);

          if ((pricingRule.excludeB2CCustomers === "Y" && matchingCustomer) || (pricingRule.excludeB2CCustomers !== "Y" && !matchingCustomer)) {
            excludingFlag = false;
          }
        }

        if (pricingRule.timeSpecific === "Y") {
          const weekDay = currentDay();
          const pStartTime = pricingRule.starttime.substring(11);
          const pEndTIme = pricingRule.endtime !== null ? pricingRule.endtime.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
          const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
          const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;

          if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y" && minQtyFlag && minAmountFlag && maxQtyFlag && maxAmountFlag && excludingFlag) {
            const matchedResult = await matchedPricingRule(product, pricingRule, setCart, cart, orderType, cartObj, cartRef);
            if (matchedResult !== undefined) {
              matchFound = true;
              break; // Break out of the loop
            }
          }
        } else {
          if (minQtyFlag && minAmountFlag && maxQtyFlag && maxAmountFlag && excludingFlag) {
            const matchedResult = await matchedPricingRule(product, pricingRule, setCart, cart, orderType, cartObj, cartRef);
            if (matchedResult !== undefined) {
              matchFound = true;
              break; // Break out of the loop
            }
          }
        }
      }
    }
  }

  return finalData;
};




  const matchedPricingRule = async(product, pricingRule, setCart, cart, orderType, cartObj, cartRef) => {

    if (pricingRule.type === "FD" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
     let latestData = await flatDiscount(product, pricingRule, setCart, cart, orderType, cartObj, cartRef);
    return latestData
    }

    if (pricingRule.type === "PA" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      let latestData = await priceAdjustment(product, pricingRule, setCart, cart, orderType, cartObj, cartRef);
      return latestData
    }

    if (pricingRule.type === "PD" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      let latestData = await PercentageDiscount(product, pricingRule, setCart, cart, orderType, cartObj);
      return latestData
    }

    if (pricingRule.type === "PK" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      let latestData = await PackageDiscount(product, pricingRule, setCart, cart, orderType, cartObj)
      return latestData
    }

    if (pricingRule.type === "XGY" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      let latestData = await BuyXGetYDiscount(product, pricingRule, setCart, cart, orderType, cartObj)
      return latestData
    }

    if (pricingRule.type === "QD" && pricingRule.iscoupon === "N" && pricingRule.manualDiscount === "N") {
      let latestData = await QuantityDiscount(product, pricingRule, setCart, cart, orderType, cartObj, cartRef);
      return latestData
    }

  };