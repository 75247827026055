import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, message, Input, Button } from "antd";
import { useHistory } from "react-router-dom";
// import { serverUrl } from "../../constants/config";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { tillClosingPrint } from "../../lib/printer/tillCloseReceipt";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/icon.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";
import { v4 as uuidv4 } from "uuid";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import moment from "moment";


const CloseTillAll = () => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const redirectUrl = process.env.REACT_APP_redirectURL;
  const dynamicHeight = 0.5625;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const domainURL = process.env.REACT_APP_domain;
  const Instance = process.env.REACT_APP_INSTANCE_TYPE;
  const [allExpectedAmount, setAllExpectedAmount] = useState(0);
  const [allTransactionCount, setAllTransactionCount] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));

  const paymentMethodList = tillData.tillAccess.csBunit.paymentMethodList;
  const sessionId = tillSession.tillSessionId;
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [salesAmount,setSalesAmount] = useState(0)
  const [cashSaleAmount, setCashSaleAmount] = useState(0);
  const [cashValues, setCashValues] = useState({ cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 });
  const authHeaders = getOAuthHeaders();

  useEffect(async () => {
    let obj = { cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 }
    let cashDetails = await db.cashInCashOut.toArray();

    cashDetails.map((item) => {
      if (item.type === "cashIn") {
        obj.cashIn += parseFloat(item.amount);
      } else if (item.type === "cashOut") {
        obj.cashOut += parseFloat(item.amount);
      }
      if (item.type === "pettyCashIn") {
        obj.pettCashIn += parseFloat(item.amount);
      }
      if (item.type === "pettyCashOut") {
        obj.pettCashOut += parseFloat(item.amount);
      }
    })
    setCashValues(obj)

    if (posConfig.showDenominations === "N") {
      const newPaymentsList = [];
      db.orders
        .where("tillSessionId")
        .equals(sessionId)
        .toArray()
        .then((orders) => {
          paymentMethodList.forEach((payment) => {
            let currPaymentAmount = 0;
            let transactionCount = 0;
            let change = 0;
            let currPaymentReturn = 0;
            orders.forEach((order) => {
              const orderPayments = order.payments;
              change += order.change;
              orderPayments.forEach((orderPayment) => {
                if (payment.name.toLowerCase() === orderPayment.name.toLowerCase() && order.total>0) {
                  currPaymentAmount += parseFloat(orderPayment.amount);
                  transactionCount += 1;
                }else if(payment.name.toLowerCase() === orderPayment.name.toLowerCase() && order.total<0){
                  currPaymentReturn += parseFloat(Math.abs(orderPayment.amount));
                  transactionCount += 1;
                }
              });
            });
            payment.expectedAmount = currPaymentAmount;
            payment.amount = currPaymentAmount;
            payment.paymentReturn = currPaymentReturn;
            payment.difference = payment.expectedAmount - currPaymentAmount;
            payment.transactionCount = transactionCount;
            payment.change = change;
            
            newPaymentsList.push(payment);
          });
          const cashIndex = newPaymentsList.findIndex((p) => p.name.toLowerCase() === "cash");
          const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount;
          const cashAmount = parseFloat(newPaymentsList[cashIndex].expectedAmount);
          setTotalOpeningAmount(openAmount);
          setTotalOrdersCount(orders.length);
          setCashSaleAmount(cashAmount);
          const updatedTillSession = tillSession;
          const updatedAllPaymentsData = newPaymentsList;
          const aPi = updatedAllPaymentsData?.findIndex((apd) => apd.name.toLowerCase() === "cash");
          updatedAllPaymentsData[aPi].expectedAmount =
            tillData.tillAccess.csBunit.incDayOpening === "N"
              ? parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + parseFloat(tillSession.openingCash) - Math.abs(updatedAllPaymentsData[aPi].change)
              : parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + parseFloat(tillSession.openingCash) + parseFloat(totalOpeningAmount) - Math.abs(updatedAllPaymentsData[aPi].change);
          updatedAllPaymentsData[aPi].amount = totalAmount;
          updatedAllPaymentsData[aPi].expectedAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount.toFixed(2))
          updatedAllPaymentsData[aPi].cashSaleAmount = cashSaleAmount;
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].amount) - parseFloat(updatedAllPaymentsData[aPi].expectedAmount);
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].difference.toFixed(2));
          updatedTillSession.closingTime = timeStamp();
          updatedTillSession.closingCash = totalAmount;
          updatedTillSession.closingCashDenominations = [];
          updatedTillSession.allPaymentsData = updatedAllPaymentsData;
          updatedTillSession.totalOrdersCount = orders.length;
          // updatedAllPaymentsData[aPi].amount = updatedAllPaymentsData[aPi].amount + obj.cashIn - obj.cashOut -obj.pettCash;
          updatedAllPaymentsData[aPi].expectedAmount = updatedAllPaymentsData[aPi].expectedAmount + obj.cashIn - obj.cashOut + obj.pettCashIn - obj.pettCashOut;
          localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));
          setAllExpectedAmount(updatedAllPaymentsData[aPi].expectedAmount);
          setAllTransactionCount(updatedAllPaymentsData[aPi].transactionCount);
          setTotalAmount(updatedAllPaymentsData[aPi].amount);
          setTotalOrdersCount(orders.length);

          setPaymentsList([...newPaymentsList]);
        });
    } else if (posConfig.showDenominations === "Y") {
      const tillSessionPayments = tillSession.allPaymentsData;
      let totalAmountAllPayments = 0;
      let transactionCount = 0;
      let total = 0;
      let salesAmount = 0;
      tillSessionPayments?.map((pay) => {
        totalAmountAllPayments += pay.expectedAmount;
        transactionCount += pay.transactionCount;
        if(pay.name.toLowerCase() === "cash"){
          pay.expectedAmount = pay.expectedAmount- pay.paymentReturn;
          salesAmount += pay?.cashSaleAmount - pay?.paymentReturn;
        }else{
          salesAmount += pay?.expectedAmount - pay?.paymentReturn;
        }
        total += parseFloat(pay.expectedAmount);
        return null;
      });
      const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount;
      setSalesAmount(salesAmount);
      // total = total + obj.cashIn - obj.cashOut -obj.pettCash;
      totalAmountAllPayments = salesAmount + obj.cashIn - obj.cashOut + obj.pettCashIn - obj.pettCashOut + openAmount;
      setAllExpectedAmount(totalAmountAllPayments);
      setAllTransactionCount(transactionCount);
      setTotalAmount(total);
      setTotalOrdersCount(tillSession.totalOrdersCount);
      setPaymentsList([...tillSessionPayments]);
    }
  }, []);

  const history = useHistory();
  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(async () => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteCloseTillAll = async () => {
    confirmBtnRef.current.disabled = true;
    cancelBtnRef.current.disabled = true;
    const updatedPaymentsList = paymentsList;
    const closingSession = tillSession;
    closingSession.tillStatus = "closed";
    closingSession.totalClosingExpectedAmount = allExpectedAmount;
    closingSession.totalClosingTransactions = allTransactionCount;
    closingSession.totalClosingAmount = totalAmount;
    closingSession.allPaymentsData = updatedPaymentsList;
    closingSession.isSynced = 0;

    if (authHeaders && authHeaders.access_token) {
      if (
        db.logConfiguration.toArray().then((fetched) => {
          fetched.map((item) => {
            item.dayClosing.filter((item) => item.log === "Y").length > 0;
          });
        })
      ) {
        const timeMark = timeStamp();
        const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
        const dayClosingArray = [];
        dayClosingArray.push(`{
          type: "DCL",
          action: "LOG",
          description: "${timeMark}",
          date: "${currentDate}",
          time: "${timeMark}",
          orderNo: "",
          remarks: "",
        }`);
        await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `mutation {
            upsertPOSLog(order: {
                tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                userId: "${tillData.tillAccess.csUserId}" 
                bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
                lines: [${dayClosingArray}]
              }) {
              status   
              message
            }
          }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        }).then((res) => {
          if (res.data.data.upsertPOSLog.status === "200") {
            db.tillEvents.add(closingSession).then(() => {
              tillClosingPrint(closingSession);
              // localStorage.removeItem("tillSession");
              confirmBtnRef.current.disabled = false;
              cancelBtnRef.current.disabled = false;
              // localStorage.removeItem("tokens");
              // localStorage.removeItem("sync1");
              // localStorage.removeItem("posConfig");
            });

            const nWStatus = navigator.onLine ? "online" : "offline";
            if (nWStatus === "online") {
              db.tillEvents
                .where("isSynced")
                .equals(0)
                .toArray()
                .then(async (events) => {
                  if (events.length > 0) {
                    for (let i = 0; i < events.length; i += 1) {
                      const event = events[i];
                      const tillCash = [];
                      for (let j = 0; j < event.allPaymentsData.length; j += 1) {
                        // console.log(event.allPaymentsData[j], "event.allPaymentsData[j]")
                        const paymentObj = event.allPaymentsData[j];

                        if (paymentObj.name.toLowerCase() === "cash") {
                          const openedCashEvents = [];
                          const closedCashEvents = [];

                          for (let x = 0; x < event.openingCashDenominations.length; x += 1) {
                            openedCashEvents.push(`{
                              cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                              count:${event.openingCashDenominations[x].count}
                              amount:${event.openingCashDenominations[x].amount}
                              denomination:${event.openingCashDenominations[x].value}
                            }`);
                          }
                          for (let z = 0; z < event.closingCashDenominations?.length; z += 1) {
                            closedCashEvents.push(`{
                              cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                              count:${event.closingCashDenominations[z].count}
                              amount:${event.closingCashDenominations[z].amount}
                              denomination:${event.closingCashDenominations[z].value}
                            }`);
                          }
                          let cashInOut = [];
                          let cashIn = 0;
                          let cashOut = 0;
                          let pattyCashIn = 0;
                          let pettyCashOut = 0;

                          await db.cashInCashOut.toArray(data => {
                            data.map((item) => {
                              if (item.type === "cashIn") {
                                cashIn += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "CI",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`)
                              } else if (item.type === "cashOut") {
                                cashOut += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "CO",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`)
                              }
                              if (item.type === "pettyCashIn") {
                                pattyCashIn += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "PCI",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`)
                              }
                              if (item.type === "pettyCashOut") {
                                pettyCashOut += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "PCO",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`)
                              }
                            })

                          });
                          tillCash.push(`{
                            cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                            date: "${event.closingTime}"
                            finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                            finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(event.closingCash) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                            opening: ${event.openingCash}
                            sales: ${paymentObj.cashSaleAmount}
                            netsales: ${paymentObj.cashSaleAmount}
                            cashin: ${cashIn}
                            cashout: ${cashOut}
                            retainAmt: 0
                            closing: ${event.closingCash}
                            returns: 0
                            iscash: 1
                            isclose: 1
                            pettyCashIn: ${pattyCashIn},
                            pettyCashOut: ${pettyCashOut},
                            cashInOut: [${cashInOut}],
                            cashEvents: [
                              {
                                cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                                amount: ${event.openingCash}
                                expected: 0
                                diff: 0
                                transactionCount: 0
                                type: "Open"
                                description: ""
                                cashEventDetails: [${openedCashEvents}]
                              },
                              {
                                cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                                amount: ${event.closingCash}
                                expected: ${paymentObj.expectedAmount}
                                diff: ${paymentObj.difference}
                                transactionCount: ${paymentObj.transactionCount}
                                type: "Close"
                                description: ""
                                cashEventDetails: [${closedCashEvents}]
                              }
                            ]
                          }`);
                        } else {
                          let cashInOut = [];
                          let cashIn = 0;
                          let cashOut = 0;
                          let pattyCashIn = 0;
                          let pettyCashOut = 0;

                          await db.cashInCashOut.toArray(data => {
                            data.map((item) => {
                              if (item.type === "cashIn") {
                                cashIn += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "CI",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`)
                              } else if (item.type === "cashOut") {
                                cashOut += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "CO",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`)
                              }
                              if (item.type === "pettyCashIn") {
                                pattyCashIn += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "PCI",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`)
                              }
                              if (item.type === "pettyCashOut") {
                                pettyCashOut += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "PCO",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`)
                              }
                            })

                          });
                          await db.cashInCashOut.clear();
                          tillCash.push(`{
                            cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                            date: "${event.closingTime}"
                            finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                            finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(paymentObj.amount) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                            opening: 0
                            sales: ${paymentObj.expectedAmount}
                            netsales: ${paymentObj.expectedAmount}
                            cashin: ${cashIn}
                            cashout: ${cashOut}
                            retainAmt: 0
                            closing: ${parseFloat(paymentObj.amount)}
                            returns: 0
                            iscash: 0
                            isclose: 1
                            pettyCashIn: ${pattyCashIn},
                            pettyCashOut: ${pettyCashOut},
                            cashInOut: [${cashInOut}],
                            cashEvents: [
                              {
                                cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                                amount: ${parseFloat(paymentObj.amount)}
                                expected: ${paymentObj.expectedAmount}
                                diff: ${paymentObj.difference}
                                transactionCount: ${paymentObj.transactionCount}
                                type: "Close"
                                description: ""
                                cashEventDetails: []
                              }
                            ]
                          }`);
                        }
                      }

                      const paramsInput = {
                        query: `mutation{
                          upsertTill(tillInfo:{
                            cwrTillID:"${tillData.tillAccess.cwrTill.cwrTillID}"
                            csbunitID:"${tillData.tillAccess.csBunit.csBunitId}"
                            csclientID:"${tillData.tillAccess.csClientId}"
                            created:"${event.openingTime}"
                            createdby:"${tillData.tillAccess.csUserId}"
                            updated:"${event.openingTime}"
                            updatedby:"${tillData.tillAccess.csUserId}"
                            tillCash:[${tillCash}]
                          })
                          {    
                            status
                            message
                            cwrTillID
                                  tillCash{
                              
                              cwrTillCashId
                              date
                              finPaymentmethodId
                              opening
                              sales
                              netsales
                              cashin
                              cashout
                              retainAmt
                              closing
                              returns
                              iscash
                              isclose
                              cashEvents{
                                cwrCashEventsID
                                amount
                                expected
                                diff
                                transactionCount
                                type
                                description
                                cashEventDetails{
                                  cwrCashEventdetailsID
                                  count
                                  amount
                                  denomination
                                }
                              }
                            }
                          }
                        }`,
                      };

                      Axios({
                        url: serverUrl,
                        method: "POST",
                        data: paramsInput,
                        headers: {
                          "Content-Type": "Application/json",
                          Authorization: `${authHeaders.access_token}`,
                        },
                      }).then(async (response) => {
                        const result = response.data.data?.upsertTill;
                        const status = result?.status;
                        if (status === "200") {
                          let tillData = await db.tillRegistrations.toArray();
                          Axios({
                            url: serverUrl,
                            method: "POST",
                            data: {
                              query: `mutation {
                                  upsertPOSActivity(tillActivity: [
                                    {
                                      csBunitId: "${tillData[0].tillAccess[0].csBunit.csBunitId}"
                                      csUserId: "${tillData[0].tillAccess[0].csUser.csUserId}"
                                      tillRegistrationId: "${tillData[0].tillHistory[0].cwrTillRegHistoryId}"
                                      type: "LO"
                                      time: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                                    }
                                  ]) {
                                    status
                                    message
                                  }
                              }`,
                            },
                            headers: {
                              "Content-Type": "Application/json",
                              Authorization: `${authHeaders.access_token}`,
                            },
                          }).then(async (response) => {
                            if (response.data.data.upsertPOSActivity.status === 200 || response.data.data.upsertPOSActivity.status === "200") {
                              // await onLogin();
                              if (posConfig.closingRegister === "Y") {
                                let unlinkTill = localStorage.getItem("unlink")
                                if (unlinkTill) {
                                  unlinkTillAPI();
                                } else {
                                  // if (Instance === "Enterprise") {                               
                                    const cookiesToDelete = document.cookie.split(';').map(cookie => cookie.trim());

                                    for (let i = 0; i < cookiesToDelete.length; i++) {
                                      const cookie = cookiesToDelete[i];
                                      const eqPos = cookie.indexOf('=');
                                      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                                      const domain = "domain=" + domainURL;
                                      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;' + domain;
                                    }
                                    

                                  // } 
                                  localStorage.removeItem("tillSession")
                                  window.location.assign("/");
                                  // window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`)
                                }
                                // tillClosingPrint(closingSession);
                                // window.open(`http://localhost:3001/others/window/7515`, "_self");
                                // window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`)
                              }
                            }
                          });
                          db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 1 });
                          try {
                            let unlinkTill = localStorage.getItem("unlink")
                            if (unlinkTill) {
                              unlinkTillAPI();
                            } else {
                              // if (Instance === "Enterprise") {                               
                              const cookies = document.cookie.split(';');
                              for (let i = 0; i < cookies.length; i++) {
                                const cookie = cookies[i];
                                const eqPos = cookie.indexOf('=');
                                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                                const domain = "domain=" + domainURL;
                                document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;' + domain;
                              }

                              // } 
                              localStorage.removeItem("tillSession")
                              window.location.assign("/");
                              // window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`)
                            }
                            // localStorage.clear();
                          } catch (error) {
                            // message.error(error.message);
                          };
                        } else {
                          console.log("Failed Till Session Sync ====> ", response);
                          db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 2 });
                        }
                      });
                    }
                  }
                });
            }
          }
        })
      }
    }


  };

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const updatedPaymentsList = paymentsList;
    const paymentIndex = updatedPaymentsList.findIndex((pi) => pi.name === name);
    updatedPaymentsList[paymentIndex].amount = value;
    const diff = parseFloat(value) - parseFloat(updatedPaymentsList[paymentIndex].expectedAmount);
    updatedPaymentsList[paymentIndex].difference = isNaN(diff) ? 0 : diff;
    setPaymentsList([...updatedPaymentsList]);
    let totalAmountVal = 0;
    updatedPaymentsList.map((p) => {
      return (totalAmountVal += isNaN(parseFloat(p.amount)) ? 0 : parseFloat(p.amount));
    });
    // totalAmountVal = totalAmountVal + cashValues.cashIn - cashValues.cashOut -cashValues.pettCash;
    setTotalAmount(totalAmountVal);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const { name } = e.target;
      const value = 0;
      const updatedPaymentsList = paymentsList;
      const paymentIndex = updatedPaymentsList.findIndex((pi) => pi.name === name);
      updatedPaymentsList[paymentIndex].amount = value;
      const diff = parseFloat(value) - parseFloat(updatedPaymentsList[paymentIndex].expectedAmount);
      updatedPaymentsList[paymentIndex].difference = isNaN(diff) ? 0 : diff;
      setPaymentsList([...updatedPaymentsList]);
      let totalAmountVal = 0;
      updatedPaymentsList.map((p) => {
        return (totalAmountVal += isNaN(parseFloat(p.amount)) ? 0 : parseFloat(p.amount));
      });
      // totalAmountVal = totalAmountVal + cashValues.cashIn - cashValues.cashOut -cashValues.pettCash;
      setTotalAmount(totalAmountVal);
    }
  };

  const unlinkTillAPI = async () => {
    try {
      let id = tillData.tillAccess.cwrTill.cwrTillID;

      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
            unlinkTill(tillId: "${id}") {
              status
              message
            } 
          }`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      });

      // Handle the response if needed
      if (response.data.data.unlinkTill.status === "200") {
        const keepKey = ""; // Replace with the key you want to keep
        // for (let i = 0; i < localStorage.length; i++) {
        //   const key = localStorage.key(i);
        //   // Check if the current key is not the one you want to keep
        //   if (key !== keepKey) {
        //     localStorage.removeItem(key);
        //   }
        // }
        localStorage.clear();
        await Promise.all(
          db.tables.map(async (table) => {
            if (table.name !== keepKey) {
              await table.clear();
            }
          })
        );

      }

      localStorage.removeItem("unlink");
      window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`)
    } catch (error) {
      // Handle errors if the request fails
      console.error("Error in unlinkTillAPI:", error);
    }

  };

  const checkFlag = () => {
    if (posConfig.cashDiffInShiftClose === "N") {
      const paymentMismatches = [];
      paymentsList.forEach((res) => {

        // Calculate the difference between expected amount and amount
        
        const integerPart = Math.floor(res.expectedAmount);
        const decimalPart = res.expectedAmount - integerPart;
      
        let roundedDecimal;
      
        if (decimalPart <= 0.25 && decimalPart>0) {
          roundedDecimal = 0.25;
        } else if (decimalPart <= 0.5 && decimalPart>0) {
          roundedDecimal = 0.5;
        } else if (decimalPart <= 0.75 && decimalPart>0) {
          roundedDecimal = 0.75;
        } else if(decimalPart >0) {
          roundedDecimal = 1.0;
        }else {
          roundedDecimal = 0;
        }
      
        const roundedValue = integerPart + roundedDecimal;
        // return roundedValue;

        const difference = res.name.toLowerCase() === "cash" ? roundedValue - res.amount : res.expectedAmount - res.amount;

        // Check if the difference is not 0
        if (difference !== 0) {
          // Store the payment method with its difference in the array
          paymentMismatches.push({
            paymentMethod: res.name,
            difference: difference,
          });
        }
      });

      // Check if there are any payment mismatches
      if (paymentMismatches.length > 0) {
        // Construct a single message for all payment mismatches
        const mismatchMessage = `Payment mismatches detected. Details: ${paymentMismatches.map(
          (mismatch) => `${mismatch.paymentMethod}: ${parseFloat(mismatch.difference).toFixed(2)}`
        )}`;

        // Display a single message for all mismatches
        message.info(mismatchMessage);

        // console.log("There are payment mismatches:");
        // console.log(paymentMismatches);
      } else {
        console.log("All payments match the expected amounts.");
        excuteCloseTillAll();
      }
    } else {
      excuteCloseTillAll();
    }
  }
  let reconstructedObject;
  // const rawData = tillData?.tillAccess?.tillAccessMeta[0]?.value;
  const rawtillAccessMeta = tillData?.tillAccess?.tillAccessMeta;
  if(rawtillAccessMeta){
    const rawDataArray = tillData?.tillAccess?.tillAccessMeta;

    // Find the object with the key "Open Till"
    const closeTillObject = rawDataArray.find(item => item.key === 'Close Till');
    
    // Access the value property of the found object
    const closeTillValue = closeTillObject?.value;

    if (closeTillValue !== undefined) {
      // The rest of your code that depends on closeTillValue
        // Clean up the string (remove extra characters)
    const cleanedData = closeTillValue.replace(/[{}"]/g, '');
    
    // Split the string into key-value pairs
    const keyValuePairs = cleanedData.split(',');
    
    // Create an object from key-value pairs
     reconstructedObject = {};
    keyValuePairs.forEach(pair => {
      // Check if the pair contains a colon
      if (pair.includes(':')) {
        const [key, value] = pair.split(':');
        
        // Check if both key and value are not undefined before trimming
        if (key !== undefined && value !== undefined) {
          reconstructedObject[key.trim()] = value.trim();
        }
      }
    });
    } else {
      // Handle the case where closeTillValue is undefined
      console.error('closeTillValue is undefined');
    }
  }
  return (
    <div style={data.openTill.mainContainer}>




      <div style={data.openTill.mainContainer}>
        <Row style={{ height: "6vh", padding: "1% 0 0 1%" }}>
          <Col span={1} style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
            <img src={MenuIcon} style={data.openTill.header.menuIcon} alt="" />
          </Col>
          <Col span={23}>
            <label style={{
              "fontSize": "2vw",
              "color": "#000000",
              "letterSpacing": "0.36px",
              "opacity": 1,
              "fontWeight": "600",
              "textAlign": "center",
            }}>Close Till</label>
          </Col>
        </Row>

        <div style={data.openTill.mainCardContainer}>
          <div style={{ padding: "1vw", display: "flex", paddingBottom: "0" }}>
            <div style={{ borderRadius: "3px", width: "75%" }}>
              <div key="z" style={data.openTill.mainCardFirst}>
                <Row style={{ padding: "1% 0%", borderBottom: "1px solid rgba(0, 0, 0, 0.09)" }}>

                  <Col span={posConfig.cashDiffInShiftClose === "Y" ? 5 : 6} style={data.openTill.colAlign}>
                    <strong>Payment Name</strong>
                  </Col>
                  <Col span={posConfig.cashDiffInShiftClose === "Y" ? 5 : 6} style={data.openTill.colAlign}>
                    <strong>Transactions</strong>
                  </Col>
                  <Col span={posConfig.cashDiffInShiftClose === "Y" ? 5 : 6} style={data.openTill.alignCenter}>
                    <strong>Expected Amount</strong>
                  </Col>
                  <Col span={posConfig.cashDiffInShiftClose === "Y" ? 5 : 6} style={data.openTill.alignCenter}>
                    <strong>Actual Amount</strong>
                  </Col>
                  {posConfig.cashDiffInShiftClose === "Y" ? <Col span={4} style={data.openTill.colAlign}>
                    <strong>Difference</strong>
                  </Col> : null}

                </Row>
                <Row >
                  <Scrollbars autoHide className="closeTillScroll">
                    {paymentsList.map((payment, index) => (
                      <Row style={{ marginBottom: "2%", marginTop: "1%" }}>
                        <Col span={posConfig.cashDiffInShiftClose === "Y" ? 5 : 6} style={data.openTill.paymentColAlign}>
                          <h6 style={data.openTill.paymentDetails}>{payment.name}</h6>
                        </Col>
                        <Col span={posConfig.cashDiffInShiftClose === "Y" ? 5 : 6} style={data.openTill.paymentColAlign}>
                          <h6 style={data.openTill.paymentDetails}>{payment.transactionCount}</h6>
                        </Col>
                        <Col span={posConfig.cashDiffInShiftClose === "Y" ? 5 : 6} style={data.openTill.paymentColAlign}>
                          <h6 style={data.openTill.paymentDetails}>{parseFloat(payment.expectedAmount) >= 0 ? parseFloat(payment.expectedAmount).toFixed(2) : 0.00}</h6>
                        </Col> 
                        <Col span={posConfig.cashDiffInShiftClose === "Y" ? 5 : 6} style={data.openTill.colAlign}>
                          <Input
                            name={`${payment.name}`}
                            value={payment.amount}
                            onChange={onFieldInput}
                            onBlur={onFieldOut}
                            readOnly={payment.name.toLowerCase() === "cash" && posConfig.showDenominations === "Y" ? true : false}
                            style={payment.name.toLowerCase() === "cash" && posConfig.showDenominations === "Y" ? data.openTill.currency : data.openTill.actualAmtInput}
                          />
                        </Col>
                        {posConfig.cashDiffInShiftClose === "Y" ?
                          <Col span={4} style={data.openTill.paymentColAlign}>
                            <h6 style={data.openTill.paymentDetails}>{parseFloat(payment.difference).toFixed(2)}</h6>
                          </Col> : null}
                      </Row>
                    ))}
                  </Scrollbars>
                </Row>
              </div>
            </div>
            <div style={{ width: "25%", paddingLeft: "2.3%" }}>
              <div style={data.openTill.opentillRightCard}>
                <p style={data.openTill.emptyP} />
                <p style={data.openTill.emptyP}>Opened On</p>
                <div style={{ marginBottom: "0.5vh", marginTop: "-6px" }}>
                  <span style={data.openTill.currentDate}>{currentDate}</span>
                  <span style={data.openTill.displayClock}>{displayClock}</span>
                </div>
                <Form>
                  <p style={{
                    "marginBottom": "0.5vh",
                    "fontSize": "2vh",
                    "fontFamily": "Inter",
                    "fontWeight": "600",
                    "letterSpacing": "0px",
                    "color": "#0F0718",
                  }}>Transactions</p>
                  <Form.Item style={{ marginBottom: "0.8vh" }}>
                    <p style={data.openTill.transactionsAmtClose}>Payment Transactions</p>
                    <Input
                      type="number" value={allTransactionCount} readOnly={true} className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh" }}>
                    <p style={data.openTill.transactionsAmtClose}>Retail Transactions</p>
                    <Input
                      value={totalOrdersCount}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  {/* my changes */}

                  <Form.Item style={{ marginBottom: "0.8vh",display:reconstructedObject?reconstructedObject.OpeningAmount==="Y"?"block":"none":"block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Opening Amount</p>
                    <Input
                      value={`${parseFloat(JSON.parse(localStorage.getItem("tillSession")).totalOpeningAmount).toFixed(2)}`}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh",display:reconstructedObject?reconstructedObject.SalesAmount==="Y"?"block":"none":"block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Sales Amount</p>
                    <Input
                      value={`${parseFloat(salesAmount).toFixed(2)}`}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>


                  <Form.Item style={{ marginBottom: "0.8vh",display:reconstructedObject?reconstructedObject.CashIn==="Y"?"block":"none":"block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Cash In</p>
                    <Input
                      value={`${parseFloat(cashValues.cashIn).toFixed(2)}`}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh",display:reconstructedObject?reconstructedObject.CashOut==="Y"?"block":"none":"block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Cash Out</p>
                    <Input
                      value={`${parseFloat(cashValues.cashOut).toFixed(2)}`}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh",display:reconstructedObject?reconstructedObject.PettyCashIn==="Y"?"block":"none":"block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Petty Cash In</p>
                    <Input
                      value={`${parseFloat(cashValues.pettCashIn).toFixed(2)}`}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh",display:reconstructedObject?reconstructedObject.pettyCashOut==="Y"?"block":"none":"block" }} >
                      <p style={data.openTill.transactionsAmtClose}>Petty Cash Out</p>
                      <Input
                        value={`${parseFloat(cashValues.pettCashOut).toFixed(2)}`}
                        readOnly={true}
                        className="transactionAmtInputClose"
                        style={{ height: "4.5vh" }}
                      />
                    </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh",display:reconstructedObject?reconstructedObject.ExpectedAmount==="Y"?"block":"none":"block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Expected Amount</p>
                    <Input
                      value={`${parseFloat(allExpectedAmount) >= 0 ? parseFloat(allExpectedAmount).toFixed(2) : 0.00}`}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh" }}>
                    <p style={data.openTill.transactionsAmtClose}>Total Amount</p>
                    <Input
                      value={`${parseFloat(totalAmount).toFixed(2)}`}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                </Form>
                <Row style={{ paddingTop: "2vh" }}>
                  <Button ref={cancelBtnRef} style={data.openTill.btnCancel}>
                    <Link to={posConfig.showDenominations === "Y" ? "/close-till" : "/pos"}>
                      Back
                    </Link>
                  </Button>
                  <Button ref={confirmBtnRef} style={data.openTill.btnConfirm} onClick={checkFlag}>
                    Close Till
                  </Button>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseTillAll;
