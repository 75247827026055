import { v4 as uuidv4 } from "uuid";

export const PackageDiscount = async (product, pricingRule, setCart, cart, orderType, cartObj, cartRef) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
      const saleTypeValidation = (pricingRule?.cwrSaletypeId === null || orderType === undefined) ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletypeId;
      let excludingFlag = true;
    
      if (pricingRule.mPricingPcategories.length > 0) {
        const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);
      
        if ((pricingRule.excludeProductCategories === "Y" && matchingCategoy) || (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy)) {
          excludingFlag = false;
        }
      }
    
      if (pricingRule.mPricingBrands.length > 0) {
        const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);
      
        if ((pricingRule.excludeBrands === "Y" && matchingBrand) || (pricingRule.excludeBrands !== "Y" && !matchingBrand)) {
          excludingFlag = false;
        }
      }
    
      if (pricingRule.mPricingBUnits.length > 0) {
        excludingFlag = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }
      
      if (pricingRule.mPricingXProducts.length > 0) {
        const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
      
        if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) || (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1)) {
          excludingFlag = false;
        }
      }

      let pricingRuleFlag = true;
      // if(pricingRule.mPricingXProducts.length>0){
      //   pricingRule.mPricingXProducts.some((op) => {
      //     cartObj.items.map((product)=> {
      //       if(op.mProductId === product.productId && product.nextRule === "Y"){
      //         pricingRuleFlag = false;
      //       }else if(op.mProductId === product.productId && !product.nextRule){
      //         pricingRuleFlag = true;
      //       }
      //     })
      //   })
      // }else{
      //   if(product.nextRule === "Y"){
      //     pricingRuleFlag = false;
      //   }
      // }

      if (saleTypeValidation && excludingFlag && pricingRuleFlag) {
        const addedToCart = cartObj.items;
        const offerProducts = pricingRule.mPricingXProducts;
        const prodIndexes = [];
        const properWeights = [];
        const prodData = [];
        let productsPriceTotal = 0;
        const uniqueProductIds = new Set();

        addedToCart.map((item, index) => {
          const ifExistis = offerProducts.findIndex((op) => op.mProductId === item.productId && !item?.bundleId);
          if (ifExistis >= 0) {
            prodIndexes.push(index);
            if (!uniqueProductIds.has(item.productId)) {
              prodData.push(item)
              uniqueProductIds.add(item.productId);
            }
            properWeights.push(item.weight);
            productsPriceTotal += parseFloat(item.realPrice);
          }else {
            // item.bundleId = null
          }
          return null;
        });
        
        if (prodData.length === offerProducts.length) {
          let bundleId = uuidv4().replace(/-/g, "").toUpperCase();
          if (properWeights.every((item) => item === properWeights[0])) {
            prodIndexes.map((index) => {
              let distributedDiscount = addedToCart[index].realPrice - (addedToCart[index].realPrice / productsPriceTotal) * pricingRule.fixedUnitPrice
              const sp = (addedToCart[index].realPrice / productsPriceTotal) * pricingRule.fixedUnitPrice;
              const mrp = parseFloat(sp) * addedToCart[index].weight;
              const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
              addedToCart[index].salePrice = sp;
              addedToCart[index].taxAmount = tax;
              addedToCart[index].nettotal = mrp;
              addedToCart[index].discount = Math.abs(distributedDiscount);
              addedToCart[index].discountName = pricingRule.printedName;
              addedToCart[index].bundleId = bundleId;
              // message.success(pricingRule.printedName);
              return null;
            });
          } else {  
            prodIndexes.map((index) => {
              if(!addedToCart[index].bundleId){
                const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
                const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
                addedToCart[index].taxAmount = tax;
                addedToCart[index].nettotal = mrp;
                addedToCart[index].discount = 0;
                addedToCart[index].discountName = "";
                addedToCart[index].mPricingruleId = null;
                return null;
              }
            });
          }
        } else {
          prodIndexes.map((index) => {
            if(!addedToCart[index]?.bundleId){
              const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
              const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
              addedToCart[index].taxAmount = tax;
              addedToCart[index].nettotal = mrp;
              addedToCart[index].discount = 0;
              addedToCart[index].discountName = "";
              addedToCart[index].mPricingruleId = null;
              return null;
            }
          });
        }

        let obj = {
          ...cartObj,
          items: [...addedToCart],
        }
        return obj;
      }
}